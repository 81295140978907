import { Col, Row } from 'react-bootstrap';

import styles from './styles.module.css';
import SquareLogoAnimate from '../../../SquareLogoAnimate/SquareLogoAnimate';
import appContext from '../../../../context/app-context';
import { useContext } from 'react';
import { AppleIcon, GoogleIcon } from '../../../Icons';

export function SocialFlow({ children }) {
  const { user } = useContext(appContext);
  console.log(user);
  return (
    <>
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page p-relative'
        >
          <div className={styles.overlay} />
          <div className={styles.centerContent}>
            <div className='mt-4 pt-4 pb-5'>
              <SquareLogoAnimate />
            </div>

            <div className='text-center'>
              <div>
                <p style={{ paddingBottom: '40px' }} className='fw-700 fs-14 '>
                  To access your signed copies,
                  <br />
                  please continue with...
                </p>
                {user?.google_id && (
                  <div className='d-flex align-items-center justify-content-center'>
                    <div
                      className={`d-flex align-items-center justify-content-center border border-dark rounded-circle ${styles.icon}`}
                    >
                      <GoogleIcon />
                    </div>
                  </div>
                )}
                {user?.apple_id && (
                  <div className='d-flex align-items-center justify-content-center'>
                    <div
                      className={`d-flex align-items-center justify-content-center border border-dark rounded-circle ${styles.icon}`}
                    >
                      <div className={styles.appleIcon}>
                        <AppleIcon />
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {children}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
