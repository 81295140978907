import { useContext, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import appContext from '../../context/app-context';
import MyProfile from '../../components/Pages/Profile/MyProfile/MyProfile';
import MyDetails from '../../components/Pages/Profile/MyDetails/MyDetails';
import MYIDDocuments from '../../components/Pages/Profile/MyIDDocuments/MYIDDocuments';
import IDVerification from '../../components/Pages/Profile/IDVerification/IDVerification';
import SeifieVerification from '../../components/Pages/Profile/SeifieVerification/SeifieVerification';
import ChangePassword from '../../components/Pages/Profile/ChangePassword/ChangePassword';
import { useNavigate } from 'react-router-dom';
import AddBusiness from '../../components/Pages/Profile/AddBusiness/AddBusiness';

export const Profile = () => {
  const { profilePage, getUser, setContinuePage } = useContext(appContext);
  const [page, setPage] = useState(profilePage);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    getUser();
    if (!sessionStorage.getItem('token')) {
      setContinuePage(0);
      navigate('/continue');
    }
    selectPage(profilePage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilePage]);

  const profileScreens = () => {
    switch (page) {
      case 0:
        return <MyProfile />;
      case 1:
        return <MyDetails />;
      case 2:
        return <MYIDDocuments />;
      case 3:
        return <ChangePassword />;
      case 4:
        return <IDVerification />;
      case 5:
        return <SeifieVerification />;
      case 6:
        return <AddBusiness />;

      default:
        return <MyProfile />;
    }
  };

  const selectPage = (currentPage) => {
    setPage(currentPage);
    window.scrollTo(0, 0);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div id='outer-container'>
        <div id='page-wrap'>{profileScreens()}</div>
      </div>
    </motion.div>
  );
};
