import { Button, Col, Row } from 'react-bootstrap';

import styles from './styles.module.css';
import { GoogleIcon } from '../Icons';
import SquareLogoAnimate from '../SquareLogoAnimate/SquareLogoAnimate';
import { useContext } from 'react';
import appContext from '../../context/app-context';

function getUrlParamsAsString(url) {
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);
  let result = '';
  params.forEach((value, key) => {
    result += `${key}=${value}&`;
  });
  // Remove the trailing '&'
  return result.slice(0, -1);
}

export const SocialLogin = ({ signInType }) => {
  const { setContinuePage } = useContext(appContext);

  const urlObj = new URL(window.location.href);
  const params = new URLSearchParams(urlObj.search);

  const welcomeMessages = () => {
    if (signInType.includes('signin')) {
      return (
        <>
          <p className='pb-4 fw-400 fs-14'>
            Welcome Back
            <br />
            <br />
            <strong className='fw-700'>
              To access your signed copies,
              <br />
              please continue with
            </strong>
          </p>
        </>
      );
    } else if (signInType.includes('signone')) {
      return (
        <>
          <p style={{ paddingBottom: '40px' }} className='fw-400 fs-14'>
            To access your <strong className='fw-700'>dewly</strong> profile,
            <br />
            please continue with
          </p>
        </>
      );
    } else if (signInType.includes('new')) {
      return (
        <>
          <p className='pb-4 fw-700 fs-18'>Continue with</p>
        </>
      );
    } else if (signInType.includes('existing')) {
      return (
        <>
          <p className='pb-4 fw-400 fs-14'>
            Sign with <strong className='fw-700'>dewly</strong>
            <br />
            <br />
            Please provide us with your email address
          </p>
        </>
      );
    } else if (signInType.includes('signup')) {
      return (
        <>
          <p className='pb-4 fw-400 fs-14'>
            Join <strong className='fw-700'>dewly</strong>
            <br />
            <br />
            Leave signature scribbles in the previous era,
            <br />& sign files with surety and security fit for this era.
          </p>
        </>
      );
    } else {
      return (
        <>
          <p style={{ paddingBottom: '40px' }} className='fw-400 fs-14'>
            To access your signed copies,
            <br />
            please continue with...
          </p>
        </>
      );
    }
  };

  const login = (provider) => {
    switch (provider) {
      case 'google':
        const params = getUrlParamsAsString(window.location.href);
        const socialLoginUrl = `${
          process.env.REACT_APP_BASE_PATH
        }auth/google/redirect${params ? `?${params}` : ''}`; // Replace with your actual social login URL
        window.location.href = socialLoginUrl;
        break;
      case 'apple':
        break;
      default:
        break;
    }
  };
  return (
    <>
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page p-relative'
        >
          <div className={styles.centerContent}>
            <div className='mt-4 pt-4 pb-5'>
              <SquareLogoAnimate />
            </div>
            <div className='text-center'>
              {welcomeMessages()}
              <div className='mb-3'>
                <div className='text-center pb-2 fw-700'>
                  <Col
                    xs={12}
                    className='d-flex justify-content-center flex-column align-items-center '
                  >
                    <Button
                      variant='outline'
                      onClick={() => login('google')}
                      className={styles.button}
                    >
                      <span className={styles.smallIcon}>
                        <GoogleIcon />
                      </span>
                      Continue with Google{' '}
                    </Button>
                    <Button
                      variant='outline'
                      onClick={() => login('apple')}
                      className={styles.button}
                      disabled
                    >
                      <span
                        className={`${styles.smallIcon} ${styles.appleIcon}`}
                      >
                        <svg
                          fill='currentColor'
                          height='24'
                          viewBox='0 0 21 24'
                          width='21'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='m20.3432 8.18608c-.1146.06685-2.8426 1.47764-2.8426 4.60572.1286 3.5673 3.4426 4.8184 3.4994 4.8184-.0568.0668-.5003 1.7042-1.814 3.4206-1.0425 1.4785-2.1997 2.9692-3.9568 2.9692-1.6715 0-2.2715-.9854-4.2-.9854-2.07116 0-2.65718.9854-4.2429.9854-1.75715 0-3.00001-1.5705-4.09939-3.0352-1.42827-1.9169-2.6422432-4.9252-2.68510049-7.8136-.02888211-1.5306.28602549-3.0352 1.08541049-4.31312 1.12826-1.78411 3.14255-2.99522 5.34225-3.03516 1.6854-.05296 3.18541 1.07828 4.21403 1.07828.9857 0 2.8285-1.07828 4.9136-1.07828.9.00087 3.3.25351 4.7861 2.38316zm-9.8423-2.68876c-.3-1.39777.5283-2.79554 1.2997-3.68716.9857-1.078283 2.5426-1.81016 3.8851-1.81016.0857 1.39777-.4575 2.76863-1.4283 3.76704-.8711 1.07828-2.3711 1.89003-3.7565 1.73028z'
                            fill='currentColor'
                          />
                        </svg>
                      </span>
                      Continue with Apple (Coming soon)
                    </Button>
                    {params.has('transaction') ? (
                      <></>
                    ) : (
                      <button
                        onClick={() => setContinuePage(1)}
                        className={styles.link}
                      >
                        Login to your Business account
                      </button>
                    )}
                  </Col>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footerLogo}>
            <img
              src='/static/media/dewly-logo.95b074ed119097ec1853a1fdc0320f6b.svg'
              alt='dewly'
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
