import React, { useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { Col, Form, Row, Button } from 'react-bootstrap';
import { WhiteChevronRightIcon } from '../../../../components/Icons';
import appContext from '../../../../context/app-context';
import axios from 'axios';
import SpinnerAnimation from '../../../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import { useNavigate } from 'react-router-dom';

export default function SignOTP() {
  const { setContinuePage, resendOtp, email, setShowPassword, signingType } =
    useContext(appContext);
  const [otp, setOtp] = useState(1021);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {}, []);

  const validateOtp = async () => {
    let url = new URL(window.location.href);
    let newPath = url.pathname + url.search;
    navigate(newPath.replace('continue', 'sign'));
    sessionStorage.setItem('otp_confirmed', true);
    return;
    try {
      setShowLoader(true);
      const { data } = await axios.post('check-otp', {
        otp: otp,
        email: email
      });

      if (data.success) {
        setShowLoader(false);
        if (signingType === 'signin') {
          let url = new URL(window.location.href);
          let newPath = url.pathname + url.search;
          window.location.href = newPath.replace('continue', 'sign');
          console.log(newPath);
          window.location.href = newPath;
        } else {
          setShowPassword(data.password);
          setContinuePage(2);
        }
      }
    } catch (e) {
      setShowLoader(false);
      console.log(e);
    }
  };

  return (
    <>
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          <div className='text-center pt-5'>
            <p className='pb-4 fw-500 fs-14'>
              Please enter the OTP sent to your mobile number
              <br />
              073 1234 123
            </p>
            <Form>
              <Form.Group className='mb-4 ps-3 pe-3'>
                <div className='text-center pb-3'>
                  <label className={styles.label}>OTP</label>
                </div>
                <Form.Control
                  type='number'
                  inputMode='numeric'
                  defaultValue={otp}
                  onChange={(e) => setOtp(e.currentTarget.value)}
                  className={styles.otpInput}
                />
              </Form.Group>
              <div className='text-center ps-3 pe-3'>
                <div className='text-center pb-3'>
                  <p className={styles.otpLink} onClick={resendOtp}>
                    Send me a new OTP
                  </p>
                </div>
              </div>
              <div className={styles.proceed}>
                {showLoader ? (
                  <div className='h-54 p-relative m-t-40'>
                    <SpinnerAnimation />
                  </div>
                ) : (
                  <Button
                    variant='dark'
                    className='btn btn-dark rounded-circle btn-md-rounded pulse-animate'
                    disabled={!otp}
                    onClick={(e) => validateOtp()}
                  >
                    <WhiteChevronRightIcon />
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
}
