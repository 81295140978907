import * as Sentry from '@sentry/react';
import axios from 'axios';
import ViewAcceptAcknowledgements from '../../../../components/Acknowledgements/ViewAcceptAcknowledgements';
import SigningHeader from '../../../../components/Pages/Signing/Header/Header';
import SpinnerAnimation from '../../../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import appContext from '../../../../context/app-context';
import { motion } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import DocumentList from '../Finalisation/DocumentList/DocumentList';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import AdditionalFields from '../../../shared/SidebarMenu/AdditionalFields';

export default function SingleFile() {
  const {
    setCurrentPage,
    signatory,
    setSingleSigning,
    currentDocument,
    email,
    transaction,
    selfie,
    manualverification,
    signatoryDocuments,
    getToken,
    setContinuePage,
    documents,
  } = useContext(appContext);
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [url, setUrl] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState('');
  const [signFiles, setSignFiles] = useState(false);
  const [showAcceptSign, setShowAcceptSign] = useState(true);
  const [validatePassed, setValidatePassed] = useState(false);
  const fieldRef = useRef();

  // ACKNOWLEDGEMENTS
  const [userAcknowledgemets, setUserAcknowledgemets] = useState([]);
  const [showAcknowledgement, setShowAcknowledgement] = useState(false);
  const [signSingleFile, setSignSingleFile] = useState(false);

  function previousPage() {
    if (manualverification) {
      setCurrentPage(9);
      return;
    }
    setCurrentPage(2);
  }

  function sign() {
    setSignFiles(true);
    let documentIndex = signatoryDocuments.findIndex(
      (document) => document.uuid === currentDocument.uuid
    );

    let document =
      documentIndex !== -1 ? signatoryDocuments[documentIndex] : null;
    console.log('SINGLE FILE SIGN', userAcknowledgemets)
    
    if (userAcknowledgemets?.length > 0) {
      setShowAcknowledgement(true);
      return;
    }
    handleSignDocument();
  }

  useEffect(() => {
    console.log(signFiles, userAcknowledgemets?.length)
    if (signFiles && userAcknowledgemets?.length == 0) {
      handleSignDocument(signSingleFile);
    }
  }, [userAcknowledgemets]);

  useEffect(() => {
    getSelfie();
  }, []);

  async function getSelfie() {
    if (selfie) {
      setUrl(selfie);
    } else {
      try {
        const { data } = await axios.get(`selfie/${signatory.email}`);
        if (data.success && data.selfie) {
          setUrl(data.selfie);
        }
      } catch (e) {
        console.log(e);
        if (e.response?.data?.message === 'Unauthenticated.') {
          sessionStorage.removeItem('token');
          await getToken();
          setContinuePage(0);
          navigate('/continue');
        }
      }
    }
  }

  function disregard() {
    setShowAcknowledgement(false);
    setSignFiles(true);
    setCurrentPage(1);
    window.location.reload();
  }

  async function handleSignDocument() {
    fieldRef.current.save();
    setError('');
    setShowLoader(true);
    console.log(currentDocument);
    console.log(userAcknowledgemets);
    console.log('handleSignDocument', userAcknowledgemets)
    if (userAcknowledgemets?.length > 0) {
      setShowAcknowledgement(true);
      return;
    }

    console.log('Complete Sig handleSignDocument')

    try {
      const { data } = await axios.post(
        `transactions/${transaction.id}/complete-signatory/${currentDocument.uuid}`,
        {
          email: email
        }
      );

      setShowLoader(false);
      if (data.success) {
        //Finalisatiion
        setCurrentPage(5);
        setSingleSigning(true);
      } else {
        setTimeout(() => {
          setError('Something went wrong please try again later!');
        }, 1500);
      }
    } catch (e) {
      setTimeout(() => {
        setShowLoader(false);
        setError('Something went wrong please try again later!');
      }, 1500);
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        {showAcceptSign ||
          (showAcknowledgement && <div className={styles.overlay}></div>)}
        <SigningHeader previousPage={previousPage} />
        <Col md={{ span: 6, offset: 3 }}>
          <div className={styles.main}>
            <div className='text-center'>
              <div className={styles.flipImage}>
                {url ? (
                  <img src={url} className={styles.photoPreview} alt='Selfie' />
                ) : (
                  ''
                )}
              </div>
              <p className='fw-800 fs-18'>
                Hi{' '}
                {signatory && signatory.first_name ? (
                  <>
                    {signatory?.first_name}{' '}
                    {signatory.last_name ? <>{signatory?.last_name},</> : null}
                  </>
                ) : (
                  <span className='lowercase'>{signatory?.email},</span>
                )}
              </p>
              <AdditionalFields
                  validate={setValidatePassed}
                  ref={fieldRef}
                  documents={documents}
                  transaction={transaction}
                  user={signatory}
                />
              <p className='mt-5 fw-500 fs-14'>
                By signing,
                <br />
                you agree to, & accept
                <br />
                the terms & conditions of
                <br />
                <br />
                {
                  <DocumentList
                    key={currentDocument.id}
                    title={currentDocument.title}
                  />
                }
              </p>
              <p className={styles.error}>{error ? error : ''}</p>
              <div className={styles.acceptSignFooter}>
                {showAcceptSign && (
                  <>
                    <Row className='m0 pt-4'>
                      <Col
                        lg={{ span: 6, offset: 3 }}
                        md={{ span: 6, offset: 3 }}
                        xs={{ span: 12, offset: 0 }}
                      >
                        {showLoader ? (
                          <div className='p-b-50'>
                            <SpinnerAnimation />
                          </div>
                        ) : (
                          <Button
                            variant='dark'
                            className='btn-rounded btn-block mb-3'
                            onClick={sign}
                          >
                            <span className='fs-16 fw-700'>Accept & Sign</span>
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {/*  ACCEPT ACKNOWLEDDEMENT */}
      <ViewAcceptAcknowledgements
        currentDocument={currentDocument}
        document={document}
        transaction={transaction}
        signatory={signatory}
        setUserAcknowledgemets={setUserAcknowledgemets}
        showAcknowledgement={showAcknowledgement}
        generatePDFafterAccept={false}
        signSingleFile={true}
        disregard={disregard}
      />
    </motion.div>
  );
}
