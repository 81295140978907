import * as Sentry from '@sentry/react';
import appContext from '../../../../context/app-context';
import { motion } from 'framer-motion';
import React, { useContext, useEffect } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import styles from './styles.module.css';

export default function Overview(props) {
  const { setSendingPage, stats, user, transactionStats, token } =
    useContext(appContext);

  useEffect(() => {
    // getUser();
    transactionStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user]);

  useEffect(() => {
    console.log(stats);
  }, [stats]);

  function resetEdit() {
    sessionStorage.removeItem('transactionId');
    sessionStorage.removeItem('transactionName');
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page p-relative pt-5'
        >
          <div className='text-center pt-5'>
            <p className='mb-1 fs-16 fw-800'>
              Hello {user && user.first_name ? user?.first_name : user?.email}
            </p>
            <p className='pb-4'>
              Thank you for signing your files
              <br />
              the smart, fast and safe way.
            </p>
          </div>
          <Card bg='light' border='light' className='p-3 rounded'>
            <Card.Body>
              <Card.Title as='h6'>Signed by me</Card.Title>
              <Card.Text className='mt-4'>
                <Row>
                  <Col xs={6}>
                    <p className='fw-500 fs-18 mb-1'>{stats?.transactions}</p>
                    <p>Transactions</p>
                  </Col>
                  <Col xs={6}>
                    <p className='fw-500 fs-18 mb-1'>{stats?.documents}</p>
                    <p>Documents</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <p className='fw-500 fs-18 mb-1'>{stats?.percentage}%</p>
                    <p>Complete</p>
                  </Col>
                  <Col xs={6}>
                    <p className='fw-500 fs-18 mb-1'>0</p>
                    <p>Remaining</p>
                  </Col>
                </Row>
                <Row className='mt-3'>
                  <Col xs={12}>
                    <Button
                      variant='light'
                      className='btn-block btn-white btn-rounded btn-shadow'
                    >
                      See more
                    </Button>
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
          <div className={styles.footer}>
            <Button
              variant='dark'
              className='btn-block btn-rounded fw-700'
              onClick={(e) => (setSendingPage(1), resetEdit())}
            >
              Send documents for signing
            </Button>
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}
