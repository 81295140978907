import React from 'react';
import styles from './styles.module.css';
import { motion } from 'framer-motion';
import LogoPart1 from '../../assets/images/logo-part-1.svg';
import LogoPart2 from '../../assets/images/logo-part-2.svg';

export default function SquareLogoAnimate() {
  return (
    <div className={styles.fileImage}>
      <span className={styles.logoIconSplit}>
        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ type: 'tween', duration: 0.8, delay: 1.5 }}
          src={LogoPart1}
          alt='logo'
          className={styles.outerIcon}
        />
        <motion.img
          initial={{ x: -10, y: -10, scale: 0 }}
          animate={{ x: 0, y: 0, scale: 1 }}
          transition={{ type: 'spring', delay: 1 }}
          src={LogoPart2}
          alt='logo'
          className={styles.innerIcon}
        />
      </span>
    </div>
  );
}
