import React, { useContext, useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import styles from './styles.module.css';
import { Col, Form, Row } from 'react-bootstrap';
import UploadFiles from '../../../../components/UploadFiles/UploadFiles';
import appContext from '../../../../context/app-context';
import { useNavigate } from 'react-router-dom';
import Cross from '../../../../assets/images/cross.svg';
import home from '../../../../assets/images/home.svg';
import axios from 'axios';
import * as Sentry from '@sentry/react';

export default function CreateTransaction(props) {
  const [name, setName] = useState('');
  const [showError, setShowError] = useState(false);
  const navigate = useNavigate();
  const { user, currentTransaction, setSendingPage, getToken, setContinuePage } = useContext(appContext);
  const ref = useRef(null);

  useEffect(() => {
    console.log('currentTransaction', currentTransaction);
    let transactionName = sessionStorage.getItem('transactionName');
    if (transactionName) {
      setName(transactionName);

      if (currentTransaction?.closed == 1) {
        setSendingPage(3);
      }
    }
  }, [user]);

  useEffect(() => ref.current && ref.current.focus());

  useEffect(() => {
    sessionStorage.setItem('transactionName', name);
  }, [name]);

  useEffect(() => {
    // sessionStorage.removeItem('transactionId');
    // sessionStorage.removeItem('transactionName');
  }, []);

  async function deleteTransaction(redirect) {
    let transactionId = sessionStorage.getItem('transactionId');
    if (transactionId) {
      try {
        await axios.delete(`transactions/${transactionId}/delete`);
        sessionStorage.removeItem('transactionName');
        sessionStorage.removeItem('transactionId');
        if (redirect) {
          navigate('/');
        }
      } catch (e) {
        console.log(e);
        Sentry.captureException(e);
        if (e.response?.data?.message === 'Unauthenticated.') {
          sessionStorage.removeItem('token');
          await getToken();
          setContinuePage(0);
          navigate('/continue');
        }
      }
    } else {
      sessionStorage.removeItem('transactionName');
      sessionStorage.removeItem('transactionId');
      if (redirect) {
        navigate('/');
      }
    }
  }

  const clearTransactionValue = () => {
    ref.current.focus();
    ref.current.value = '';
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0 '>
        <Col xs={{ span: 12 }} className='p0'>
          <div className={styles.header}>
            <p className='mb-1 fw-500 fs-14'>Step 1 of 4</p>
            <p className='m0 fw-700 fs-16'>Upload your files</p>
            <img
              src={home}
              alt='home'
              className={styles.homeBtn}
              onClick={() => navigate('/')}
            />
          </div>
        </Col>
      </Row>
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          <Form className='pt-5 mt-2'>
            <Form.Group className='mb-4 pt-5 text-center'>
              <div className='pb-3'>
                <label className={styles.label}>TRANSACTION</label>
              </div>
              <div className='p-relative'>
                <Form.Control
                  type='text'
                  ref={ref}
                  defaultValue={name}
                  className={styles.transactionInput}
                  onChange={(e) => setName(e.currentTarget.value)}
                  placeholder='Name this transaction'
                />
                {name && (
                  <img
                    src={Cross}
                    alt='clear'
                    onClick={clearTransactionValue}
                    className='inputIcon'
                  ></img>
                )}
              </div>
              {showError ? (
                <small className={styles.error}>
                  Transaction name is required.
                </small>
              ) : (
                ''
              )}
            </Form.Group>

            <div className='text-center'>
              <UploadFiles
                setShowError={setShowError}
                transactionName={name}
                deleteTransaction={deleteTransaction}
              />
            </div>
          </Form>
          {/* <div className={styles.cancelBtn}>
            <span
              onClick={(e) => deleteTransaction(true)}
              className='m0 fw-700 fs-16 pointer'
            >
              Cancel
            </span>
          </div> */}
        </Col>
      </Row>
    </motion.div>
  );
}
