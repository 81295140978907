import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { WhiteChevronRightIcon } from '../../../../components/Icons';
import SpinnerAnimation from '../../../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import SpinnerAnimationGrey from '../../../../components/Spinners/SpinnerAnimationGrey/SpinnerAnimationGrey';
import appContext from '../../../../context/app-context';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import styles from './styles.module.css';

export default function OTP() {
  const { setContinuePage, email, setShowPassword, signingType } =
    useContext(appContext);
  const [otp, setOtp] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [showOTPLoader, setShowOTPLoader] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const OTPInput = useRef(null);
  useEffect(() => OTPInput.current && OTPInput.current.focus());

  const validateOtp = async (e) => {
    e.preventDefault();
    setError('');
    try {
      setShowLoader(true);
      const { data } = await axios.post('check-otp', {
        otp: otp,
        email: email
      });

      if (data.success) {
        if (signingType === 'signin') {
          let url = new URL(window.location.href);
          let newPath = url.pathname + url.search;
          navigate(newPath.replace('continue', 'sign'));
        } else {
          setShowPassword(data.password);
          setContinuePage(2);
        }
      }
      setShowLoader(false);
    } catch (e) {
      setShowLoader(false);
      setError(e.response.data.message);
      console.log(e);
      Sentry.captureException(e);
    }
  };

  const resendOtp = async () => {
    setMessage('');
    setError('');
    try {
      setShowOTPLoader(true);
      await axios.post('resend-otp', {
        email: email
      });
      setShowOTPLoader(false);
      setMessage('OTP sent successfully! Please check your email.');
      setTimeout(() => {
        setMessage('');
      }, 3000);
    } catch (e) {
      setError(e.response.data.message);
      setShowOTPLoader(false);
      console.log(e);
      Sentry.captureException(e);
    }
  };

  return (
    <>
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          <div className='text-center pt-5'>
            <p className='pb-4 fw-500 fs-14'>
              Please verify your email address by entering
              <br />
              the OTP that was emailed to you.
            </p>
            <Form onSubmit={validateOtp}>
              <Form.Group className='mb-4 ps-3 pe-3'>
                <div className='text-center pb-3'>
                  <label className={styles.label}>OTP</label>
                </div>
                <Form.Control
                  type='number'
                  inputMode='numeric'
                  ref={OTPInput}
                  onChange={(e) => setOtp(e.currentTarget.value)}
                  className={styles.otpInput}
                />
                {error ? <small className={styles.error}>{error}</small> : ''}
              </Form.Group>
              <div className='text-center ps-3 pe-3'>
                {showOTPLoader ? (
                  <div className='p-relative text-center pb-2'>
                    <p className={styles.otpLink}>Sending...</p>
                    <SpinnerAnimationGrey />
                  </div>
                ) : (
                  <div className='text-center pb-2'>
                    {message ? (
                      <small className={styles.success}>{message}</small>
                    ) : (
                      <p
                        className={styles.otpLink}
                        onClick={(e) => resendOtp()}
                      >
                        Send me a new OTP
                      </p>
                    )}
                  </div>
                )}
              </div>
              <div className={styles.proceed}>
                {showLoader ? (
                  <div className='h-54 p-relative m-t-40'>
                    <SpinnerAnimation />
                  </div>
                ) : (
                  <Button
                    variant='dark'
                    className='btn btn-dark rounded-circle btn-md-rounded pulse-animate'
                    disabled={!otp}
                    onClick={(e) => validateOtp(e)}
                  >
                    <WhiteChevronRightIcon />
                  </Button>
                )}
              </div>
            </Form>
          </div>

          <div className={styles.footer}>
            <span
              className='fw-700 fs-16 pointer fw-700'
              onClick={(e) => setContinuePage(0)}
            >
              Back
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
}
