export const SUPPORTED_DOCUMENT_TYPES = [
  {
    id: 'NEW_ID_CARD',
    name: 'RSA Id Card',
    sides: ['front', 'back']
  },
  {
    id: 'GREEN_ID',
    name: 'RSA Green ID',
    sides: ['front']
  },
  {
    id: 'PASSPORT',
    name: 'Passport',
    sides: ['front']
  }
];

export const ALLOWED_SELFIE_ATTEMPTS = 1;
