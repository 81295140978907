import * as Sentry from '@sentry/react';
import axios from 'axios';
import GoBackHeader from '../../../../components/GoBackHeader/GoBackHeader';
import { ChevronRightIcon } from '../../../../components/Icons/Icons';
import SpinnerAnimation from '../../../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import appContext from '../../../../context/app-context';
import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

export default function TransactionDetails() {
  const navigate = useNavigate();
  const {
    setTransactionPage,
    setCurrentTransaction,
    user,
    setCurrentDocument,
    setSendingPage,
    currentDocument,
    getToken,
    setContinuePage,
  } = useContext(appContext);
  const [transaction, setTransaction] = useState(null);
  const [signed, setSigned] = useState(0);
  const [showLoader, setShowLoader] = useState(false);

  function previousPage() {
    navigate('/');
    console.log('hit p0');
  }

  function viewTransaction(document) {
    setTransactionPage(1);
    setCurrentDocument(document);
    setCurrentTransaction(transaction);
  }

  function viewDocument(document) {
    setTransactionPage(2);
    setCurrentDocument(document);
    setCurrentTransaction(transaction);
  }

  useEffect(() => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let transactionUuid = params.get('transaction');
    let documentUuid = params.get('document');
    getTransaction(transactionUuid);
    if (documentUuid) {
      viewDocument(currentDocument);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTransaction = async (transactionUuid) => {
    try {
      setShowLoader(true);
      const { data } = await axios.get(
        `transactions/${transactionUuid}/details`
      );
      data.transaction.id = data.transaction.uuid;
      setTransaction(data.transaction);
      setCurrentTransaction(data.transaction);
      let signed =
        data.transaction?.documents.length - data.transaction?.unsigned_docs;
      setSigned(signed);
      setTimeout(() => {
        setShowLoader(false);
      }, 1000);
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  async function deleteTransaction() {
    try {
      await axios.delete(`transactions/${transaction.id}/delete`);
      navigate('/');
    } catch (e) {
      console.log(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  }

  function editTransaction() {
    sessionStorage.setItem('transactionName', transaction.name);
    sessionStorage.setItem('transactionId', transaction.id);
    transaction?.closed ? setSendingPage(3) : setSendingPage(1);
    navigate('/send');
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page p-b-250'
        >
          <GoBackHeader
            previousPage={previousPage}
            pageName={'Transaction details'}
          />
          <div className={styles.spacer} />
          {showLoader ? (
            <div className='p-relative m-t-25 mb-4'>
              <SpinnerAnimation />
            </div>
          ) : (
            <>
              <div className='text-center'>
                <p className='fs-16 fw-700 color-lightgray mb-1'>
                  {transaction?.name}
                </p>
                <p className='fs-12 fw-700 color-lightgray mb-4'>
                  {signed}/{transaction?.documents.length}{' '}
                  {signed == 1 ? 'file' : 'files'} signed
                </p>
              </div>
              {transaction &&
                transaction?.documents.map((document, index) => {
                  return (
                    <Card
                      key={index}
                      className='mb-2 cardWhite pointer'
                      onClick={(e) => viewTransaction(document)}
                    >
                      <Card.Body className={styles.cardBody}>
                        <span className='badge badge-info fw-600'>PDF</span>
                        <Card.Text className={styles.cardBodyText} as='h6'>
                          <div>
                            <p className='m0 fw-700 fs-14'>{document?.title}</p>
                          </div>
                          <span className='color-lightgray fs-12 fw-700'>
                            Signed by {transaction?.signed}/{transaction?.total}{' '}
                            signers
                          </span>
                        </Card.Text>
                        <div className={styles.viewFileBtn}>
                          <ChevronRightIcon />
                        </div>
                      </Card.Body>
                    </Card>
                  );
                })}
            </>
          )}
        </Col>
      </Row>

      {transaction && transaction?.sender_id == user?.id ? (
        <div className={styles.footer}>
          <Row className='m0'>
            <Col
              lg={{ span: 4, offset: 4 }}
              md={{ span: 6, offset: 3 }}
              className={styles.footerInner}
            >
              <Row className='m0'>
                <Col xs={{ span: `${transaction?.signed ? '12' : '6'}` }}>
                  <Button
                    variant='outline-dark'
                    className='btn-block btn-rounded fw-700 fs-16'
                    onClick={(e) => editTransaction(e)}
                    disabled={transaction?.signed}
                  >
                    Edit
                  </Button>
                </Col>
                {transaction?.signed ? (
                  ''
                ) : (
                  <Col xs={{ span: 6 }}>
                    <Button
                      variant='outline-dark'
                      className='btn-block btn-rounded fw-700 fs-16'
                      onClick={(e) => deleteTransaction()}
                    >
                      Delete
                    </Button>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        ''
      )}
    </motion.div>
  );
}
