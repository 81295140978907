import * as Sentry from '@sentry/react';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import DewlyLogo from '../../assets/images/dewly-logo.svg';
import Menu from '../../components/Menu/Menu';
import ClosedTransactions from '../../components/Pages/Dashboard/Transactions/ClosedTransactions';
import DraftTransactions from '../../components/Pages/Dashboard/Transactions/DraftTransactions';
import OpenTransactions from '../../components/Pages/Dashboard/Transactions/OpenTransactions';
import TransactionsPending from '../../components/Pages/Dashboard/Transactions/TransactionsPending';
import FirstTimeUser from '../../components/Pages/FirstTimeUser/FirstTimeUser';
import SpinnerAnimationGrey from '../../components/Spinners/SpinnerAnimationGrey/SpinnerAnimationGrey';
import SquareLogoAnimate from '../../components/SquareLogoAnimate/SquareLogoAnimate';
import appContext from '../../context/app-context';
import styles from './styles.module.css';

export const Dashboard = () => {
  const { user, transactionStats, token, getToken, setContinuePage } = useContext(appContext);
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [pendingOthers, setPendingOthers] = useState([]);
  const [pendingMe, setPendingMe] = useState([]);
  const [closed, setClosed] = useState([]);
  const [draft, setDraft] = useState([]);
  const [isBusiness, setIsBusiness] = useState(false);
  const [logo, setLogo] = useState(null);
  const [business, setBusiness] = useState(null);

  const getBusiness = async () => {
    try {
      const { data } = await axios.get(`users/${user?.id}/business`);
      setBusiness(data.business);
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  const getCompanyLogo = async () => {
    try {
      const { data } = await axios.get(
        `users/${user.id}/documents?type=company_logo`
      );
      setLogo(data.company_logo);
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  useEffect(() => {
    if (user?.has_business) {
      setIsBusiness(true);
      getBusiness();
      getCompanyLogo();
    }
    if (token) {
      transactionStats();
    }
  }, [token, user]);

  useEffect(() => {
    async function get() {
      await getPendingMe();
      await getPendingOthers();
      await getClosedTransactions();
      await getDraftTransactions();
    }
    if (token) {
      get();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, user]);

  const getPendingMe = async () => {
    try {
      setShowLoader(true);
      const { data } = await axios.get(`transactions/pending/me`);
      setPendingMe(data.transactions);
    } catch (e) {
      if (e?.response?.status == 401) {
        Sentry.captureException(e);
      }
      console.log(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  const getPendingOthers = async () => {
    try {
      setShowLoader(true);
      const { data } = await axios.get(`transactions/pending/all`);
      if (data.success) {
        setShowLoader(false);
        setPendingOthers(data.transactions);
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  // CLOSED
  const getClosedTransactions = async () => {
    try {
      setShowLoader(true);
      const { data } = await axios.get(`transactions/closed/all`);

      if (data.success) {
        setShowLoader(false);
        setClosed(data.transactions);
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  // DRAFTS
  const getDraftTransactions = async () => {
    try {
      setShowLoader(true);
      const { data } = await axios.get(`transactions/draft/all`);

      if (data.success) {
        setShowLoader(false);
        setDraft(data.transactions);
      }
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  function goToSending() {
    navigate('/send');
  }

  function resetEdit() {
    sessionStorage.removeItem('transactionId');
    sessionStorage.removeItem('transactionName');
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white p0 min-height-full-page'
        >
          <div className={styles.main}>
            <Menu />
            <div className='mb-1'>
              <SquareLogoAnimate />
            </div>
            <div className='text-center pt-3'>
              {isBusiness ? (
                <p className='mb-1 fs-16 fw-800'>
                  Hello {user && user.first_name ? user?.first_name : null}
                </p>
              ) : (
                <p className='mb-1 fs-16 fw-800'>{business?.name}</p>
              )}

              <p className='pb-4'>
                Thank you for signing your files
                <br />
                the smart, fast and safe way.
              </p>
            </div>
            {!isBusiness && (
              <>
                {showLoader ? (
                  <div className='h-54 p-relative m-t-25'>
                    <SpinnerAnimationGrey />
                  </div>
                ) : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    {pendingMe.length ? (
                      <div className='p-relative'>
                        <div className={styles.transactions}>
                          <TransactionsPending transactions={pendingMe} />
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                  </motion.div>
                )}
              </>
            )}

            {/* DRAFT */}
            {showLoader ? (
              <div className='h-54 p-relative m-t-25'>
                <SpinnerAnimationGrey />
              </div>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                {draft.length ? (
                  <div className='p-relative'>
                    <div className={styles.openTransactions}>
                      <DraftTransactions transactions={draft} />
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </motion.div>
            )}

            {/* PENDING OTHERS */}
            {showLoader ? (
              <div className='h-54 p-relative m-t-25'>
                <SpinnerAnimationGrey />
              </div>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                {pendingOthers.length ? (
                  <div className='p-relative'>
                    <div className={styles.transactions}>
                      <OpenTransactions transactions={pendingOthers} />
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </motion.div>
            )}

            {/* CLOSED */}
            {showLoader ? (
              <div className='h-54 p-relative m-t-25'>
                <SpinnerAnimationGrey />
              </div>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
              >
                {closed.length ? (
                  <div className='p-relative'>
                    <div className={styles.openTransactions}>
                      <ClosedTransactions transactions={closed} />
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </motion.div>
            )}

            {!pendingMe && !pendingOthers && !closed ? (
              <div className={styles.transactionSpacer} />
            ) : null}

            {!user?.dashboard_viewed ? (
              <FirstTimeUser />
            ) : (
              <>
                <div className='divider mt-5 mb-5' />
                <div className={styles.logoContainer}>
                  <img src={DewlyLogo} alt='dewly' />
                </div>
              </>
            )}
          </div>
        </Col>
      </Row>
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className=''
        >
          <div className={styles.footer}>
            <Row className='m0'>
              <Col
                lg={{ span: 4, offset: 4 }}
                md={{ span: 6, offset: 3 }}
                className={styles.footerInner}
              >
                <Button
                  variant='dark'
                  className='btn-rounded btn-block fw-700'
                  onClick={(e) => (goToSending(), resetEdit())}
                >
                  Send documents for signing
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </motion.div>
  );
};
