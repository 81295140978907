import { Viewer, Worker } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import ChevronLeftIcon from '../../../../assets/images/chevron-left.svg';
import downloadIcon from '../../../../assets/images/download-white.svg';
import home from '../../../../assets/images/home.svg';
import appContext from '../../../../context/app-context';
import { motion } from 'framer-motion';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import packageJson from '../../../../../package.json';
import styles from './styles.module.css';

// PDF styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

import CreateEditAcknowledgement from '../../../../components/Acknowledgements/CreateEditAcknowledgement';

export default function ViewTransactionDocument(props) {
  const { setTransactionPage, currentDocument, currentTransaction, user } =
    useContext(appContext);
  const navigate = useNavigate();
  const [cacheBuster, setCacheBuster] = useState(0);

  const documentUrl = `${process.env.REACT_APP_BASE_URL}transactions/${currentTransaction?.uuid}/documents/${currentDocument?.uuid}/view`;
  const downloadUrl = `${process.env.REACT_APP_BASE_PATH}documents/${currentDocument?.uuid}/download`;

  const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];
  const zoomPluginInstance = zoomPlugin();
  const { Zoom } = zoomPluginInstance;

  // Go to Previous document
  function previousPage() {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let documentUuid = params.get('document');

    if (documentUuid) {
      navigate('/send');
    } else {
      setTransactionPage(1);
    }

    props.setOpenViewDocument(false);
  }

  const getCacheBuster = useCallback(() => {
    console.log('cache buster')
    return Math.floor(10000 + Math.random() * 90000).toString()
  }, [])

  // useEffect(() => {
  //   setCacheBuster(Math.floor(10000 + Math.random() * 90000).toString());
  // }, [])

  // RENDER HTML
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0 bg-lightgray no-horizontal-scroll'>
        <div className={styles.header}>
          <Row className='m0 w-100'>
            <Col className='p0' md={{ span: 6, offset: 3 }}>
              <div className='d-flex align-items-center justify-content-center pe-3 ps-3'>
                <div className={styles.chevronLeftIcon} onClick={previousPage}>
                  <img src={ChevronLeftIcon} alt='go back' />
                </div>
                <p className='fs-16 fw-700 m0'>{currentDocument?.title}</p>
                <img
                  src={home}
                  alt='home'
                  className={styles.homeBtn}
                  onClick={() => navigate('/')}
                />
              </div>
            </Col>
          </Row>
        </div>

        <Col xs={{ span: 12 }} lg={{ span: 8, offset: 2 }}>
          <div className={`pdf-viewer ${styles.main}`}>
            <div
              className='fileViewerZoomButton'
              style={{
                position: 'fixed',
                right: '20px',
                backgroundColor: '#fff',
                borderRadius: '6px',
                zIndex: '1'
              }}
            >
              <Zoom levels={[0.4, 0.8, 1, 1.2, 1.5, 2.4, 3.2]} />
            </div>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
            >
              <div className={styles.docViewer}>
                <Viewer
                  className='border-radius-6'
                  fileUrl={`${documentUrl}?${getCacheBuster()}`}
                  plugins={[zoomPluginInstance]}
                />
              </div>
            </Worker>
            {currentTransaction?.closed == 1 ? (
              <div className={`pulse-animate ${styles.btnSign}`}>
                <a
                  href={downloadUrl}
                  download={currentDocument.path}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={downloadIcon}
                    alt='sign'
                    className={` btn-rounded ${styles.signBtn}`}
                  />
                </a>
              </div>
            ) : (
              ''
            )}

            {/* CREATE AND EDIT ACKNOWLEDGEMENTS */}
            {props.isEdit || props.isNew ? (
              <CreateEditAcknowledgement
                setTransactionPage={setTransactionPage}
                currentDocument={currentDocument}
                currentTransaction={currentTransaction}
                user={user}
                isNew={props.isNew}
                isEdit={props.isEdit}
                documentIndex={props.documentIndex}
                acknowledgementIndex={props.acknowledgementIndex}
                selectedAcknowledgement={props.selectedAcknowledgement}
                previousPage={previousPage}
              />
            ) : (
              ''
            )}
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}
