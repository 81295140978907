import { motion } from 'framer-motion';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Webcam from 'react-webcam';
import { useCallback, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';

import CameraIcon from '../../assets/images/camera-white.svg';
import Identification from '../../assets/images/identification.svg';
import styles from './styles.module.css';
import SpinnerAnimation from '../Spinners/SpinnerAnimation/SpinnerAnimation';
import { dataURLtoFile } from '../../common/utils/utils';
import { uploadFile } from '../../common/uploadFile/uploadFile';

const videoConstraints = {
  facingMode: getCameraFacingMode(),
  screenshotQuality: 0.7
};

function getCameraFacingMode() {
  if (isMobile) {
    return {
      exact: 'environment'
    };
  }

  return 'user';
}

export default function DocumentCapture({
  onSubmit,
  onCancel,
  acceptedDocumentTypes,
  isLoading
}) {
  const [front, setFront] = useState(null);
  const [back, setBack] = useState(null);
  const [activeSide, setActiveSide] = useState('front');
  const [idDocumentType, setIdDocumentType] = useState('');
  const [error, setError] = useState('');
  const [progress, setProgress] = useState(0);

  const [continuePopup, setContinuePopup] = useState(true);

  function selectIdDocumentType(e) {
    setIdDocumentType(e.currentTarget.value);
    setError('');
  }

  function closePopup() {
    if (idDocumentType === '') {
      setError('Please select a document type');
      return;
    }
    return setContinuePopup(false);
  }

  const webcamRef = useRef(null);
  const capturePhoto = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    activeSide === 'front' ? setFront(imageSrc) : setBack(imageSrc);
  }, [webcamRef, activeSide]);

  const confirm = async () => {
    const frontOnlyDocumentIds = acceptedDocumentTypes
      .filter((doc) => !doc.sides.includes('back'))
      .map((doc) => doc.id);
    if (frontOnlyDocumentIds.includes(idDocumentType)) {
      const frontSide = dataURLtoFile(front, 'front.jpeg');
      const fileName = await uploadFile(frontSide, setProgress);
      return onSubmit({ front, idDocumentType, frontFileName: fileName });
    }

    if (activeSide === 'front') {
      return setActiveSide('back');
    }

    const frontSide = dataURLtoFile(front, 'front.jpeg');
    const frontFileName = await uploadFile(frontSide, setProgress);
    const backSide = dataURLtoFile(back, 'back.jpeg');
    const backFileName = await uploadFile(backSide, setProgress);
    console.log('BACKFILENAME', backFileName)
    return onSubmit({ front, back, idDocumentType, frontFileName, backFileName });
  };

  const clearPhoto = () => {
    if (activeSide === 'front') {
      setFront(null);
    }

    if (activeSide === 'back') {
      setBack(null);
    }
  };

  // const proceedBtn = async () => {
  //   setShowLoader(true);
  //   try {
  //     const { data } = await axios.post('validate/rsaiddoc', {
  //       id_number: idNumber,
  //       id_document: photo,

  //       country: 'South Africa',
  //       email: email
  //     });

  //     if (data.success === true) {
  //       //Selfie verification
  //       setCurrentPage(4);
  //     }
  //     setShowLoader(false);
  //   } catch (e) {
  //     //Todo remove
  //     setShowLoader(false);
  //     console.log(e);
  //   }
  // };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {continuePopup ? <div className={styles.overlay}></div> : null}
      <Row className='m0'>
        {/* <SigningHeader previousPage={previousPage} /> */}
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          <div className={styles.main}>
            <div className='text-center p-t-100'>
              {(activeSide === 'front' && front) ||
              (activeSide === 'back' && back) ? (
                <>
                  <p className='fw-700 fs-16 m-b-20'>
                    If the image is sharp and clear
                    <br />
                    please proceed
                  </p>
                  <div className='p-relative'>
                    <img
                      src={activeSide === 'front' ? front : back}
                      alt={activeSide === 'front' ? 'front' : 'back'}
                      className={styles.photo}
                    />
                  </div>
                  <p className='fw-700 fs-16 p-t-30'>
                    If unclear, please retake the photo
                  </p>
                  <Row>
                    <Col
                      lg={{ span: 4, offset: 4 }}
                      md={{ span: 8, offset: 2 }}
                      xs={{ span: 12, offset: 0 }}
                      className={`${styles.continueOrRetake}`}
                    >
                      <Col>
                        {isLoading ? (
                          <SpinnerAnimation />
                        ) : (
                          <Button
                            variant='dark'
                            className='btn-block btn-rounded fw-700'
                            onClick={confirm}
                          >
                            Continue
                          </Button>
                        )}
                      </Col>
                      <Col>
                        <Button
                          variant='outline-dark'
                          className='btn-block btn-rounded fw-700 m-t-15 btn-no-hover-change'
                          onClick={clearPhoto}
                          disabled={isLoading}
                        >
                          Retake Photo
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <div className=''>
                    <p className='fw-700 fs-16 m-b-30'>
                      Take a photo of the {activeSide} of your
                      <br />
                      identity card or passport document
                    </p>
                    <div className={styles.webcamContainer}>
                      <div className={styles.webcamBorders}>
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                      <Webcam
                        ref={webcamRef}
                        audio={false}
                        height={480}
                        width={640}
                        screenshotFormat='image/jpeg'
                        className={styles.webcam}
                        screenshotQuality={0.7}
                        forceScreenshotSourceSize={true}
                        //height='100%'
                        videoConstraints={videoConstraints}
                      />
                    </div>
                    <p className='fw-700 fs-16 m-t-30'>
                      Please ensure that the photo is clearly
                      <br />
                      visible and in-focus.
                    </p>
                    <div className={styles.cameraBtns}>
                      {isLoading ? (
                        <SpinnerAnimation />
                      ) : (
                        <Button
                          variant='dark'
                          className='rounded-circle btn-md-rounded p0 pulse-animate rotate'
                          onClick={capturePhoto}
                        >
                          <img src={CameraIcon} alt='selfie' />
                        </Button>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>

            {continuePopup && (
              <div className={styles.footerAnimate}>
                <Row className='m0'>
                  <Col
                    lg={{ span: 4, offset: 4 }}
                    md={{ span: 6, offset: 3 }}
                    className='bg-white border-rounded-top'
                  >
                    <div className='pb-4 pt-5'>
                      <img
                        src={Identification}
                        alt='ID'
                        className={styles.icon}
                      />
                    </div>
                    <div className='pt-3 pb-5'>
                      <p className='fw-700 fs-16'>
                        Take a photo of your
                        <br />
                        <div className='mt-3 mb-3'>
                          <Form>
                            <Form.Select
                              onChange={selectIdDocumentType}
                              className='text-center'
                            >
                              <option value='' disabled selected>
                                Select document type
                              </option>
                              {acceptedDocumentTypes.map((type) => (
                                <option
                                  key={type.id}
                                  selected={idDocumentType === type.id}
                                  value={type.id}
                                >
                                  {type.name}
                                </option>
                              ))}
                            </Form.Select>
                          </Form>
                        </div>
                        Please ensure that the photo(s) are clearly
                        <br />
                        visible and in-focus
                      </p>
                    </div>
                    <p className={styles.error}>{error ? error : ''}</p>
                    <Button
                      variant='dark'
                      className='btn-block btn-rounded fw-700 fs-16'
                      onClick={closePopup}
                    >
                      Continue
                    </Button>
                    <p className='mt-4 mb-3' onClick={onCancel}>
                      <span className='pointer fw-700 fs-16'>Cancel</span>
                    </p>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}
