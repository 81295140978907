import * as Sentry from '@sentry/react';
import axios from 'axios';
import Email from '../../components/Pages/Continue/Email/Email';
// import CaptureUser from '../../components/Pages/Signing/CaptureUser/CaptureUser';
import DocumentView from '../../components/Pages/Signing/DocumentView/DocumentView';
import Finalisation from '../../components/Pages/Signing/Finalisation/Finalisation';
import IDVerification from '../../components/Pages/Signing/IDverification/IDVerification';
import IdentityPassportPhoto from '../../components/Pages/Signing/IdentityPassportPhoto/IdentityPassportPhoto';
import MultipleFiles from '../../components/Pages/Signing/MultipleFiles/MultipleFiles';
import Overview from '../../components/Pages/Signing/Overview/Overview';
import SingleFile from '../../components/Pages/Signing/SingleFile/SingleFile';
import appContext from '../../context/app-context';
import { useContext, useEffect, useState } from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { getToken } from '../../common/utils/utils';
import {
  registerManualVerification,
  verifyDocument,
  verifyIdByFace
} from '../../common/api';
import { FaceCapture } from '../../components/FaceCapture/FaceCapture';
import DocumentCapture from '../../components/DocumentCapture/DocumentCapture';
import {
  ALLOWED_SELFIE_ATTEMPTS,
  SUPPORTED_DOCUMENT_TYPES
} from '../../common/contants';
import DocumentSigning from '../../components/Pages/Signing/DocumentSigning/DocumentSigning';
import ManualVerification from '../../components/Pages/Signing/ManualVerification/ManualVerification';
import { getVerification } from '../../common/api/api';

export const Sign = () => {
  const {
    getTransaction,
    transaction,
    getDocuments,
    documents,
    currentDocument,
    setContinuePage,
    getSignatory,
    signatory,
    currentPage,
    sender,
    user,
    checkToken,
    selfie,
    setIdNumber,
    setCurrentPage,
    setCountry,
    setSelfie,
    setSelfieVerified,
    idNumber,
    country,
    email,

  } = useContext(appContext);
  const [page, setPage] = useState(currentPage);
  const navigate = useNavigate();
  const [business, setBusiness] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selfieAttempts, setSelfieAttempts] = useState(0);
  const [error, setError] = useState(null);
  const [selfieFileName, setSelfieFileName] = useState(null);
  const [acceptedDocumentTypes, setAcceptedDocumentTypes] = useState(
    SUPPORTED_DOCUMENT_TYPES
  );

  const getUnsignedDocuments = () => documents.filter((document) => !document.signed)

  useEffect(() => {
    console.log(currentPage);
  }, [currentPage]);

  useEffect(() => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let transactionUuid = params.get('transaction');
    let self = params.get('self');
    let signatory = params.get('signatory') ?? '';

    const verifyUser = async () => {
      const token = getToken();
      console.log('token', token);
      if (!user && token) {
        const validUser = await checkToken(token, email, signatory);

        if (!validUser) {
          navigate({
            pathname: '/continue',
            search: `?${createSearchParams(
              'transaction=' +
                transactionUuid +
                '&self=' +
                self +
                '&signatory=' +
                signatory
            )}`
          });
        }
      }
      console.log('verifyUser', token, user);
    };

    getTransaction(transactionUuid);
    getDocuments(transactionUuid, self, signatory);
    getSignatory(transactionUuid, self, signatory);
    selectPage(currentPage);

    verifyUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (sender?.has_business) {
      getBusinessInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sender]);

  const getBusinessInfo = async () => {
    try {
      const { data } = await axios.get(
        `transactions/business-info/${sender?.id}`
      );
      setBusiness(data.business);
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      
    }
  };

  const handleVerifiedIdNumber = ({ idNumber, country }) => {
    if (country !== 'South Africa') {
      const newAcceptedDocumentTypes = SUPPORTED_DOCUMENT_TYPES.filter(
        (type) => type.id === 'PASSPORT'
      );
      setAcceptedDocumentTypes(newAcceptedDocumentTypes);
    }
    setIdNumber(idNumber);
    setCountry(country);
    setCurrentPage(4);
    setPage(4);
  };

  const handleSelfieSubmission = async ({ faceString, fileName }) => {
    console.log('fileName', fileName)
    if (!faceString || !fileName) {
      return;
    }
    setSelfie(faceString);
    setSelfieFileName(fileName);

    const userCountry = country ? country : signatory.country;
    console.log("CHECKING COUNTRY", userCountry)
    if (userCountry === 'South Africa') {
      setIsLoading(true);
      const response = await verifyIdByFace({
        idNumber: idNumber ? idNumber : signatory?.id_number,
        userCountry,
        email,
        fileName,
        transactionId: transaction?.id,
        documentId: currentDocument?.id
      });
      setIsLoading(false);

      if (response.success) {
        setSelfieVerified(true);
        setSelfieAttempts(0);
        
        if (getUnsignedDocuments().length > 1) {
          return setCurrentPage(6);
        }
        return setCurrentPage(7);
      } else {
        if (selfieAttempts >= ALLOWED_SELFIE_ATTEMPTS) {
          setSelfieAttempts(0);
          return setCurrentPage(4.5);
        }
        return setSelfieAttempts(selfieAttempts + 1);
      }
    }



    try {
      const previouslyVerified = await getVerification();
    
      console.log('previouslyVerified', previouslyVerified);
      if (previouslyVerified.verified) {
        setSelfieVerified(true);
        if (getUnsignedDocuments().length > 1) {
          return setCurrentPage(6);
        }
        return setCurrentPage(7);
      }
  } catch (e) {
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        getToken();
        setContinuePage(0);
        setCurrentPage(1);
        navigate('/continue');
      }
      Sentry.captureException(e);
  }

    return setCurrentPage(4.5);
  };

  const submitIdDocument = async ({ idDocumentType, frontFileName, backFileName }) => {
    setIsLoading(true);

    const verifiedDocument = await verifyDocument({
      idNumber: idNumber ? idNumber : signatory?.id_number,
      idDocumentType,
      country: country ? country : signatory?.country,
      email,
      frontFileName,
      backFileName,
      selfieFileName,
    });
    setIsLoading(false);
    if (verifiedDocument.success) {
      setSelfieVerified(true);
      if (getUnsignedDocuments().length > 1) {
        return setCurrentPage(6);
      }
      return setCurrentPage(7);
    } else {
      setError(verifiedDocument.errors);
      return setCurrentPage(8);
    }
  };

  const submitManualVerification = async ({
    first_name,
    last_name,
    middle_name
  }) => {
    try {
      setIsLoading(true);
      const manualVerificationData = {
        first_name: first_name ? first_name : signatory?.first_name,
        last_name: last_name ? last_name : signatory?.last_name,
        id_number: idNumber ? idNumber : signatory?.id_number,
        middle_name,
        country,
        email,
        signatory
      };
      const manualVerification = await registerManualVerification(
        manualVerificationData
      );
      setIsLoading(false);
      if (manualVerification.success) {
        console.log('MV unsigned docs', getUnsignedDocuments())
        if (getUnsignedDocuments().length > 1) {
          console.log('MV setting page to 6')
          setCurrentPage(6);
          setPage(6);
        } else {
          console.log('MV setting page to 7')
          setCurrentPage(7);
          setPage(7);
        }
        
        setSelfieVerified(true);
        window.scrollTo(0, 0);
      }
    } catch (e) {
      if (e.response?.status === 422) {
        setError(e.response.data.errors);
      }
      setIsLoading(false);
      window.scrollTo(0, 0);
      console.log(e);
      Sentry.captureException(e);
    }
  };

  const cancelVerification = () => {
    setCurrentPage(3);
  };

  const cancelSigning = () => {
    setCurrentPage(2);
  };
  
  const signing = () => {
    switch (page) {
      case 1:
        return (
          <Overview
            signatory={signatory}
            transaction={transaction}
            documents={documents}
            sender={sender}
            business={business}
          />
        );
      case 2:
        return <DocumentView />;
      case 3:
        return <IDVerification onVerifyId={handleVerifiedIdNumber} />;
      case 4:
        return (
          <FaceCapture
            onSubmit={handleSelfieSubmission}
            isLoading={isLoading}
            previousPage={() => {
              setSelfieAttempts(0);

              if (user.verified) {
                return setCurrentPage(2);
              }
              return selectPage(3);
            }}
            attempts={selfieAttempts}
          />
        );
      case 4.5:
        return (
          <DocumentCapture
            onSubmit={submitIdDocument}
            onCancel={cancelVerification}
            acceptedDocumentTypes={acceptedDocumentTypes}
            isLoading={isLoading}
          />
        );
      case 4.6:
        return <DocumentSigning onCancel={cancelSigning} selfie={selfie} />;
      case 5:
        return <Finalisation />;
      case 6:
        return <MultipleFiles />;
      case 7:
        return <SingleFile />;
      case 8:
        return (
          <ManualVerification
            onSubmit={submitManualVerification}
            onCancel={cancelVerification}
            isLoading={isLoading}
          />
        );
      case 9:
        return (
          <IdentityPassportPhoto
            signatory={signatory}
            transaction={transaction}
            documents={documents}
            business={business}
          />
        );
      //Sign in / Create account flow
      case 10:
        return <Email setUserData={setUserData} userData={userData} />;

      default:
        return (
          <Overview
            signatory={signatory}
            transaction={transaction}
            documents={documents}
            sender={sender}
            business={business}
          />
        );
    }
  };

  const selectPage = (currentPage) => {
    setPage(currentPage);
    window.scrollTo(0, 0);
  };

  return (
    <div id='outer-container'>
      <div id='page-wrap'>{signing()}</div>
    </div>
  );
};
