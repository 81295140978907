import DewlyLogo from '../../../../assets/images/dewly-logo.svg';
import welcomeSlideFour from '../../../../assets/images/welcomeSlideFour.svg';
import welcomeSlideOne from '../../../../assets/images/welcomeSlideOne.svg';
import welcomeSlideThree from '../../../../assets/images/welcomeSlideThree.svg';
import welcomeSlideTwo from '../../../../assets/images/welcomeSlideTwo.svg';
import axios from 'axios';
import CarouselSlider from '../../../../components/CaroueslSlider/CarouselSlider';
import appContext from '../../../../context/app-context';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

export default function LastPage() {
  const { email, getTransaction, getDocuments, getSignatory } =
    useContext(appContext);
  const [mail, setMail] = useState(email);
  const [existingUser, setExistingUser] = useState(false);
  const navigate = useNavigate();
  const [userType, setUserType] = useState('new');

  const checkUser = async () => {
    try {
      const { data } = await axios.get(`check-user?email=${mail}`);
      setExistingUser(data.success);
    } catch (e) {
      console.log(e);
      
    }
  };

  useEffect(() => {
    checkUser();
  }, [email]);

  const proceed = () => {
    navigate({
      pathname: '/'
    });
  };

  return (
    <Row className='m0'>
      <Col
        lg={{ span: 4, offset: 4 }}
        md={{ span: 6, offset: 3 }}
        className='bg-white min-height-full-page'
      >
        <div className='text-center'>
          <p className='p-t-80 fw-800 fs-18'>Welcome</p>
        </div>
        <CarouselSlider>
          <div className='text-center'>
            <div className='center-content w-100'>
              <div className='h-90'>
                <img src={welcomeSlideOne} alt='img1' />
              </div>
              <p className='fw-500 fs-14 mb-0 m-t-40'>
                Digital signing
                <br />
                without signature scribbles
              </p>
            </div>
          </div>
          <div className='text-center'>
            <div className='center-content w-100'>
              <div className='h-90'>
                <img src={welcomeSlideTwo} alt='img1' />
              </div>
              <p className='fw-500 fs-14 mb-0 m-t-40'>
                Biometric
                <br />
                identity verification
              </p>
            </div>
          </div>
          <div className='text-center'>
            <div className='center-content w-100'>
              <div className='h-90'>
                <img src={welcomeSlideThree} alt='img1' />
              </div>
              <p className='fw-500 fs-14 mb-0 m-t-40'>
                Process automation
                <br />
                saves time & money
              </p>
            </div>
          </div>
          <div className='text-center'>
            <div className='center-content w-100'>
              <div className='h-90'>
                <img src={welcomeSlideFour} alt='img1' />
              </div>
              <p className='fw-500 fs-14 mb-0 m-t-40'>
                Eliminate document & identity fraud
                <br />
                with state-of-the-art encryption
              </p>
            </div>
          </div>
        </CarouselSlider>
        <div className={styles.footer}>
          <Row className='m0'>
            <Col
              lg={{ span: 4, offset: 4 }}
              md={{ span: 6, offset: 3 }}
              className={styles.footerInner}
            >
              <Button
                variant='outline-dark'
                className='btn btn-outline-dark btn btn-rounded btn-block mb-5 mt-1 fw-700 fs-14'
                onClick={proceed}
              >
                Continue
              </Button>
              <img src={DewlyLogo} alt='dewly' />
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}
