export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return parts;
};

export const getAccessToken = () => {
  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);

  return params.get('token');
};

export const getRedirect = () => {
  const redirect = sessionStorage.getItem('redirect')
    ? sessionStorage.getItem('redirect')
    : '/';
  sessionStorage.removeItem('redirect');
  return redirect;
};

export const setRedirect = (redirect) => {
  return sessionStorage.setItem('redirect', redirect);
};

export const storeToken = (token) => {
  return sessionStorage.setItem('token', token);
};

export const getToken = () => {
  return sessionStorage.getItem('token');
};
export function validateIDNumber(IdNumber) {
  let ex = /^[0-9]{13}$/;
  return ex.test(IdNumber) && controlDigitValidate(IdNumber);
}

function controlDigitValidate(idNumber) {
  console.log('here');
  let checkDigit = parseInt(idNumber[12], 10);

  let oddDigitsSum = 0;

  for (let i = 0; i < idNumber.length - 1; i += 2) {
    oddDigitsSum += parseInt(idNumber[i], 10);
  }
  let evenDigits = '';
  for (let j = 1; j < idNumber.length - 1; j += 2) {
    evenDigits += idNumber[j];
  }
  evenDigits = parseInt(evenDigits, 10);
  evenDigits *= 2;
  evenDigits = '' + evenDigits;

  let sumOfEvenDigits = 0;
  for (let k = 0; k < evenDigits.length; k++) {
    sumOfEvenDigits += parseInt(evenDigits[k], 10);
  }
  let total = sumOfEvenDigits + oddDigitsSum;
  let computedCheckDigit = 10 - (total % 10);

  if (computedCheckDigit === 10) {
    computedCheckDigit = 0;
  }
  console.log('lastly', computedCheckDigit, checkDigit);
  return computedCheckDigit === checkDigit;
}

export function removeTokenAndStringifyParams() {
  const urlObj = new URL(window.location.href);
  const params = new URLSearchParams(urlObj.search);
  params.delete('token');
  let result = '';
  params.forEach((value, key) => {
    result += `${key}=${value}&`;
  });
  // Remove the trailing '&'
  return result.slice(0, -1);
}

export function validateEmail(val) {
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regEmail.test(val)) {
    sessionStorage.setItem('cell', val);
    return false;
  }
  return true;
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
}
