import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import appContext from './app-context';
const countries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia Herzegovina',
  'Botswana',
  'Brazil',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Cape Verde',
  'Cayman Islands',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  'Cote D Ivoire',
  'Croatia',
  'Cruise Ship',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Polynesia',
  'French West Indies',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kuwait',
  'Kyrgyz Republic',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Palestine',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Pierre Miquelon',
  'Samoa',
  'San Marino',
  'Satellite',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'South Africa',
  'South Korea',
  'Spain',
  'Sri Lanka',
  'St Kitts Nevis',
  'St Lucia',
  'St Vincent',
  'St. Lucia',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  "Timor L'Este",
  'Togo',
  'Tonga',
  'Trinidad Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks Caicos',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Venezuela',
  'Vietnam',
  'Virgin Islands (US)',
  'Yemen',
  'Zambia',
  'Zimbabwe'
];

const AppState = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [token, setToken] = useState('');
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [currentTransaction, setCurrentTransaction] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [document, setDocument] = useState(null);
  const [idNumber, setIdNumber] = useState('');
  const [idNumberVerified, setIdNumberVerified] = useState(false);
  const [selfieVerified, setSelfieVerified] = useState(false);
  const [documentSigned, setDocumentSigned] = useState(false);
  const [signatory, setSignatory] = useState(null);
  const [existing, setExisting] = useState(null);
  const [sender, setSender] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [continuePage, setContinuePage] = useState(0);
  const [sendingPage, setSendingPage] = useState(1);
  const [billingPage, setBillingPage] = useState(0);
  const [profilePage, setProfilePage] = useState(0);
  const [businessProfilePage, setBusinessProfilePage] = useState(0);
  const [teamMembersPage, setTeamMembersPage] = useState(0);
  const [workSpacePage, setWorkSpacePage] = useState(0);
  const [transactionPage, setTransactionPage] = useState(0);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [singleSigning, setSingleSigning] = useState(false);
  const [otp, setOtp] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [stats, setStats] = useState(null);
  const [email, setEmail] = useState('');
  const [originalEmail, setOriginalEmail] = useState('');
  const [card, setCard] = useState(null);
  const [billing, setBilling] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [signingType, setSigningType] = useState(null);
  const [count, setCount] = useState(0);
  const [unSignedDocuments, setUnSignedDocuments] = useState(0);
  const [encodedEmail, setEncodedEmail] = useState('');
  const [invoice, setInvoice] = useState(null);
  const navigate = useNavigate();
  const [country, setCountry] = useState('');
  const [manualVerification, setManualVerification] = useState(false);
  const [selfie, setSelfie] = useState(false);
  const [signatoryDocuments, setSignatoryDocuments] = useState(false);
  const [notVerified, setNotVerified] = useState(false);

  // useEffect(() => {
  //   if (showPassword) {
  //     setContinuePage(2);
  //   }
  // }, [showPassword]);

  const getUser = async () => {
    let newCount = count + 1;
    setCount(newCount);
    try {
      const { data } = await axios.get('users');

      setUser(data.data);
      setUserId(data.data?.id);
      setIsAuth(true);
    } catch (e) {
      if (
        e.response.data?.error === 'token_expired' ||
        e.response.data?.message === 'Unauthenticated.'
      ) {
        let currentToken = sessionStorage.getItem('token');
        if (currentToken) {
          await checkToken(currentToken);
        }
      }
      console.log(e);
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    async function startApp() {
      const storedToken = sessionStorage.getItem('token');
      console.log('START APP');
       if (!storedToken) {
        return;
      }
      
      axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
      await getUser();
      await getToken();
      sessionStorage.removeItem('redirect');
    }
    startApp();
  }, []);

  const removeToken = () => {
    sessionStorage.removeItem('token');
    setToken(null);
  };

  const checkToken = async (token, email = '', signatory = '') => {
    try {
      console.log('signatory' + signatory);
      console.log('token', token);
      const encodedToken = encodeURIComponent(token);
      const encodedEmail = encodeURIComponent(email);
      const encodedSignatory = encodeURIComponent(signatory);
      let url = `check-token?token=${encodedToken}`;
      if (email.length > 1) {
        url = `check-token?token=${encodedToken}&email=${encodedEmail}`;
      }
      if (signatory.length > 1) {
        url = `check-token?token=${encodedToken}&email=${encodedEmail}&signatory=${encodedSignatory}`;
      }

      const { data } = await axios.get(url);
      if (!data.success || !data.user) {
        removeToken();
        const urlObj = new URL(window.location.href);
        if (urlObj.pathname === '/sign' || urlObj.pathname === '/continue') {
          const params = new URLSearchParams(urlObj.search);
          if (params.get('transaction')) {
            return false;
          }
        }
        navigate('/continue');
        return false;
      }

      setUser(data.user);
      setUserId(data.user?.id);
      setIsAuth(true);
      return data.user;
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }

      removeToken();
      navigate('/continue');
      setContinuePage(0);
      console.log(error.config);
    }
  };

  const getToken = async () => {
    const storedToken = sessionStorage.getItem('token');
    setToken(storedToken);
  };

  const login = async (email) => {
    try {
      const { data } = await axios.post('login', {
        email: email,
        id_number: idNumber
      });
      sessionStorage.removeItem('token');
      if (data.success) {
        setUser(data.user);
        setUserId(data.user.uuid);
      } else {
        setErrorMessage(true);
      }
    } catch (e) {
      setErrorMessage(false);
      console.log(e);
      Sentry.captureException(e);
    }
  };

  const getTransaction = async (transactionUuid) => {
    try {
      const { data } = await axios.get(`transactions/${transactionUuid}`);
      setTransaction(data.data);
    } catch (e) {
      setErrorMessage(false);
      console.log(e);
      Sentry.captureException(e);
    }
  };

  const getDocuments = async (transactionUuid, self, signatory = '') => {
    try {
      let url = `transactions/${transactionUuid}/documents`;
      if (self) {
        setEncodedEmail(self);
        url = `transactions/${transactionUuid}/documents?email=${self}`;
        if (signatory.length > 1) {
          console.log('this is hitting');
          const { signatoryData } = await axios.get(
            `transactions/${transactionUuid}/signatory/user-details?signatory=${signatory}`
          );
          if (signatoryData) {
            setEncodedEmail(signatoryData.email);
          }
          url = `transactions/${transactionUuid}/documents?email=${self}&signatory=${signatory}`;
        }
        let currentToken = sessionStorage.getItem('token');
        // url = `transactions/${transactionUuid}/documents`;
        // console.log(currentToken, self, signatory)
        await checkToken(currentToken, self, signatory);
      }
      const { data } = await axios.get(url);
      console.log('Documents', data);
      setDocuments(data.documents);
      setUnSignedDocuments(data.unsigned);
    } catch (e) {
      setErrorMessage(false);
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  const getDocument = async (transactionUuid, documentUuid) => {
    try {
      const { data } = await axios.get(
        `transactions/${transactionUuid}/documents/${documentUuid}`
      );
      setDocument(data.data);
    } catch (e) {
      setErrorMessage(false);
      Sentry.captureException(e);
      console.log(e);
    }
  };

  const verifyIdNumber = async (idNumber) => {
    try {
      const { data } = await axios.post(`validate/rsaid`, {
        id_number: idNumber,
        //TODO: add country code
        country: 'South Africa',
        email: email
      });

      if (data.success) {
        setIdNumberVerified(true);
        setIdNumber(idNumber);
      } else {
        setIdNumberVerified(false);
      }
    } catch (e) {
      setIdNumberVerified(false);
      setErrorMessage(false);
      Sentry.captureException(e);
      console.log(e);
    }
  };

  const getSignatory = async (transactionUuid, email, signatory = '') => {
    try {
      console.log('GEtting signatory');
      let url = `transactions/${transactionUuid}/signatory?email=${email}`;
      if (signatory.length > 1) {
        url = `transactions/${transactionUuid}/signatory?email=${email}&signatory=${signatory}`;
      }
      const { data } = await axios.get(url);
      setSignatory(data.signer);
      setSender(data.sender);
      setEmail(data.email);
      setOriginalEmail(data.email);
      setSignatoryDocuments(data.documents);
      setExisting(data.existing);
    } catch (e) {
      setErrorMessage(false);
      Sentry.captureException(e);
      console.log(e);
    }
  };

  const verifySelfie = async (imageUrl) => {
    try {
      const { data } = await axios.post(`verify-selfie`, {
        image_url: imageUrl,
        id_number: idNumber
      });

      if (data.success) {
        setSelfieVerified(true);
      }
    } catch (e) {
      setErrorMessage(false);
      Sentry.captureException(e);
      console.log(e);
    }
  };

  const completeSigning = async () => {
    let documentUuid = '';
    if (singleSigning) {
      documentUuid = `/${currentDocument.id}`;
    }
    try {
      const { data } = await axios.get(
        `transactions/${transaction.id}/complete-signatory${documentUuid}`
      );

      if (data.success) {
        setDocumentSigned(true);
      }
    } catch (e) {
      setErrorMessage(false);
      Sentry.captureException(e);
      console.log(e);
    }
  };

  const checkOtp = async (otp) => {
    try {
      const { data } = await axios.post('check-otp', {
        otp: otp
      });

      if (data.success) {
        setOtp(data.otp);
      } else {
        setOtp('');
      }
    } catch (e) {
      setErrorMessage(false);
      Sentry.captureException(e);
      console.log(e);
    }
  };

  const savePassword = async (password, confirm, email) => {
    try {
      const { data } = await axios.post('update-password', {
        password: password,
        password_confirmation: confirm,
        email: email
      });

      if (data.success) {
        setUser(data.user);
        setUserId(data.user.uuid);
        setIsAuth(true);
        setToken(data.token);
        sessionStorage.setItem('token', data.token);
        setErrorMessage(false);
      } else {
        setErrorMessage(true);
      }
    } catch (e) {
      setErrorMessage(false);
      Sentry.captureException(e);
      console.log(e);
    }
  };

  const transactionStats = async () => {
    try {
      const { data } = await axios.get(`transactions/stats/all`);
      setStats(data);
    } catch (e) {
      setErrorMessage(false);
      Sentry.captureException(e);
      console.log(e);
    }
  };

  const getTransactions = async () => {
    try {
      const { data } = await axios.get(`workspace/transactions`);
      setTransactions(data.data);
    } catch (e) {
      setErrorMessage(false);
      Sentry.captureException(e);
      console.log(e);
    }
  };

  const getCard = async () => {
    try {
      const { data } = await axios.get(`users/${user?.id}/cards`);
      if (data.success) {
        setCard(data.card);
      }
    } catch (e) {
      setErrorMessage(false);
      Sentry.captureException(e);
      console.log(e);
    }
  };

  const getBilling = async () => {
    try {
      const { data } = await axios.get(`/billings`);
      if (data.success) {
        setBilling(data.billings);
      }
    } catch (e) {
      setErrorMessage(false);
      Sentry.captureException(e);
      console.log(e);
    }
  };

  return (
    <appContext.Provider
      value={{
        //user
        isAuth,
        user,
        token,
        errorMessage,
        login,
        getUser,
        getToken,
        setUser,
        setShowPassword,
        showPassword,
        setIsAuth,
        setToken,
        checkToken,
        encodedEmail,

        //Transactions
        getTransaction,
        transaction,
        transactions,
        getTransactions,
        stats,
        transactionStats,
        currentTransaction,
        setCurrentTransaction,

        //documents
        documents,
        getDocuments,
        getDocument,
        document,
        setCurrentDocument,
        currentDocument,
        unSignedDocuments,

        //ID and selfie
        email,
        setEmail,
        idNumber,
        setIdNumber,
        idNumberVerified,
        // verifyIdNumber,
        verifySelfie,
        selfieVerified,
        setSelfieVerified,
        setNotVerified,
        notVerified,

        //signing
        selfie,
        setSelfie,
        documentSigned,
        completeSigning,
        getSignatory,
        signatory,
        currentPage,
        setCurrentPage,
        setSingleSigning,
        singleSigning,
        sender,
        signingType,
        setSigningType,
        setDocumentSigned,
        signatoryDocuments,
        existing,
        originalEmail,

        //Continue
        setContinuePage,
        continuePage,
        userId,
        otp,
        checkOtp,
        savePassword,

        //sending
        setSendingPage,
        sendingPage,

        //billing
        setBillingPage,
        billingPage,
        billing,
        card,
        getCard,
        getBilling,
        setCard,

        //Invoices
        setInvoice,
        invoice,

        //profile
        setProfilePage,
        profilePage,

        //business profile
        setBusinessProfilePage,
        businessProfilePage,

        //team members
        setTeamMembersPage,
        teamMembersPage,

        //work spaces
        setWorkSpacePage,
        workSpacePage,

        //transactions
        setTransactionPage,
        transactionPage,

        //countries
        countries,
        country,
        setCountry,

        //manualVerification
        setManualVerification,
        manualVerification
      }}
    >
      {children}
    </appContext.Provider>
  );
};

export default AppState;
