import { useContext, useEffect, useState } from 'react';

import IndividualOrBusiness from '../../components/Pages/Continue/Email/IndividualOrBusiness';
import TermsAndPrivacyConfirmation from '../../components/Pages/Continue/Email/TermsAndPrivacyConfirmation';
import LastPage from '../../components/Pages/Continue/LastPage/LastPage';
import OTP from '../../components/Pages/Continue/OTP/OTP';
import Phone from '../../components/Pages/Continue/Phone/Phone';
import SignOTP from '../../components/Pages/Continue/SignOTP/SignOTP';
import AddBusiness from '../../components/Pages/Profile/AddBusiness/AddBusiness';
import { SocialLogin } from '../../components/SocialLogin/SocialLogin';
import appContext from '../../context/app-context';
import { getToken, setRedirect } from '../../common/utils/utils';
import axios from 'axios';
import AccountConfirmation from '../../components/Pages/Continue/Email/AccountConfirmation';
import Email from '../../components/Pages/Continue/Email/Email';
import AddPassword from '../../components/Pages/Continue/AddPassword/AddPassword';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { SocialFlow } from '../../components/Pages/Continue/SocialFlow/SocialFlow';

export const Continue = () => {
  const {
    continuePage,
    getTransaction,
    getDocuments,
    getSignatory,
    setContinuePage,
    setSigningType,
    user
  } = useContext(appContext);
  const navigate = useNavigate();
  const [page, setPage] = useState(continuePage);
  const [userData, setUserData] = useState(null);
  const [signInType, setSignInType] = useState('signone');
  const [registeringBusiness, setRegisteringBusiness] = useState(false);

  useEffect(() => {
    const load = async () => {
      const url = new URL(window.location.href);
      const params = new URLSearchParams(url.search);
      const token = getToken();
      const newUser = params.get('new');

      if (params.has('new') && parseInt(newUser)) {
        return setContinuePage(1.1);
      }

      if (token && user && !user.terms_and_conditions) {
        return setContinuePage(1.2);
      }

      if (token && user && !user?.cell) {
        return setContinuePage(5);
      }

      if (params && params.size) {
        setRedirect(`/sign${url.search}`);
      }

      let signType = params.get('sign_type');
      if (signType === 'new') {
        setSignInType('new');
      }

      if (signType === 'existing') {
        setSignInType('existing');
        setSigningType('signin');
        if (token) {
          setContinuePage(4);
        }
      }

      if (signType === 'signup') {
        setSignInType('signup');
        setContinuePage(2.1);
      }
    };
    load();
  }, []);

  useEffect(() => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let transactionUuid = params.get('transaction');

    if (transactionUuid) {
      let self = params.get('self');
      let signatory = params.get('signatory') ?? '';

      getTransaction(transactionUuid);
      getDocuments(transactionUuid, self, signatory);
      getSignatory(transactionUuid, self, signatory);
    }

    selectPage(continuePage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continuePage]);

  const declineTerms = async () => {
    try {
      await axios.post('logout');
    } catch (e) {
    } finally {
      setContinuePage(0);
    }
  };

  const confirmAccount = async () => {
    setContinuePage(1.2);
  };

  const cancelAccountConfirmation = async () => {
    try {
      await axios.post('logout');
    } catch (e) {
    } finally {
      setContinuePage(0);
    }
  };

  const confirmPhone = async () => {
    await completeLogin();
  };

  const completeLogin = async () => {
    console.log('COmplete login');
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let transactionUuid = params.get('transaction');
    let signatory = params.get('signatory') ?? '';
    let self = params.get('self');
    const signType = params.get('sign_type');
    const newUser = params.get('new');

    if (transactionUuid) {
      getTransaction(transactionUuid);
      getDocuments(transactionUuid, self, signatory);
      getSignatory(transactionUuid, self, signatory);

      return navigate({
        pathname: '/sign',
        search: `?${createSearchParams(
          'transaction=' +
            transactionUuid +
            '&sign_type=' +
            signType +
            '&signatory=' +
            signatory +
            '&self=' +
            self
        )}`
      });
    }

    if (newUser === '1') {
      return setContinuePage(3);
    }

    navigate('/');
  };

  const confirmTerms = async () => {
    if (!user?.cell) {
      return setContinuePage(5);
    }
    completeLogin();
  };

  const registerBusiness = async () => {
    setRegisteringBusiness(true);
    setContinuePage(1);
  };

  const continueScreens = () => {
    switch (page) {
      case 0:
        return <SocialLogin signInType={signInType} />;
      case 1:
        return (
          <Email
            setUserData={setUserData}
            confirmTerms={confirmTerms}
            declineTerms={declineTerms}
          />
        );
      case 1.1:
        return (
          <SocialFlow>
            <AccountConfirmation
              onProceed={confirmAccount}
              onCancel={cancelAccountConfirmation}
            />
          </SocialFlow>
        );
      case 1.2:
        return (
          <SocialFlow>
            <TermsAndPrivacyConfirmation
              onConfirm={confirmTerms}
              onDecline={declineTerms}
            />
          </SocialFlow>
        );
      case 1.3:
        return <OTP />;
      case 2:
        return (
          <AddPassword
            userData={userData}
            onCancel={cancelAccountConfirmation}
          />
        );
      case 2.1:
        return <IndividualOrBusiness onConfirmBusiness={registerBusiness}/>;
      case 3:
        return <LastPage />;
      case 4:
        return <SignOTP />;
      case 5:
        return <Phone onSuccess={confirmPhone} />;
      case 6:
        return <AddBusiness />;
      default:
        return <SocialLogin />;
    }
  };

  const selectPage = (continuePage) => {
    setPage(continuePage);
    window.scrollTo(0, 0);
  };

  return (
    <div id='outer-container'>
      <div id='page-wrap'>{continueScreens()}</div>
    </div>
  );
};
