import * as Sentry from '@sentry/react';
import profilePhotoPlaceholder from '../../../../assets/images/black-scribble.svg';
import ChevronDown from '../../../../assets/images/chevron-down.svg';
import axios from 'axios';
import ShowFileIDHeader from '../../../../components/ShowFileIDHeader/ShowFileIDHeader';
import SpinnerAnimation from '../../../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import Switch from '../../../../components/Switch/Switch';
import appContext from '../../../../context/app-context';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Whatsapp } from 'react-social-sharing';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';

export default function ViewTransaction() {
  const {
    setTransactionPage,
    currentTransaction,
    user,
    setCurrentDocument,
    currentDocument,
    getToken,
    setContinuePage,
  } = useContext(appContext);
  const navigate = useNavigate();
  const [showSigned, setShowSigned] = React.useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showUserInfo, setShowUserInfo] = useState(false);
  const [signedUser, setSignedUser] = useState(null);
  const [showShare, setShowShare] = useState(false);
  const [whatsappLink, setWhatsappLink] = useState('');
  const [sent, setSent] = useState(false);
  const [whatsappMsg, setWhatsappMsg] = useState('');
  const [userSelfie, setUserSelfie] = useState('');

  useEffect(() => {
    if (!currentTransaction.unsigned_docs) {
      setShowSigned(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function previousPage() {
    setTransactionPage(0);
  }

  const updatePage = () => {
    setCurrentDocument(currentDocument);
    setTransactionPage(2);
  };

  const sendingLink = async (appLink, appMessage) => {
    setWhatsappLink(appLink);
    setWhatsappMsg(appMessage);
    // setShowShare(true);
  };

  const showUser = async (user) => {
    setSignedUser(user);

    console.log('user ');
    console.log(user);

    getSignatoryData(user);

    setShowUserInfo(true);
  };

  const sendAgain = async () => {
    // eslint-disable-next-line eqeqeq
    if (currentTransaction?.sender_id == user.id) {
      setShowLoader(true);
      console.log('send file', currentTransaction);
      try {
        await axios.get(
          `transactions/${
            currentTransaction.uuid
          }/signatories/send?reminder=${true}`
        );
        setShowLoader(false);
        setSent(true);
      } catch (e) {
        setShowLoader(false);
        console.log(e);
        Sentry.captureException(e);
        if (e.response?.data?.message === 'Unauthenticated.') {
          sessionStorage.removeItem('token');
          await getToken();
          setContinuePage(0);
          navigate('/continue');
        }
      }
    } else {
      updatePage();
    }
  };

  const getSignatoryData = async (user) => {
    try {
      const { data } = await axios.get(
        `documents/${currentDocument.uuid}/${user.uuid}/signatory`
      );

      console.log('Signatory Data');
      console.log(data);
      console.log('User Data');
      console.log(user);

      if (data) {
        if (data?.selfie) {
          setUserSelfie(data?.selfie);
        } else {
          setUserSelfie(user?.selfie);
        }
      }
    } catch (e) {
      setShowLoader(false);
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  function updateSigned() {
    if (currentTransaction?.signed_users.length) {
      setShowSigned(!showSigned);
    }
  }

  const whatsappShare = {
    background: 'black',
    borderRadius: '50%',
    height: '32px',
    width: '32px',
    padding: '8px',
    position: 'relative'
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {showUserInfo ? <div className={styles.overlay}></div> : null}
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          <ShowFileIDHeader
            previousPage={previousPage}
            pageName={currentTransaction?.name}
            transactionId={currentDocument?.uuid}
            unsignedDocs={currentTransaction.unsigned_docs}
          />

          {currentTransaction.unsigned_docs ? (
            <div className={styles.spacer} />
          ) : (
            <div className={styles.spacerXL} />
          )}

          {!currentTransaction.unsigned_docs ? (
            <>
              <div className={styles.everyoneSigned}>Everyone signed</div>
            </>
          ) : (
            <Switch
              isOn={showSigned}
              handleToggle={(e) => updateSigned(e)}
              left={'Not Signed'}
              right={'Signed'}
            />
          )}

          {showSigned ? (
            currentTransaction &&
            currentTransaction?.signed_users?.map((user, index) => {
              return (
                <div key={index}>
                  <div className={styles.usersContainer}>
                    <div className={styles.user}>
                      <div
                        className={styles.initialsContainer}
                        onClick={(e) => showUser(user)}
                      >
                        <p className={`pointer ${styles.center}`}>
                          {user?.initials ? (
                            <>{user?.initials}</>
                          ) : (
                            <>{user?.email.charAt(0)}</>
                          )}
                        </p>
                      </div>
                      <p className={styles.userName}>
                        <span
                          className='fw-700 pt-3 pb-3 pe-3 pointer'
                          onClick={(e) => showUser(user)}
                        >
                          {user?.first_name ? (
                            <>
                              {user?.first_name} {user?.last_name}
                            </>
                          ) : (
                            <>{user?.email}</>
                          )}
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className={styles.footer}>
                    <Row className='m0'>
                      <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 6, offset: 3 }}
                        className={styles.footerInner}
                      >
                        {showLoader ? (
                          <div className='p-relative m-t-25 mb-4'>
                            <SpinnerAnimation />
                          </div>
                        ) : (
                          <Button
                            variant='dark'
                            className='btn-block btn-rounded fw-700'
                            onClick={updatePage}
                          >
                            View file
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                </div>
              );
            })
          ) : (
            <>
              {currentTransaction &&
                currentTransaction?.not_signed?.map((user, index) => {
                  return (
                    <div key={index} className={styles.usersContainer}>
                      <div className={styles.user} disabled='disabled'>
                        <div
                          className={`opacity-5 ${styles.initialsContainer}`}
                        >
                          <p className={styles.center}>
                            {user?.initials ? (
                              <>{user?.initials}</>
                            ) : (
                              <>{user?.email.charAt(0)}</>
                            )}
                          </p>
                        </div>
                        <p className={styles.userName}>
                          <span className='fw-700 opacity-5 pt-3 pb-3 pe-3 pointer'>
                            {user?.first_name ? (
                              <>
                                {user?.first_name} {user?.last_name}
                              </>
                            ) : (
                              <>{user?.email}</>
                            )}
                          </span>
                        </p>
                        <span
                          className={styles.share}
                          onClick={(e) =>
                            sendingLink(user.app_link, user.app_message)
                          }
                        >
                          <div className='shareIcon'>
                            <Whatsapp
                              style={whatsappShare}
                              solid
                              small
                              message={whatsappMsg}
                              link={whatsappLink}
                            />
                          </div>
                          {/* <img
                            src={shareIcon}
                            alt='share'
                            className='pointer'
                          /> */}
                        </span>
                      </div>
                    </div>
                  );
                })}

              <div className={styles.footer}>
                <Row className='m0'>
                  <Col
                    lg={{ span: 4, offset: 4 }}
                    md={{ span: 6, offset: 3 }}
                    className={styles.footerInner}
                  >
                    {showLoader ? (
                      <div className='p-relative'>
                        <SpinnerAnimation />
                      </div>
                    ) : sent ? (
                      <Button
                        variant='dark'
                        className='btn-block btn-rounded fw-700'
                      >
                        Sent
                      </Button>
                    ) : (
                      <Row className='m0'>
                        {currentTransaction?.sender_id == user?.id ? (
                          <Col xs={{ span: 6 }}>
                            <Button
                              variant='dark'
                              className='btn-block btn-rounded fw-700'
                              onClick={updatePage}
                            >
                              View file
                            </Button>
                          </Col>
                        ) : (
                          ''
                        )}
                        <Col
                          xs={{
                            span: `${
                              currentTransaction?.sender_id == user?.id
                                ? '6'
                                : '12'
                            }`
                          }}
                        >
                          <Button
                            variant='dark'
                            className='btn-block btn-rounded fw-700'
                            onClick={sendAgain}
                          >
                            {currentTransaction?.sender_id == user?.id
                              ? 'Send Again'
                              : 'View file'}
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </div>
            </>
          )}
          <div className={styles.spacer} />

          {showUserInfo ? (
            <div className={styles.footerAnimate}>
              <Row className='m0'>
                <Col
                  lg={{ span: 4, offset: 4 }}
                  md={{ span: 6, offset: 3 }}
                  className={styles.userInfo}
                >
                  <div className='text-center'>
                    <img
                      src={userSelfie ? userSelfie : profilePhotoPlaceholder}
                      className={styles.photo}
                      alt='Profile Pic'
                    />
                    <p className='fw-800 fs-24 mb-3 scribble-text'>
                      {signedUser?.first_name} {signedUser?.last_name}
                    </p>
                  </div>
                  <table width='100%' className='mb-3'>
                    {signedUser?.signed_date && (
                      <tr>
                        <td align='left'>
                          <p className='fw-700 fs-14 color-lightgray mb-1'>
                            SIGNED
                          </p>
                        </td>
                        <td align='right'>
                          <p className='fw-500 fs-14 mb-1'>
                            {signedUser?.signed_date}
                          </p>
                        </td>
                      </tr>
                    )}

                    {signedUser?.representing && (
                      <tr>
                        <td align='left'>
                          <p className='fw-700 fs-14 color-lightgray mb-1'>
                            ON BEHALF OF
                          </p>
                        </td>
                        <td align='right'>
                          <p className='fw-500 fs-14 mb-1'>
                            {signedUser?.representing}
                          </p>
                        </td>
                      </tr>
                    )}

                    {signedUser?.title && (
                      <tr>
                        <td align='left'>
                          <p className='fw-700 fs-14 color-lightgray mb-1'>
                            TITLE
                          </p>
                        </td>
                        <td align='right'>
                          <p className='fw-500 fs-14 mb-1'>
                            {signedUser?.title}
                          </p>
                        </td>
                      </tr>
                    )}

                    {signedUser?.location && (
                      <tr>
                        <td align='left'>
                          <p className='fw-700 fs-14 color-lightgray mb-1'>
                            AT
                          </p>
                        </td>
                        <td align='right'>
                          <p className='fw-500 fs-14 mb-1'>
                            {signedUser?.location.replace('<br/>', '')}
                          </p>
                        </td>
                      </tr>
                    )}

                    {signedUser?.first_name && (
                      <tr>
                        <td align='left'>
                          <p className='fw-700 fs-14 color-lightgray mb-1'>
                            BY
                          </p>
                        </td>
                        <td align='right'>
                          <p className='fw-500 fs-14 mb-1'>
                            {signedUser?.first_name} {signedUser?.last_name}
                          </p>
                        </td>
                      </tr>
                    )}

                    {signedUser?.id_number && (
                      <tr>
                        <td align='left'>
                          <p className='fw-700 fs-14 color-lightgray mb-1'>
                            ID/PASSPORT
                          </p>
                        </td>
                        <td align='right'>
                          <p className='fw-500 fs-14 mb-1'>
                            {signedUser?.id_number}
                          </p>
                        </td>
                      </tr>
                    )}

                    {signedUser?.email && (
                      <tr>
                        <td align='left'>
                          <p className='fw-700 fs-14 color-lightgray mb-1'>
                            EMAIL
                          </p>
                        </td>
                        <td align='right'>
                          <p className='fw-500 fs-14 mb-1'>
                            {signedUser?.email}
                          </p>
                        </td>
                      </tr>
                    )}

                    {signedUser?.cell && (
                      <tr>
                        <td align='left'>
                          <p className='fw-700 fs-14 color-lightgray mb-1'>
                            MOBILE
                          </p>
                        </td>
                        <td align='right'>
                          <p className='fw-500 fs-14 mb-1'>
                            {signedUser?.cell}
                          </p>
                        </td>
                      </tr>
                    )}

                    {signedUser?.ip_address && (
                      <tr>
                        <td align='left'>
                          <p className='fw-700 fs-14 color-lightgray mb-1'>
                            DEVICE IP
                          </p>
                        </td>
                        <td align='right'>
                          <p className='fw-500 fs-14 mb-1'>
                            {signedUser?.ip_address}
                          </p>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <td align='left'>
                        <p className='fw-700 fs-14 color-lightgray'>
                          IDENTITY STATUS
                        </p>
                      </td>
                      <td align='right' className='v-align-top'>
                        <span className={styles.verifiedLabel}>
                          {signedUser?.verified
                            ? 'Verified ID'
                            : 'Pending ID Verification'}
                        </span>
                      </td>
                    </tr>

                    {/* <tr>
                      <td align='left'>
                        <p className='fw-700 fs-14 color-lightgray'>
                          CUSTOM FIELD
                        </p>
                      </td>
                      <td align='right'>
                        <p className='fw-400 fs-14'>Custom value</p>
                      </td>
                    </tr> */}
                  </table>
                  <img
                    onClick={(e) => setShowUserInfo(false)}
                    src={ChevronDown}
                    className={styles.closeArrow}
                    alt='close'
                  />
                </Col>
              </Row>
            </div>
          ) : null}

          {showShare ? (
            <>
              <div className={styles.shareFooter}>
                <Row className='m0'>
                  <Col
                    lg={{ span: 4, offset: 4 }}
                    md={{ span: 6, offset: 3 }}
                    className={styles.shareButtons}
                  >
                    {/* <div className='shareIcon'>
                      <Whatsapp
                        style={whatsappShare}
                        solid
                        small
                        message={`${username} wants you to sign this`}
                        link={whatsappLink}
                      />
                    </div> */}
                    <Button
                      variant='outline-dark'
                      className='btn-block btn-rounded fw-700 fs-16 mt-3'
                      onClick={(e) => setShowShare(false)}
                    >
                      Close
                    </Button>
                  </Col>
                </Row>
              </div>
            </>
          ) : null}
        </Col>
      </Row>
    </motion.div>
  );
}
