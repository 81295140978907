import styles from './styles.module.css';
import { motion } from 'framer-motion';
import { Row, Col } from 'react-bootstrap';
import SigningHeader from '../../../../components/Pages/Signing/Header/Header';
import Camera from '../../../../components/CameraCapture/Camera';
import appContext from '../../../../context/app-context';
import { useContext } from 'react';
import GoBackHeader from '../../../../components/GoBackHeader/GoBackHeader';

export default function SeifieVerification() {
  const { setProfilePage } = useContext(appContext);

  function previousPage() {
    setProfilePage(4);
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <GoBackHeader
          previousPage={previousPage}
          pageName={'Selfie Verification '}
        />
        <Col md={{ span: 6, offset: 3 }}>
          <div className={styles.main}>
            <div className='text-center'>
              <Camera onClick={(e) => setProfilePage(0)} isProfile={true} />
            </div>
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}
