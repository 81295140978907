import { Card } from 'react-bootstrap';
import { useEffect, useState } from 'react';

import ProgressLine from '../ProgressLine/ProgressLine';
import Cross from '../../assets/images/cross.svg';
import Check from '../../assets/images/check.svg';
import styles from './styles.module.css';
import { uploadFile } from '../../common/uploadFile/uploadFile';

export default function TransactionFile({ file, deleteDocument, addDocument }) {
  const [uploadComplete, setUploadComplete] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showProgressBar, setShowProgressBar] = useState(true);

  useEffect(() => {
    async function upload() {
      try {
        const uploadedDocumentName = await uploadFile(file, setProgress);
        addDocument({ uploadedDocumentName, name: file.name });
      } catch (error) {
        console.log(error);
      }
    }
    upload();
  }, []);

  useEffect(() => {
    if (progress === 1) {
      setShowProgressBar(false);
      setUploadComplete(true);
    }
  }, [progress]);

  return (
    <Card className='mb-2 cardWhite h-70 text-left'>
      <Card.Body className={styles.cardBody}>
        <span className='badge badge-info fw-600'>PDF</span>
        <Card.Text className={styles.cardBodyText}>
          <p className='mb-0 fw-700 fs-14'>{file?.name}</p>
          {showProgressBar && (
            <>
              <ProgressLine
                visualParts={[
                  {
                    percentage: `${progress * 100}%`,
                    color: 'black'
                  }
                ]}
              />
              <div className={styles.progressSpacer} />
            </>
          )}

          {uploadComplete && (
            <span className='color-lightgray fs-12 fw-700'>
              <img src={Check} alt='check' className={styles.checkIcon} />
              Uploaded
            </span>
          )}
        </Card.Text>
        <div className={styles.deleteDoc}>
          <img
            src={Cross}
            alt='delete'
            className={styles.crossIcon}
            onClick={(e) => deleteDocument(file)}
          />
        </div>
      </Card.Body>
    </Card>
  );
}
