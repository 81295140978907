import { useContext, useEffect, useState } from 'react';
import appContext from '../../context/app-context';
import { useNavigate } from 'react-router-dom';
import TransactionDetails from '../../components/Pages/Transactions/TransactionDetails/TransactionDetails';
import ViewTransaction from '../../components/Pages/Transactions/ViewTransaction/ViewTransaction';
import ViewTransactionDocument from '../../components/Pages/Transactions/ViewTransactionDocument/ViewTransactionDocument';
import axios from 'axios';
import * as Sentry from '@sentry/react';

export default function Transactions() {
  const { transactionPage, getCard, getUser, user, getToken, setContinuePage } = useContext(appContext);
  const [page, setPage] = useState(transactionPage);
  const [transactionList, setTransactions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionStorage.getItem('token')) {
      setContinuePage(0);
      navigate('/continue');
    }
    getUser();
    getCard();
    selectPage(transactionPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionPage]);

  useEffect(() => {
    // getAllTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getAllTransactions = async () => {
    try {
      const { data } = await axios.get(`transactions/sent/all`);

      console.log(data);
      if (data.success) {
        setTransactions(data.transactions);
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  const transactions = () => {
    switch (page) {
      case 0:
        return <TransactionDetails />;
      case 1:
        return <ViewTransaction />;
      case 2:
        return <ViewTransactionDocument setOpenViewDocument={() => {}} />;

      default:
        return <TransactionDetails />;
    }
  };

  const selectPage = (currentPage) => {
    setPage(currentPage);
    window.scrollTo(0, 0);
  };

  return (
    <div id='outer-container'>
      <div id='page-wrap'>{transactions()}</div>
    </div>
  );
}
