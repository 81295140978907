import { useContext, useEffect, useState } from 'react';
import Overview from '../../components/Pages/Sending/Overview/Overview';
import CreateTransaction from '../../components/Pages/Sending/CreateTransaction/CreateTransaction';
import Signatories from '../../components/Pages/Sending/Signatories/Signatories';
import AddSignee from '../../components/Pages/Sending/AddSignee/AddSignee';
import Confirmation from '../../components/Pages/Sending/Confirmation/Confirmation';
import Success from '../../components/Pages/Sending/Success/Success';
import Billing from '../../components/Pages/Sending/Billing/Billing';
import SendAcknowledgements from '../../components/Acknowledgements/send/SendAcknowledgements';
import appContext from '../../context/app-context';
import { useNavigate } from 'react-router-dom';
import AdditionalInfo from '../../components/Pages/Sending/AdditionalInfo/AdditionalInfo';

export const Send = () => {
  const { sendingPage, token, userId, setContinuePage } = useContext(appContext);
  const [page, setPage] = useState(sendingPage);
  const navigate = useNavigate();

  useEffect(() => {
    selectPage(sendingPage);
    if (!sessionStorage.getItem('token')) {
      setContinuePage(0);
      navigate('/continue');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendingPage, userId, token]);

  const sending = () => {
    switch (page) {
      case 0:
        return <Overview />;
      case 1:
        return <CreateTransaction />;
      case 2:
        return <Signatories />;
      case 3:
        return <SendAcknowledgements />;
      case 4:
        return <Confirmation />;
      case 5:
        return <Success />;
      case 6:
        return <AddSignee />;
      case 7:
        return <Billing />;
      case 8:
        return <AdditionalInfo />;
      default:
        return <Overview />;
    }
  };

  const selectPage = (currentPage) => {
    setPage(currentPage);
    window.scrollTo(0, 0);
  };

  return (
    <div id='outer-container'>
      <div id='page-wrap'>{sending()}</div>
    </div>
  );
};
