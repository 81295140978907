import * as Sentry from '@sentry/react';
import appContext from '../../context/app-context';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import axios from 'axios';
import SpinnerAnimation from '../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';

const ViewAcceptAcknowledgements = (props) => {
  const navigate = useNavigate();
  const { currentDocument, documents, transaction, signatory, getToken, setContinuePage } =
    useContext(appContext);

  const [showLoader, setShowLoader] = useState(false);
  const [acknoledgementsData, setAcknoledgementsData] = useState([]);
  const [acknowledgementsToAccept, setAcknowledgementsToAccept] = useState([]);
  const [disregardedDocID, setDisregardedDocID] = useState([]);

  useEffect(() => {
    getSignatoryAcknowledgements(0);
  }, [props.signSingleFile]);

  function closePopup() {
    props.showAcknowledgement = false;
  }

  /********************
   * ACKNOWLEDGEMENTS
   ********************/

  // HTTP update accept or decline acknowledgement
  async function updateAcknowledgementSignatory(
    user_id,
    acknowledgement_id,
    status
  ) {
    setShowLoader(true);
    try {
      await axios.put(
        `/acknowledgement/update/signatory/${user_id}/${acknowledgement_id}`,
        {
          has_accepted: status
        }
      );

      getSignatoryAcknowledgements(0);

      if (props.generatePDFafterAccept) {
        if (documents.length > 1) {
          generateDocument(false);
        } else {
          generateDocument(true);
        }
      }
    } catch (e) {
      Sentry.captureException(e);
      setShowLoader(false);
      if (e.response.data?.message === 'Unauthenticated.') {
        setContinuePage(0);
        navigate('/continue');
      }
    }
    setShowLoader(false);
  }

  // HTTP Get Signatory acknowledgements to accept
  async function getSignatoryAcknowledgements(status) {
    let documentIds = [];

    if (!props.signSingleFile) {
      documents.forEach((item, index) => {
        documentIds.push(item.id);
      });
    }

    try {
      const { data } = await axios.post(
        `/acknowledgement/user/${status}/${props.signatory?.id}/${props.currentDocument.uuid}`,
        {
          documentIds: documentIds
        }
      );

      setAcknoledgementsData(data);
      setAcknowledgementsToAccept(data?.acknowledgements);
      props.setUserAcknowledgemets(data?.acknowledgements);

      // remove disegarded acknowledgements from list
      if (disregardedDocID.length > 0) {
        let newAcknowledgementsArr = [];
        disregardedDocID.forEach((element) => {
          newAcknowledgementsArr = data?.acknowledgements.filter((object) => {
            return object.document_id !== element;
          });
        });
        // Set the new list of acknowledgemts without the disregarded ones
        setAcknowledgementsToAccept(newAcknowledgementsArr);
        props.setUserAcknowledgemets(newAcknowledgementsArr);
      }

      setShowLoader(false);
    } catch (e) {
      Sentry.captureException(e);
      setShowLoader(false);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  }

  // Generate document
  async function generateDocument(isSingle) {
    let documentId = '';
    setShowLoader(true);

    if (isSingle) {
      documentId = `/${currentDocument.uuid}`;
    }

    try {
      const { data } = await axios.post(
        `transactions/${transaction.id}/complete-signatory${documentId}`,
        {
          email: signatory.email
        }
      );

    } catch (e) {
      setTimeout(() => {
        setShowLoader(false);
        // setError('Something went wrong please try again later!');
      }, 2000);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
      Sentry.captureException(e);
    }
  }

  // Disregard acknowledgement
  function disregard(DocumentUuid) {
    setDisregardedDocID((current) => [...current, DocumentUuid]);

    let newAcknowledgementsArr = [];

    if (disregardedDocID.length > 0) {
      disregardedDocID.forEach((element) => {
        newAcknowledgementsArr = acknowledgementsToAccept.filter((object) => {
          return (
            object.document_id !== element &&
            object.document_id !== DocumentUuid
          );
        });
      });
    } else {
      newAcknowledgementsArr = acknowledgementsToAccept.filter((object) => {
        return object.document_id !== DocumentUuid;
      });
    }

    console.log('NEW LIST IS...');
    console.log(newAcknowledgementsArr);

    setAcknowledgementsToAccept(newAcknowledgementsArr);
    props.setUserAcknowledgemets(newAcknowledgementsArr);
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
    >
      {props.showAcknowledgement && acknowledgementsToAccept.length > 0 && (
        <div>
          {/* {!showLoader &&( */}
          <div className={`overlay`}></div>
          {/* )} */}

          <div className='accept-acknowledgement-container'>
            {acknowledgementsToAccept.map((item, index) => (
              <Row
                key={index}
                className={`${
                  props.showAcknowledgement ? 'shown-div' : 'hide-div'
                } accept-acknowledgement-popup-content absolute-popup-content hide-scrollbar ${
                  styles.acknowledgementPopup
                }`}
              >
                <Col
                  lg={{ span: 6, offset: 3 }}
                  md={{ span: 8, offset: 2 }}
                  xs={{ span: 12, offset: 0 }}
                  className='p-0 w-full ml-0'
                >
                  <div className={styles.acknowledgementScroller}>
                    <div className={styles.acknowledgementPlaceholder}>
                      <div className='text-center'>
                        <p className='fw-800 fs-18 m0 pt-4 px-5 pb-4'>
                          Acknowledge & accept
                        </p>
                      </div>
                      <div className='acknowledgement-text'>
                        <p className='fs-16 m0 px-3 py-4  text-left'>
                          {item.support_text}
                        </p>
                        <p
                          className='color-lightgray fw-600 fs-16 m0 px-3 text-left'
                          style={{ marginBottom: '10px' }}
                        >
                          related file: <br />
                          {item.title}
                        </p>
                      </div>
                    </div>
                    {showLoader ? (
                      <div className='p-b-50'>
                        <SpinnerAnimation />
                      </div>
                    ) : (
                      <div>
                        <Row className='m0 pt-4'>
                          <Col md={{ span: 6 }} xs={{ span: 12, offset: 0 }}>
                            <Button
                              variant='dark'
                              className='btn-rounded btn-block mb-3'
                              onClick={() =>
                                updateAcknowledgementSignatory(
                                  acknoledgementsData.user_details.id,
                                  item.id,
                                  1
                                )
                              }
                            >
                              <span className='fs-16 fw-700'>
                                Acknowledge & Accept
                              </span>
                            </Button>
                          </Col>
                          <Col md={{ span: 6 }} xs={{ span: 12, offset: 0 }}>
                            <Button
                              variant='outline-dark'
                              className='btn-rounded btn-block mb-3'
                              onClick={() => (
                                disregard(item.document_id),
                                props.disregard(item.document_id)
                              )}
                            >
                              <span className='fs-16 fw-700'>Disregard</span>
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      )}
    </motion.div>
  );
};

export default ViewAcceptAcknowledgements;
