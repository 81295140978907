import ChevronLeftIcon from '../../assets/images/chevron-left.svg';
import home from '../../assets/images/home.svg';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

export default function ShowFileIDHeader(props) {
  const [fileID, showFileID] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const navigate = useNavigate();

  function toggle() {
    showFileID((showFile) => !showFile);
  }

  function copyToClipboard() {
    navigator.clipboard.writeText(`${props.transactionId}`);
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 3000);
  }

  return (
    <div className={styles.header}>
      <Row className='m0 w-100'>
        <Col className='p0' md={{ span: 6, offset: 3 }}>
          <div className='d-flex align-items-center justify-content-center pe-3 ps-3'>
            <div
              className={styles.chevronLeftIcon}
              onClick={props.previousPage}
            >
              <img src={ChevronLeftIcon} alt='go back' />
            </div>
            <p className='fs-16 fw-700 m0'>{props.pageName}</p>
            <img
              src={home}
              alt='home'
              className={styles.homeBtn}
              onClick={() => navigate('/')}
            />
          </div>
          {fileID && (
            <>
              <div className='text-center mt-3'>
                <p className='fw-400 fs-16'>{props.transactionId}</p>

                <Button
                  variant='light'
                  className='btn-white btn-shadow btn-rounded btn-sm fw-700 fs-12 mb-2'
                  onClick={copyToClipboard}
                >
                  {showTooltip ? <>Copied!</> : <>Copy to clipboard</>}
                </Button>
              </div>
            </>
          )}
          {!props.unsignedDocs ? (
            <div className='text-center mt-3'>
              <span
                className='fw-700 fs-14 color-lightgray pointer'
                onClick={toggle}
              >
                {fileID ? <u>Hide file ID</u> : <u>Show file ID</u>}
              </span>
            </div>
          ) : (
            ''
          )}
        </Col>
      </Row>
    </div>
  );
}
