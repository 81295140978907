import { useContext } from 'react';
import styles from './styles.module.css';
import appContext from '../../../../context/app-context';
import { Card } from 'react-bootstrap';
import { ChevronRightIcon } from '../../../../components/Icons/Icons';

export default function Invoices({ invoices }) {
  const { setBillingPage, setInvoice } = useContext(appContext);

  function updateInvoice(invoice) {
    setInvoice(invoice);
    setTimeout(() => {
      setBillingPage(2);
    }, 500);
  }

  return (
    <>
      {invoices.map((invoice, index) => {
        return (
          <Card
            key={index}
            className='mb-2 cardWhite pointer'
            onClick={(e) => updateInvoice(invoice)}
          >
            <Card.Body className={styles.cardBody}>
              <Card.Text className={styles.cardBodyText} as='h6'>
                <div>
                  <p className='m0 fw-700 fs-14'>{invoice?.name}</p>
                </div>
                <span className='color-lightgray fs-12 fw-600'>
                  {invoice?.date}
                </span>
              </Card.Text>
              <div className={styles.viewFileBtn}>
                <ChevronRightIcon />
              </div>
            </Card.Body>
          </Card>
        );
      })}
    </>
  );
}
