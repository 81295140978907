import React from 'react';
import styles from './styles.module.css';
import Notifications from '../../components/Notifications/Notifications';
import SidebarMenu from '../../components/SidebarMenu/SidebarMenu';

export default function Menu() {
  return (
    <div className={styles.header}>
      <SidebarMenu
        pageWrapId={'page-wrap'}
        outerContainerId={'outer-container'}
      />
      {/* <Notifications
        pageWrapId={'page-wrap'}
        outerContainerId={'outer-container'}
      /> */}
    </div>
  );
}
