import * as Sentry from '@sentry/react';
import { motion } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useFileUpload } from 'use-file-upload';
import styles from './styles.module.css';
import axios from 'axios';
import useSWR from 'swr';

import uploadIcon from '../../../../assets/images/upload.svg';
import GoBackHeader from '../../../../components/GoBackHeader/GoBackHeader';
import SpinnerAnimation from '../../../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import appContext from '../../../../context/app-context';
import { fetcher } from '../../../../common/fetcher';

export default function AddBusiness() {
  const { setProfilePage, user, countries, setContinuePage, getToken } =
    useContext(appContext);
  const navigate = useNavigate();
  const [business, setBusiness] = useState(null);
  const [name, setName] = useState(business?.name);
  const [registration, setRegistration] = useState(business?.registration);
  const [email, setEmail] = useState(business?.email);
  const [streetName, setStreetName] = useState(business?.street_name);
  const [country, setCountry] = useState(business?.country);
  const [cell, setCell] = useState(business?.cell);
  const [website, setWebsite] = useState(business?.website);
  const [building, setBuilding] = useState(business?.building);
  const [suburb, setSuburb] = useState(business?.suburb);
  const [city, setCity] = useState(business?.city);
  const [industryId, setIndustryId] = useState(business?.industry_id);
  const [businessId, setBusinessId] = useState('');
  const [companyLogo, selectCompanyLogo] = useFileUpload();
  const [logo, setLogo] = useState(null);
  const [showLogoLoader, setShowLogoLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [errors, setErrors] = useState(null);

  const { data } = useSWR('industries', fetcher);
  const industries = data?.industries;

  const previousPage = () => {
    if (window.location.href.includes('continue')) {
      setContinuePage(2);
    } else {
      setProfilePage(0);
    }
  };

  useEffect(() => {
    getBusiness();
    getCompanyLogo();
  }, [user]);

  useEffect(() => {
    saveCompanyLogo();
  }, [companyLogo]);

  useEffect(() => {
    setTimeout(() => {
      setLoader(true);
    }, 1500);
  });

  const getBusiness = async () => {
    try {
      const { data } = await axios.get(`users/${user?.id}/business`);
      setBusiness(data.business);
      setName(data.business?.name);
      if (!data.business?.email) {
        setEmail(user?.email);
      } else {
        setEmail(data.business.email);
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  const getCompanyLogo = async () => {
    try {
      const { data } = await axios.get(
        `users/${user.id}/documents?type=company_logo`
      );
      setLogo(data.company_logo);
      setLoader(true);
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      setLoader(true);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  function emailValidation(email) {
    setErrors(null);
    setEmail(email);
    let oldEmail = business?.email ? business?.email : user?.email;
    if (email === '') {
      setEmailError('Please enter your business email address');
    } else if (!isValiEmail(email)) {
      setEmailError('Please enter a valid email address');
    } else if (email !== oldEmail) {
      setEmailError(
        'Please note: Changing this email address will also change the account email that is used for login'
      );
    } else {
      setEmailError(false);
    }
  }

  const createBusiness = async () => {
    setErrors(null);
    if (!isValiEmail(email)) {
      return;
    }
    try {
      let { data } = await axios.post(`/businesses`, {
        name: name ? name : business?.name,
        has_business: 1,
        street_name: streetName ? streetName : business?.street_name,
        cell: cell ? cell : business?.cell,
        registration: registration ? registration : business?.registration,
        email: email ? email : business?.email,
        country: country ? country : business?.country,
        website: website ? website : business?.website,
        building: building ? building : business?.building,
        suburb: suburb ? suburb : business?.suburb,
        city: city ? city : business?.city,
        industry_id: industryId
          ? parseInt(industryId, 10)
          : business?.industry_id
      });

      if (data.success) {
        setBusiness(data.business);
        sessionStorage.setItem('businessId', data.business.id);
        // previousPage();
        navigate('/');
      }
    } catch (e) {
      if (e.response.status === 422) {
        let errors = e.response.data.errors;
        setErrors(errors);
      }
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };
  const switchPersonal = () => {
    setContinuePage(5);
  };
  const uploadRef = useRef();
  const handleUpload = () => {
    uploadRef.current.click();
  };

  function getBase64(file, cb) {
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(reader.result);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  }

  const saveCompanyLogo = async (file) => {
    getBase64(file, (result) => {
      setLogo(result);
    });

    if (file) {
      let formData = new FormData();
      formData.append('type', 'company_logo');
      formData.append('document', file);

      const config = {
        headers: {
          'content-type': `multipart/form-data;`
        }
      };

      try {
        setShowLogoLoader(true);
        await axios.post(`users/${user.id}/documents`, formData, {
          ...config
        });
        setShowLogoLoader(false);
      } catch (e) {
        console.log(e);
        setShowLogoLoader(false);
        Sentry.captureException(e);
        if (e.response?.data?.message === 'Unauthenticated.') {
          sessionStorage.removeItem('token');
          await getToken();
          setContinuePage(0);
          navigate('/continue');
        }
      }
    }
  };

  function isValiEmail(val) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return false;
    }
    return true;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white'
        >
          <GoBackHeader
            previousPage={previousPage}
            pageName={'Business profile'}
          />
          {!loader ? (
            <>
              <Row>
                <Col lg={{ span: 4, offset: 4 }} md={{ span: 6, offset: 3 }}>
                  <div className={styles.fullscreenSpinner}>
                    <div className='center-content'>
                      <SpinnerAnimation />
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <div className='p-t-100'>
                <Form className='text-left'>
                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>Add Company Name</label>
                    </div>
                    <Form.Control
                      type='text'
                      defaultValue={business?.name}
                      onChange={(e) => setName(e.currentTarget.value)}
                      className={styles.input}
                    />
                    {(errors && errors.name) || !name ? (
                      <small className={styles.emailError}>
                        Please enter your business name
                      </small>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <div className='text-center'>
                    <p className={`ps-4 pe-4 ${styles.logoLabel}`}>
                      UPLOAD YOUR COMPANY LOGO
                    </p>
                    <>
                      {companyLogo ? (
                        <>
                          <img
                            src={companyLogo.source}
                            alt='id'
                            className={styles.idPhoto}
                          />
                        </>
                      ) : logo ? (
                        <>
                          <img src={logo} alt='id' className={styles.idPhoto} />
                        </>
                      ) : (
                        <>
                          <div className={styles.IDPlaceholder}></div>
                        </>
                      )}
                      <div>
                        {showLogoLoader ? (
                          <div className='mb-3'>
                            <SpinnerAnimation />
                          </div>
                        ) : (
                          <Button
                            variant='dark'
                            className='btn-rounded btn-50 p-relative top-less-20 p0'
                            onClick={handleUpload}
                          >
                            <img
                              src={uploadIcon}
                              alt='edit'
                              className={styles.edit}
                            />

                            <input
                              type='file'
                              ref={uploadRef}
                              style={{ display: 'none' }}
                              accept='image/*'
                              onChange={(e) =>
                                saveCompanyLogo(e.target.files[0])
                              }
                            />
                          </Button>
                        )}
                      </div>
                    </>
                  </div>
                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>Contact Number</label>
                    </div>
                    <Form.Control
                      type='number'
                      inputMode='numeric'
                      defaultValue={business?.cell}
                      onChange={(e) => setCell(e.currentTarget.value)}
                      className={styles.input}
                    />
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>Website</label>
                    </div>
                    <Form.Control
                      type='text'
                      defaultValue={business?.website}
                      onChange={(e) => setWebsite(e.currentTarget.value)}
                      className={styles.input}
                    />
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>Email</label>
                    </div>
                    <Form.Control
                      type='email'
                      defaultValue={email}
                      onChange={(e) => emailValidation(e.currentTarget.value)}
                      className={styles.input}
                    />

                    {errors && errors.email ? (
                      <small className={styles.emailError}>
                        Email already in use. Please use a different email
                      </small>
                    ) : (
                      ''
                    )}

                    {emailError && !errors ? (
                      <>
                        <div className={styles.emailError}>{emailError}</div>
                      </>
                    ) : null}
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>Street Name</label>
                    </div>
                    <Form.Control
                      type='text'
                      defaultValue={business?.street_name}
                      onChange={(e) => setStreetName(e.currentTarget.value)}
                      className={styles.input}
                    />
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>
                        Building Name / Number
                      </label>
                    </div>
                    <Form.Control
                      type='text'
                      defaultValue={business?.building}
                      onChange={(e) => setBuilding(e.currentTarget.value)}
                      className={styles.input}
                    />
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>Suburb</label>
                    </div>
                    <Form.Control
                      type='text'
                      defaultValue={business?.suburb}
                      onChange={(e) => setSuburb(e.currentTarget.value)}
                      className={styles.input}
                    />
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>City</label>
                    </div>
                    <Form.Control
                      type='text'
                      defaultValue={business?.city}
                      onChange={(e) => setCity(e.currentTarget.value)}
                      className={styles.input}
                    />
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>Country</label>
                    </div>
                    <Form.Select
                      defaultValue={
                        business?.country ? business?.country : 'South Africa'
                      }
                      onChange={(e) => setCountry(e.currentTarget.value)}
                    >
                      <option disabled value='South Africa'>
                        South Africa
                      </option>
                      {countries.map((country, key) => {
                        return (
                          <option key={key} value={country}>
                            {country}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>Industry</label>
                    </div>
                    {industries ? (
                      <Form.Select
                        defaultValue={business?.industry_id}
                        onChange={(e) => setIndustryId(e.currentTarget.value)}
                      >
                        <option disabled value={'select'} hidden>
                          Select
                        </option>
                        {industries?.map((industry) => {
                          return (
                            <option key={industry.id} value={industry.id}>
                              {industry.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    ) : (
                      <span>Loading industries</span>
                    )}
                    <div>
                      <div className='text-center m-t-40 m-b-20 p-relative'>
                        <p className='color-gray fs-12 fw-500'>PROFILE TYPE</p>
                        <label className='react-switch disabled'>
                          <input
                            className='react-switch-checkbox'
                            type='checkbox'
                          />
                          <div className='react-switch-button' />
                          <div className='react-switch-labels'>
                            <span>Business</span>
                            <span className='disabled' onClick={switchPersonal}>
                              Personal
                            </span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </Form.Group>
                  <div className={styles.footer}>
                    <Button
                      variant='dark'
                      onClick={(e) => createBusiness()}
                      className='btn-block btn-rounded fw-700'
                      disabled={!name}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </div>
            </>
          )}
        </Col>
      </Row>
    </motion.div>
  );
}
