import LogoLarge from '../../assets/images/logo-large.svg';
import LogoBlack from '../../assets/images/logo-black.svg';
import ChevronRight from '../../assets/images/chevron-right.svg';
import ChevronLeft from '../../assets/images/chevron-left.svg';
import WhiteChevronRight from '../../assets/images/white-chevron-right.svg';
import Identification from '../../assets/images/identification.svg';
import User from '../../assets/images/user-icon.svg';
import CameraWhite from '../../assets/images/camera-white.svg';
import Refresh from '../../assets/images/refresh.svg';
import Whatsapp from '../../assets/images/whatsapp.svg';
import Google from '../../assets/images/google.svg';
import Apple from '../../assets/images/apple.svg';
import Microsoft from '../../assets/images/microsoft.svg';
import { useNavigate } from 'react-router-dom';
import Bell from '../../assets/images/bell.svg';

export const LogoLargeIcon = () => {
  return (
    <>
      <img src={LogoLarge} alt='Logo' className='icon' />
    </>
  );
};

export const LogoBlackIcon = () => {
  return (
    <>
      <img src={LogoBlack} alt='Logo' className='icon' />
    </>
  );
};

export const WhiteChevronRightIcon = () => {
  return (
    <>
      <img src={WhiteChevronRight} alt='Chevron Right' className='icon' />
    </>
  );
};

export const ChevronRightIcon = () => {
  return (
    <>
      <img src={ChevronRight} alt='Chevron Right' className='icon' />
    </>
  );
};

export const ChevronLeftIcon = () => {
  const navigate = useNavigate();
  return (
    <>
      <img
        src={ChevronLeft}
        alt='Chevron Left'
        onClick={(e) => navigate(-1)}
        className='icon'
      />
    </>
  );
};

export const IdentitificationIcon = () => {
  return (
    <>
      <img src={Identification} alt='Chevron Left' className='icon' />
    </>
  );
};

export const UserIcon = () => {
  return (
    <>
      <img src={User} alt='User Icon' className='icon' />
    </>
  );
};

export const CameraWhiteIcon = () => {
  return (
    <>
      <img src={CameraWhite} alt='Camera Icon' className='icon' />
    </>
  );
};

export const RefreshIcon = () => {
  return (
    <>
      <img src={Refresh} alt='Refresh Icon' className='icon' />
    </>
  );
};

export const WhatsappIcon = () => {
  return (
    <>
      <img src={Whatsapp} alt='whatsapp' className='icon' />
    </>
  );
};

export const GoogleIcon = () => {
  return (
    <>
      <img src={Google} alt='google' className='icon' />
    </>
  );
};

export const AppleIcon = () => {
  return (
    <>
      <img src={Apple} alt='apple' className='icon' />
    </>
  );
};

export const MicrosoftIcon = () => {
  return (
    <>
      <img src={Microsoft} alt='microsoft' className='icon' />
    </>
  );
};

export const BellIcon = () => {
  return (
    <>
      <img src={Bell} alt='bell' className='icon' />
    </>
  );
};
