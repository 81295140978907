import * as Sentry from '@sentry/react';
import axios from 'axios';
import ViewAcceptAcknowledgements from '../../../../components/Acknowledgements/ViewAcceptAcknowledgements';
import SigningHeader from '../../../../components/Pages/Signing/Header/Header';
import SpinnerAnimation from '../../../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import AdditionalFields from '../../../../components/shared/SidebarMenu/AdditionalFields';
import appContext from '../../../../context/app-context';
import { motion } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

export default function MultipleFiles() {
  const {
    setCurrentPage,
    signatory,
    setSingleSigning,
    currentDocument,
    documents,
    email,
    transaction,
    selfie,
    signatoryDocuments,
    getToken,
    setContinuePage,
  } = useContext(appContext);
  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const [url, setUrl] = useState(null);
  const [signFiles, setSignFiles] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState('');
  const [saveTime, setSaveTime] = useState(true);
  const [singleFile, setSingleFile] = useState(false);

  // ACKNOWLEDGEMENTS
  const [userAcknowledgemets, setUserAcknowledgemets] = useState([]);
  const [showAcknowledgement, setShowAcknowledgement] = useState(false);
  const [showSavetime, setShowSavetime] = useState(false);
  const [signSingleFile, setSignSingleFile] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState(documents);

  // additional fields validation state
  const [validatePassed, setValidatePassed] = useState(false);
  const fieldRef = useRef();
  const [selfieUrl, setSelfieUrl] = useState({});

  useEffect(() => {
    console.log('SINGLE FILE', singleFile)
    if (signFiles && userAcknowledgemets?.length == 0) {
      signDocument(singleFile);
    }
  }, [userAcknowledgemets, selectedDocs]);

  function previousPage() {
    //Document view
    setCurrentPage(2);
  }

  function signAll() {
    fieldRef.current.save();
    setSingleFile(false);
    setSignSingleFile(false);
    setSaveTime(true);
    setSingleFile(false);
    //todo check each user against each document
    let documentIndex = signatoryDocuments.findIndex(
      (document) => document.uuid === currentDocument.uuid
    );
    let document =
      documentIndex !== -1 ? signatoryDocuments[documentIndex] : null;
    if (userAcknowledgemets?.length > 0) {
      setShowAcknowledgement(true);
      setShowSavetime(false);
      return;
    }
    signDocument(false);
  }

  function signOne() {
    setSingleFile(true);
    setSignSingleFile(true);
    setSaveTime(true);
    // setSingleFile(false);
    let documentIndex = signatoryDocuments.findIndex(
      (document) => document.uuid === currentDocument.uuid
    );
    let document =
      documentIndex !== -1 ? signatoryDocuments[documentIndex] : null;
    if (userAcknowledgemets?.length > 0) {
      setShowAcknowledgement(true);
      setShowSavetime(false);
      return;
    }
    signDocument(true);
  }

  // useEffect(() => {
  //   const getSelfie = async () => {
  //     if (selfie) {
  //       setSelfieUrl((prevSelfieUrl) => ({
  //         ...prevSelfieUrl,
  //         [currentDocument.uuid]: selfie
  //       }));
  //     } else {
  //       try {
  //         const { data } = await axios.get(`selfie/${signatory.email}`);
  //         if (data.success && data.selfie) {
  //           setSelfieUrl((prevSelfieUrl) => ({
  //             ...prevSelfieUrl,
  //             [currentDocument.uuid]: data.selfie
  //           }));
  //         }
  //       } catch (e) {
  //         console.log(e);
  //         if (e.response?.data?.message === 'Unauthenticated.') {
  //           sessionStorage.removeItem('token');
  //           await getToken();
  //           setContinuePage(0);
  //           navigate('/continue');
  //         }
  //       }
  //     }
  //   };

  //   getSelfie();
  // }, [selfie, signatory.email, currentDocument.uuid]);

  async function signDocument(isSingle) {
    fieldRef.current.save();
    setSignFiles(true);

    if (userAcknowledgemets?.length > 0) {
      setShowAcknowledgement(true);
      setShowSavetime(false);
      return;
    }

    // alert("SIGNING multiplefile.js");

    setError('');
    let documentId = '';
    setShowLoader(true);
    console.log(currentDocument);
    if (isSingle) {
      documentId = `/${currentDocument.uuid}`;
    }
    try {
      const { data } = await axios.post(
        `transactions/${transaction.id}/complete-signatory${documentId}`,
        {
          email: email,
          documents: selectedDocs
        }
      );

      setShowLoader(false);
      if (data.success) {
        //Finalisation
        setCurrentPage(5);
        setSingleSigning(isSingle);
      } else {
        setTimeout(() => {
          setError('Something went wrong please try again later!');
        }, 1500);
      }
    } catch (e) {
      setTimeout(() => {
        setShowLoader(false);
        setError('Something went wrong please try again later!');
      }, 1500);
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  }

  function disregard(DocumentUuid) {
    // Remove the document with disregarded acknowledgements from the list to sign
    let documentsToSign = selectedDocs;
    const newDocumentsArr = documentsToSign.filter((object) => {
      return object.id !== DocumentUuid;
    });

    if (signSingleFile) {
      setSelectedDocs([]);
      // navigate('/');
      setCurrentPage(1);
      return;
    } else {
      setSelectedDocs(newDocumentsArr);
    }

    // Check if there are any any documents left to sign still
    let count = 0;
    newDocumentsArr.forEach((doc) => {
      if (doc.signed == 0) {
        count++;
      }
    });

    // If all are disregarded, go to home page
    if (newDocumentsArr.length == 0 || count == 0) {
      setCurrentPage(1);
      return;
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <SigningHeader previousPage={previousPage} />
        <Col lg={{ span: 4, offset: 4 }} md={{ span: 6, offset: 3 }}>
          <div className={styles.main}>
            {signFiles && !showLoader && <div className={styles.overlay}></div>}
            <div className='text-center'>
              <div className={styles.flipImage}>
                {url && selfieUrl[currentDocument.uuid] ? (
                  <img
                    src={selfieUrl[currentDocument.uuid]}
                    className={styles.photoPreview}
                    alt='Selfie'
                  />
                ) : null}
              </div>
              <p className='fw-800 fs-18'>
                Hi{' '}
                {signatory && signatory.first_name ? (
                  <>
                    {signatory?.first_name}{' '}
                    {signatory.last_name ? <>{signatory?.last_name},</> : null}
                  </>
                ) : (
                  <span className='lowercase'>{signatory?.email},</span>
                )}
              </p>

              <AdditionalFields
                validate={setValidatePassed}
                ref={fieldRef}
                documents={documents}
                transaction={transaction}
                user={signatory}
              />

              <p className='mt-5 fw-500 fs-14 pb-3'>
                By signing,
                <br />
                you agree to, & accept
                <br />
                the terms & conditions of
                <br />
                <br />
                {selectedDocs.map((document, i) => {
                  return (
                    <p key={i} className='mb-1 fs-14 fw-700'>
                      {document.title}
                    </p>
                  );
                })}
              </p>
              <div className={`${styles.footerWhite}`}>
                <Row className='m0 pt-4'>
                  <Col
                    lg={{ span: 4, offset: 4 }}
                    md={{ span: 8, offset: 2 }}
                    xs={{ span: 12, offset: 0 }}
                    className={styles.footerInner}
                  >
                    {showLoader ? (
                      <div className='p-b-50'>
                        <SpinnerAnimation />
                      </div>
                    ) : (
                      <Button
                        variant='dark'
                        className='btn-rounded btn-block'
                        onClick={() => (
                          setSignFiles(true), setShowSavetime(true)
                        )}
                        disabled={!validatePassed}
                      >
                        <span className='fs-16 fw-700'>Accept & Sign</span>
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
              {signFiles && showSavetime ? (
                <div className={`${styles.footerWhite}`}>
                  <Row className='m0 pt-4'>
                    <Col
                      lg={{ span: 4, offset: 4 }}
                      md={{ span: 8, offset: 2 }}
                      xs={{ span: 12, offset: 0 }}
                      className={styles.footerInner}
                    >
                      {saveTime && (
                        <>
                          <p className='fw-800 fs-18 m0 pt-3'>
                            save time by signing
                            <br />
                            all files in one go?
                          </p>
                          {showLoader ? (
                            <div className='p-b-50'>
                              <SpinnerAnimation />
                            </div>
                          ) : (
                            <div className={styles.photoBtns}>
                              {error ? (
                                <p className={styles.error}>{error}</p>
                              ) : (
                                ''
                              )}
                              <Row className='m0 pt-4'>
                                <Col
                                  md={{ span: 6 }}
                                  xs={{ span: 12, offset: 0 }}
                                  className='mobile-order-1'
                                >
                                  <Button
                                    variant='outline-dark'
                                    className='btn-rounded btn-block xs-mb-0'
                                    onClick={signOne}
                                  >
                                    <span className='fs-16 fw-700'>
                                      Sign individually
                                    </span>
                                  </Button>
                                </Col>
                                <Col
                                  md={{ span: 6 }}
                                  xs={{ span: 12, offset: 0 }}
                                >
                                  <Button
                                    variant='dark'
                                    className='btn-rounded btn-block xs-mb-3'
                                    onClick={signAll}
                                  >
                                    <span className='fs-16 fw-700 '>
                                      Accept & Sign all files
                                    </span>
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                </div>
              ) : null}
            </div>
          </div>
        </Col>
      </Row>

      {/*  ACCEPT ACKNOWLEDDEMENT */}
      <ViewAcceptAcknowledgements
        currentDocument={currentDocument}
        document={document}
        transaction={transaction}
        signatory={signatory}
        setUserAcknowledgemets={setUserAcknowledgemets}
        showAcknowledgement={showAcknowledgement}
        generatePDFafterAccept={false}
        signSingleFile={signSingleFile}
        disregard={disregard}
      />
    </motion.div>
  );
}
