import { ChevronRightIcon } from '../../../../components/Icons/Icons';
import { Card } from 'react-bootstrap';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';

export default function TransactionsPending({ transactions }) {
  const navigate = useNavigate();

  const sign = (transaction) => {
    navigate(transaction?.link);
  };

  console.log('transactions', transactions);

  return (
    <div className='pe-3 ps-3 pb-5'>
      <div className='text-center'>
        <div className={styles.transactionsPending}>
          <p className={styles.transactionsPendingAmount}>
            {transactions.length}
          </p>
        </div>
        <p className='fw-800 fs-18 mt-3'>
          Transactions pending
          <br />
          your signature
        </p>
      </div>

      {transactions.map((transaction, index) => {
        return (
          <Card
            key={index}
            className='mb-2 cardWhite pointer'
            onClick={(e) => sign(transaction)}
          >
            <Card.Body className={styles.cardBody}>
              <Card.Text className={styles.cardBodyText} as='h6'>
                <div>
                  <p className='m0 fw-700 fs-14'>{transaction?.name}</p>
                </div>
                <span className='color-lightgray fs-12 fw-700'>
                  {transaction?.docs.length - transaction?.unsigned_docs}/
                  {transaction?.docs.length} files signed
                </span>
              </Card.Text>
              <div className={styles.viewFileBtn}>
                <ChevronRightIcon />
              </div>
            </Card.Body>
          </Card>
        );
      })}
      {/* 
     { transactions.length > 3 ? 
      <div className='text-center mt-5'>
        <Button variant='light' className='btn-white btn-shadow btn-rounded'>
          <p className='m0 fs-14 fw-700 pe-2 ps-2'>View 2 more</p>
        </Button>
      </div>
      : ''
     */}
    </div>
  );
}
