import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import axios from 'axios';

// Styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

// Components
import { App } from './components';
import AppState from './context/AppState';

// Fonts
import './fonts/Manrope-Bold.woff2';
import './fonts/Manrope-ExtraBold.woff2';
import './fonts/Manrope-ExtraLight.woff2';
import './fonts/Manrope-Light.woff2';
import './fonts/Manrope-Medium.woff2';
import './fonts/Manrope-Regular.woff2';
import './fonts/Manrope-SemiBold.woff2';
import { SWRConfig } from 'swr';
import { fetcher } from './common/fetcher';

Sentry.init({
  dsn: 'https://f13122d50b1c4669bdba28b95b907e7e@o1430010.ingest.sentry.io/6781087',
  integrations: [new BrowserTracing()],
  maxBreadcrumbs: 50,
  debug: true,
  environment: process.env.REACT_APP_ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.25
});

const root = ReactDOM.createRoot(document.getElementById('root'));

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
console.log('axios.defaults.baseURL', process.env);
axios.defaults.withCredentials = true;

const options = {
  fetcher
};

root.render(
  <Router>
    <AppState>
      <SWRConfig value={options}>
        <App />
      </SWRConfig>
    </AppState>
  </Router>
);
