import { useEffect } from 'react';
import axios from 'axios';
import * as Sentry from '@sentry/react';

export const ShortUrl = () => {
  useEffect(() => {
    let url = new URL(window.location.href);
    let path = url.pathname + url.search;
    getTransactionLink(path);
  }, []);

  const getTransactionLink = async (path) => {
    try {
      const { data } = await axios.get(`transactions/short-url${path}`);
      if (data.success) {
        window.location.href = data.short_url.destination_url;
      }
    } catch (e) {
      window.location.href = '/';
      Sentry.captureException(e);
    }
  };

  return (
    <div id='outer-container'>
      <div id='page-wrap'></div>
    </div>
  );
};
