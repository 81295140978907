import * as Sentry from '@sentry/react';
import ChevronLeftIcon from '../../../../assets/images/chevron-left.svg';
import home from '../../../../assets/images/home.svg';
import axios from 'axios';
import SpinnerAnimation from '../../../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import DocumentList from '../../../../components/shared/SidebarMenu/DocumentList';
import appContext from '../../../../context/app-context';
import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

export default function AdditionalInfo() {
  const navigate = useNavigate();
  const { currentTransaction, setSendingPage, getToken, setContinuePage } = useContext(appContext);

  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [showDocuments, setShowDocuments] = useState(false);
  const [itemActive, setItemActive] = useState(false);
  const [consent, setConsent] = useState(false);
  const [document, setDocument] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [fields, setFields] = useState([]);
  const [fieldsGrouped, setFieldsGrouped] = useState([]);
  const [saved, setSaved] = useState(false);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchDocs();
  }, []);

  useEffect(() => {
    const groupedObjects = groupObjectsByField(fields);
    setFieldsGrouped(groupedObjects);
  }, [fields]);

  useEffect(() => {
    documents.forEach((element) => {
      if (element.additional_field_count > 0) {
        setConsent(true);
        setSaved(true);

        sessionStorage.setItem('transaction_has_additional_fields', true);
      }
    });
  }, [documents]);

  function groupObjectsByField(objects) {
    const groupedObjects = {};

    objects.forEach((obj) => {
      const groupName = obj.group_field.replace(/_/g, ' ');
      const capitalizedGroupName =
        groupName.charAt(0).toUpperCase() + groupName.slice(1);

      if (!groupedObjects.hasOwnProperty(capitalizedGroupName)) {
        groupedObjects[capitalizedGroupName] = [];
      }

      groupedObjects[capitalizedGroupName].push(obj);
    });

    return groupedObjects;
  }

  function searchById(id, data) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].id === id) {
        return data[i];
      }
    }
    return null; // Return null if no element with the given ID is found
  }

  async function fetchDocs() {
    try {
      const { data } = await axios.get(
        `transactions/${currentTransaction.id}/documents`
      );

      setDocuments(data.documents);
    } catch (e) {
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  }

  async function fetchFieldGroups(document) {
    setLoader(true);
    try {
      const { data } = await axios.get(
        `additional-field-groups/${document.id}`
      );
      setLoader(false);
      return data;
    } catch (e) {
      setLoader(false);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  }

  async function buildItems(document) {
    const groups = await fetchFieldGroups(document);
    const checklist = groups.map((group) => {
      const signatories = JSON.parse(group.signatories);
      const checked = signatories.some(
        (signatory) => signatory.is_checked === 'true'
      );
      return { ...group, is_checked: checked, signatories: signatories };
    });
    
    //  update fields state
    setFields(checklist);

    const items = [];
    checklist.forEach((field) => {
      if (field.is_checked) items.push(field.id);
    });

    setSelectedCheckboxes(items);
  }

  function showFields(document) {
    setItemActive(true);
    setShowDocuments(false);
    setDocument(document);
    buildItems(document);
  }

  const goBack = () => {
    setFields([]);
    setSelectedCheckboxes([]);

    if (itemActive) {
      setItemActive(false);
      return;
    }

    setSendingPage(2);
  };

  const handleParentCheckboxChange = (event, currentIndex, id) => {
    const checkboxId = event.target.id;

    //  update the state of the checkboxList parent element
    // const items = [...fields];
    // const item = items[currentIndex];

    const item = searchById(id, fields);

    item.is_checked = event.target.checked;

    //  map state of parent isChecked to state of siblings isChecked
    item.signatories = item.signatories.map((signatory) => {
      return { ...signatory, is_checked: event.target.checked };
    });

    event.target.checked
      ? setSelectedCheckboxes([...selectedCheckboxes, checkboxId])
      : setSelectedCheckboxes(
          selectedCheckboxes.filter((id) => id !== checkboxId)
        );
  };

  const handleChildCheckboxChange = (event, parentIndex, currentIndex, id) => {
    const item = searchById(id, fields);

    item.signatories[currentIndex].is_checked = event.target.checked;
    const siblingsChecked = item.signatories.some(
      (item) => item.is_checked === true
    );

    if (!siblingsChecked) {
      item.is_checked = false;
      setSelectedCheckboxes(selectedCheckboxes.filter((id) => id !== item.id));
    }
  };

  const handleAllCheckboxChange = (event, group) => {
    const isChecked = event.target.checked;
    const checkboxes = fieldsGrouped[group].map(
      (item) => item.id + item.name + item.id
    );

    for (let i = 0; i < fieldsGrouped[group].length; i++) {
      const item = searchById(fieldsGrouped[group][i].id, fields);
      item.signatories = item.signatories.map((signatory) => {
        return { ...signatory, is_checked: isChecked };
      });
      item.is_checked = isChecked;
    }

    isChecked
      ? setSelectedCheckboxes([...selectedCheckboxes, ...checkboxes])
      : setSelectedCheckboxes(
          selectedCheckboxes.filter((id) => checkboxes.indexOf(id) !== -1)
        );
  };

  const checkIfAllChecked = (group) => {
    const fields = fieldsGrouped[group];
    return fields.every((item) => item.is_checked);
  };

  function createBatch(items) {
    const batch = [];
    items.map((item) => {
      JSON.parse(item.additional_fields).map((additional_field) => {
        item.signatories.map((signatory) => {
          if (signatory.is_checked) {
            batch.push({
              additional_field_id: additional_field.id,
              user_id: signatory.user_id,
              document_id: document.id
            });
          }
        });
      });
    });
    return batch;
  }

  async function save() {
    let batch = [];
    if (consent) {
      const data = fields.filter((checkbox) => checkbox.is_checked == true);
      batch = createBatch(data);
    }
    try {
      await axios.post('additional-fields/batch', {
        batch: batch,
        document_id: document.id
      });
      setSaved(true);
      setItemActive(false);
      setSelectedCheckboxes([]);
      setFields([]);
      fetchDocs();
    } catch (e) {
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  }

  async function continueAddFields() {
    sessionStorage.setItem('transaction_has_additional_fields', consent);
  }

  return (
    <>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      ></motion.div>
      <Row className='m0 '>
        <Col xs={{ span: 12 }} className='p0'>
          <div className={styles.header}>
            <div
              className={styles.chevronLeftIcon}
              onClick={() => setSendingPage(2)}
            >
              <img src={ChevronLeftIcon} alt='go back' />
            </div>
            <p className='mb-1 fw-500 fs-14'>Step 3 of 4</p>
            <p className='m0 fw-700 fs-16'>Additional Information</p>
            <img
              src={home}
              alt='home'
              className={styles.homeBtn}
              onClick={() => navigate('/')}
            />
          </div>
        </Col>
      </Row>

      {itemActive && (
        <Row className='m0'>
          <Col
            lg={{ span: 4, offset: 4 }}
            md={{ span: 6, offset: 3 }}
            className='bg-white min-height-full-page'
          >
            <div
              className={`pt-5 bg-white fade-in ${
                selectedCheckboxes.length > 0 && itemActive
                  ? `p-b-250`
                  : `p-b-150`
              }`}
            >
              <div className='pb-2 pt-5 text-center px-5 mb-2 mt-2'>
                <span className='badge badge-info fw-600'>PDF</span>
              </div>
              <p className='text-center fs-18 fw-800'>{document?.title}</p>
              <p className='text-center'>
                Select which additional information signers should capture.
              </p>

              {loader ? (
                <div className='p-t-50'>
                  <SpinnerAnimation />
                </div>
              ) : (
                <>
                  {Object.entries(fieldsGrouped).map(([group, objects]) => (
                    <div key={group}>
                      <h5 className='group-heading'>{group}</h5>
                      <div className={styles.item}>
                        <div
                          className={
                            checkIfAllChecked(group)
                              ? styles.additionalFieldContainerNoBorder
                              : styles.additionalFieldContainer
                          }
                        >
                          <div className={styles.additionalField}>
                            <Form.Check
                              id={group + 'all'}
                              type='checkbox'
                              onChange={(e) =>
                                handleAllCheckboxChange(e, group)
                              }
                              checked={checkIfAllChecked(group)}
                              label='ALL'
                              className={'d-flex align-items-center m0'}
                            />
                          </div>
                        </div>
                      </div>
                      {objects.map((item, i) => (
                        <div key={item.id} className={styles.item}>
                          <div
                            className={
                              item.is_checked
                                ? styles.additionalFieldContainerNoBorder
                                : styles.additionalFieldContainer
                            }
                          >
                            <div className={styles.additionalField}>
                              <Form.Check
                                id={item.id + item.name + item.id}
                                type='checkbox'
                                onChange={(e) =>
                                  handleParentCheckboxChange(e, i, item.id)
                                }
                                checked={item.is_checked}
                                label={item.name}
                                className={'d-flex align-items-center m0'}
                              />
                            </div>
                          </div>

                          <div className={styles.additionalFieldChildContainer}>
                            <div
                              style={{
                                paddingLeft: '15px',
                                paddingRight: '15px'
                              }}
                            >
                              {item.is_checked &&
                                item.signatories.map((signatory, j) => (
                                  <div
                                    className={styles.additionalFieldContainer}
                                    key={`${item.id}-${signatory.id}`}
                                  >
                                    <div className={styles.additionalField}>
                                      <Form.Check
                                        id={signatory.id + item.name}
                                        type='checkbox'
                                        checked={signatory.is_checked}
                                        onChange={(e) =>
                                          handleChildCheckboxChange(
                                            e,
                                            i,
                                            j,
                                            item.id
                                          )
                                        }
                                        label={
                                          signatory.first_name
                                            ? `${signatory.first_name} ${signatory.last_name}`
                                            : signatory.email
                                            ? signatory.email
                                            : signatory.contact
                                        }
                                        className={
                                          'd-flex align-items-center m0'
                                        }
                                      />
                                    </div>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))}
                </>
              )}
            </div>
          </Col>
        </Row>
      )}
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className={!itemActive ? 'bg-white min-height-full-page' : 'bg-white'}
        >
          {!itemActive && (
            <div className='mb-4 ps-3 pt-5 pe-3 fade-in'>
              <div className='pb-2 pt-5 text-center max-width px-5 mb-2 mt-2'>
                <p className=''>
                  Do you need any of the signers to capture additional
                  information when signing any of the files in this transaction?
                </p>
              </div>

              <label className='react-switch ml-0 w-100'>
                <input
                  className='react-switch-checkbox'
                  type='checkbox'
                  checked={consent}
                  onChange={(e) => setConsent(e.target.checked)}
                />
                <div
                  style={
                    consent
                      ? {
                          left: 'calc(100% - 4px)',
                          transform: 'translateX(-100%)'
                        }
                      : {}
                  }
                  className='react-switch-button'
                />
                <div className='react-switch-labels'>
                  <span onClick={save}>No</span>
                  <span>Yes</span>
                </div>
              </label>
            </div>
          )}

          {/* list documents */}
          <div className={'p-l-15 p-r-15'}>
            {consent && !itemActive && (
              <DocumentList
                onItemClick={showFields}
                documents={documents}
                consent={consent}
                itemActive={itemActive}
                saved={saved}
              />
            )}
          </div>

          {/* buttons */}
          <div className={styles.footerAnimate}>
            {(!consent || (saved && !itemActive)) && (
              <Button
                onClick={() => (continueAddFields(), setSendingPage(3))}
                variant='dark'
                className='btn-block btn-rounded mb-4 fw-700'
              >
                Continue
              </Button>
            )}

            {selectedCheckboxes.length > 0 && itemActive && (
              <Button
                onClick={save}
                variant='dark'
                className='btn-block btn-rounded mb-4 fw-700'
              >
                Save
              </Button>
            )}

            <span onClick={() => goBack()} className='m0 fw-700 fs-16 pointer'>
              Back
            </span>
          </div>
        </Col>
      </Row>
    </>
  );
}
