import axios from 'axios';

export const verifyIdByFace = ({
  idNumber,
  userCountry,
  email,
  fileName,
  transactionId,
  documentId
}) =>
  axios
    .post('validate/rsaid', {
      id_number: idNumber,
      country: userCountry,
      email: email,
      file_name: fileName,
      transaction_id: transactionId,
      document_id: documentId
    })
    .then((res) => res.data);

export const verifyIdByName = ({
  idNumber,
  userCountry,
  email,
  firstName,
  lastName
}) =>
  axios
    .post('validate/rsaid', {
      id_number: idNumber,
      country: userCountry,
      email: email,
      first_name: firstName,
      last_name: lastName
    })
    .then((res) => res.data);

export const verifyPassport = ({ idNumber, country, email, front }) =>
  axios
    .post('validate/passport', {
      id_number: idNumber,
      country,
      email,
      front
    })
    .then((res) => res.data);

export const verifyDocument = ({
  idNumber,
  idDocumentType,
  country,
  email,
  frontFileName,
  backFileName = '',
  selfieFileName = '',
}) =>
  axios
    .post('validate/document', {
      id_number: idNumber,
      id_document_type: idDocumentType,
      selfie_file_name: selfieFileName,
      country,
      email,
      front_file_name: frontFileName,
      back_file_name: backFileName
    })
    .then((res) => res.data);

export const registerManualVerification = async ({
  first_name,
  last_name,
  id_number,
  middle_name,
  country,
  email,
  signatory
}) =>
  axios
    .post('manual-verification', {
      first_name,
      last_name,
      id_number,
      middle_name,
      country,
      email,
      signatory
    })
    .then((res) => res.data);

export const getVerification = async () =>
  axios
    .get('users/verification')
    .then((res) => res.data)
