import * as Sentry from '@sentry/react';
import DewlyLogo from '../../../../assets/images/dewly-logo.svg';
import axios from 'axios';
import SpinnerAnimation from '../../../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import appContext from '../../../../context/app-context';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

export default function Confirmation() {
  const [signers, setSigners] = useState([]);
  const {
    setSendingPage,
    currentTransaction,
    documents,
    token,
    getDocuments,
    user,
    getToken,
    setContinuePage,
  } = useContext(appContext);
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderText, setShowLoaderText] = useState(false);
  const [showBilling, setShowBilling] = useState(false);
  const [card, setCard] = useState(null);
  const [cardLoadig, setCardLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getSignatories();
    getDocuments(currentTransaction.id);
  }, [token]);

  useEffect(() => {
    getCard();
  }, [user]);

  const send = async () => {
    try {
      setShowLoader(true);
      const { data } = await axios.get(
        `transactions/${currentTransaction.id}/signatories/send`
      );

      if (data.success) {
        sessionStorage.removeItem('transactionId');
        sessionStorage.removeItem('transactionName');
        setShowLoader(false);
        setSendingPage(5);
      }
    } catch (e) {
      setShowLoader(false);
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }

    const has_additional_fields = sessionStorage.getItem(
      'transaction_has_additional_fields'
    );

    if (!has_additional_fields || has_additional_fields == 'false') {
      deleteAdditionalFields();
    } else {
    }
  };

  // DELETE ADDITIONAL FIELDS IF SET TO NO
  async function deleteAdditionalFields() {
    try {
      const data = await axios.post('additional-fields/delete', {
        documents: documents
      });
    } catch (e) {
      console.log(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  }

  async function getSignatories() {
    try {
      setShowLoaderText(true);
      const { data } = await axios.get(
        `transactions/${currentTransaction.id}/signatories`
      );
      setSigners(data.signatories);
      setShowLoaderText(false);
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  }

  function cancel() {
    setShowBilling(false);
  }

  function goToBilling() {
    setSendingPage(7);
  }

  function showTerms() {
    console.log('terms');
  }

  function showBillingPopup() {
    if (card) {
      send();
    } else {
      setShowBilling(true);
    }
  }

  const getCard = async () => {
    try {
      setCardLoading(true);
      const { data } = await axios.get(`users/${user?.id}/cards`);
      console.log(data);
      if (data.success) {
        setCard(data.card);
      }
      setCardLoading(false);
    } catch (e) {
      setCardLoading(false);
      Sentry.captureException(e);
      console.log(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {showBilling && <div className={styles.overlay}></div>}

      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          <div className=' pe-4 ps-4 text-center'>
            {showLoaderText ? (
              <div className='mb-3 pt-5'>
                <SpinnerAnimation />
              </div>
            ) : (
              <p className='pt-5 fs-18 fw-800'>
                You are sending {documents.length}
                {documents.length > 1 ? ' files' : ' file'} <br />
                to {signers.length}{' '}
                {signers.length > 1 ? 'recipients ' : ' recipient '}
                for signing
              </p>
            )}

            {showLoaderText ? (
              <div className='mb-3'>
                <SpinnerAnimation />
              </div>
            ) : (
              <>
                <p className='fs-16 fw-700 mt-5 color-gray'>
                  {currentTransaction.name}
                </p>
                <div className='mb-4'>
                  {documents.length
                    ? documents.map((document, i) => (
                        <p key={i} className='mb-1 fs-14 fw-700'>
                          {document.title}
                        </p>
                      ))
                    : ''}
                </div>
                {signers.length &&
                  signers.map((signer, i) => (
                    <Card key={i} className='mb-2 cardWhite'>
                      <p className='text-center w-full m0 fs-12 fw-700'>
                        {signer.first_name ? (
                          <>
                            {signer.first_name} {signer.last_name}
                          </>
                        ) : (
                          <>{signer.email}</>
                        )}
                      </p>
                    </Card>
                  ))}
              </>
            )}
            <img src={DewlyLogo} alt='dewly' className={styles.DewlyLogo} />

            {showBilling ? (
              <Row className='m0'>
                <Col lg={{ span: 4, offset: 4 }} md={{ span: 6, offset: 3 }}>
                  <div className={styles.footerAnimate}>
                    <Row className='m0'>
                      <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 6, offset: 3 }}
                        className={styles.footerInner}
                      >
                        <div className='ps-3 pe-3 pt-3 pb-4'>
                          <p className='fw-700 fs-16'>
                            A payment method is required
                            <br />
                            to send files for sending
                          </p>
                        </div>
                        <Button
                          variant='dark'
                          className='btn-block btn-rounded mb-3 fw-700'
                          onClick={(e) => goToBilling()}
                        >
                          Add payment method
                        </Button>
                        <p className='fw-500 fs-12 mt-2 mb-4 color-lightgray'>
                          $1 per transaction | $5 p.m storage
                          <br />
                          <u>
                            <a
                              onClick={(e) => showTerms(e)}
                              className='pointer'
                            >
                              {'Terms & Conditions'}
                            </a>
                          </u>
                        </p>
                        <p className='mt-3 mb-0' onClick={(e) => cancel()}>
                          <span className='pointer fw-700'>Cancel</span>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row className='m0'>
                <Col
                  lg={{ span: 4, offset: 4 }}
                  md={{ span: 6, offset: 3 }}
                  className=''
                >
                  <div className={styles.footer}>
                    <Row className='m0'>
                      <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 6, offset: 3 }}
                        className={styles.footerInner}
                      >
                        {showLoader ? (
                          <div className='mb-3'>
                            <SpinnerAnimation />
                          </div>
                        ) : (
                          <Button
                            variant='dark'
                            className='btn-block btn-rounded mt-0 fw-700'
                            onClick={(e) => showBillingPopup()}
                            disabled={cardLoadig}
                          >
                            Send for signing
                          </Button>
                        )}
                        <Row className='m-t-15'>
                          <Col xs={'6'} className='p-r-5'>
                            <Button
                              variant='outline-dark'
                              className='btn-block btn-rounded fs-16 fw-700'
                              onClick={(e) =>
                                currentTransaction.closed == 0
                                  ? setSendingPage(3)
                                  : setSendingPage(2)
                              }
                            >
                              <span className='m0 fw-700 fs-16 pointer'>
                                Edit
                              </span>
                            </Button>
                          </Col>
                          <Col xs={'6'} className='p-l-5'>
                            <Button
                              variant='outline-dark'
                              className='btn-block btn-rounded fs-16 fw-700'
                              onClick={(e) => navigate('/')}
                            >
                              <span className='m0 fw-700 fs-16 pointer'>
                                Save Draft
                              </span>
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}
