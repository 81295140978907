import { WhiteChevronRightIcon } from '../../../../components/Icons';
import appContext from '../../../../context/app-context';
import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DocumentList from './DocumentList/DocumentList';
import styles from './styles.module.css';
import { createSearchParams } from 'react-router-dom';
import BlackScribble from '../../../../assets/images/black-scribble.svg';
import SpinnerAnimation from '../../../../components/Spinners/SpinnerAnimation/SpinnerAnimation';

export default function Finalisatiion() {
  const {
    transaction,
    documents,
    singleSigning,
    currentDocument,
    setDocumentSigned,
    token,
    unSignedDocuments,
    getDocuments,
    setCurrentPage,
    encodedEmail,
    setContinuePage
  } = useContext(appContext);
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState('');

  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);
  let transactionUuid = params.get('transaction');
  let signatory = params.get('signatory') ?? '';
  let self = params.get('self');
  let signType = params.get('sign_type');

  useEffect(() => {
    getDocuments(transaction.id, encodedEmail, signatory);
  }, [unSignedDocuments]);

  async function handleSignDocument() {
    if (unSignedDocuments == 1 || !singleSigning) {
      sessionStorage.removeItem('otp_confirmed');
    }

    sessionStorage.removeItem('selfie_verified');
    console.log(unSignedDocuments, 'the unsigned documents in finalisation');
    if (token) {
      if (unSignedDocuments > 0 ) {
        window.location.reload();
      } else {
        window.location.href = '/';
      }
      // if (unSignedDocuments > 0) {
      //   setCurrentPage(1);
      // } else {
      //   if (signatory && signType === 'new') {
      //     navigate({
      //       pathname: '/continue',
      //       search: `?${createSearchParams(
      //         'return_to_signing=true' +
      //           '&transaction=' +
      //           transactionUuid +
      //           '&sign_type=' +
      //           signType +
      //           '&signatory=' +
      //           signatory +
      //           '&self=' +
      //           self
      //       )}`
      //     });
      //   } else {

      //     if (signType === 'new') {
      //       setContinuePage(3);
      //     }

      //     navigate('/');
      //   }
      // }
    // } else if (!token && unSignedDocuments > 1) {
    //   setCurrentPage(1);
    } else {
      navigate('/continue');
    }

    setDocumentSigned(true);
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <Col md={{ span: 6, offset: 3 }}>
          <div className={styles.main}>
            <div className={styles.content}>
              <div className={styles.signedAnimationContainer}>
                <motion.div
                  initial={{ opacity: 1 }}
                  animate={{ opacity: [1, 1, 1, 0] }}
                  transition={{ duration: 2 }}
                  className='text-center p-relative'
                >
                  <motion.div
                    className={styles.dotAnimation}
                    animate={{ x: [-20, 20, -20, 20] }}
                    transition={{ ease: 'easeOut', duration: 2 }}
                  />
                  <motion.div
                    className={styles.dotAnimation}
                    animate={{ x: [20, -20, 20, -20] }}
                    transition={{ ease: 'easeOut', duration: 2 }}
                  />
                  <div>
                    <p className='fw-800 fs-18 pt-4 pb-4'>
                      Signing {unSignedDocuments > 1 ? <>files</> : <>file</>}
                    </p>
                  </div>
                </motion.div>
              </div>

              <div className={styles.signedAnimationContainer}>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: [0, 0, 0, 1] }}
                  transition={{ duration: 2.5 }}
                  className='text-center p-relative'
                >
                  <div className={styles.signedText}>
                    <img
                      src={BlackScribble}
                      alt='scribble'
                      className={styles.scribbleIcon}
                    />
                    <p className='fw-800 fs-18 pt-1 pb-4'>Signed</p>
                  </div>
                </motion.div>
              </div>
              <div className={styles.spacer} />
              <p className={styles.transactionTitle}>{transaction?.name} </p>
              {currentDocument && singleSigning ? (
                <DocumentList
                  key={currentDocument.id}
                  title={currentDocument.title}
                />
              ) : (
                ''
              )}

              {documents &&
                !singleSigning &&
                documents.map((document) => {
                  return document?.signed_at != null ? (
                    <DocumentList key={document.id} title={document.title} />
                  ) : (
                    ''
                  );
                })}

              <p className={styles.error}>{error ? error : ''}</p>

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: [0, 0, 0, 1] }}
                transition={{ duration: 3 }}
              >
                {showLoader ? (
                  <div className={styles.footer}>
                    <SpinnerAnimation />
                  </div>
                ) : (
                  <Button
                    onClick={(e) => handleSignDocument()}
                    className={`${styles.btnFooter} btn btn-dark rounded-circle btn-md-rounded pulse-animate`}
                  >
                    <span className='p-relative' style={{ right: '-2px' }}>
                      <WhiteChevronRightIcon />
                    </span>
                  </Button>
                )}
              </motion.div>
            </div>
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}
