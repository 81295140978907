import { useState, useContext, useEffect, useRef } from 'react';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import GoBackHeader from '../../../../components/GoBackHeader/GoBackHeader';
import appContext from '../../../../context/app-context';
import cameraIcon from '../../../../assets/images/camera.svg';
import Identification from '../../../../assets/images/identification.svg';
import UserCircle from '../../../../assets/images/user-circle.svg';
import profilePhotoPlaceholder from '../../../../assets/images/black-scribble.svg';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { ChevronRightIcon } from '../../../../components/Icons/Icons';
import axios from 'axios';
import SpinnerAnimation from '../../../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import * as Sentry from '@sentry/react';
import Switch from '../../../../components/Switch/Switch';

export default function MyProfile() {
  const { setProfilePage, user, getToken, setContinuePage } = useContext(appContext);
  const [sendOTP, setSendOTP] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOtp] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [showLoader, setShowLoader] = useState(false);
  const [profile, setProfile] = useState('');
  const [error, setError] = useState('');
  const OTPInput = useRef();
  const [switchToBusiness, setSwitchToBusiness] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const [logo, setLogo] = useState(null);
  const [business, setBusiness] = useState(null);
  const [businessLoader, setBusinessLoader] = useState(false);

  useEffect(() => {
    if (OTPInput.current) OTPInput.current.focus();
  }, [OTPInput]);

  let navigate = useNavigate();
  const previousPage = () => {
    let path = `/`;
    navigate(path);
  };

  function closeFooter() {
    setSendOTP(false);
    setShowOTP(false);
  }

  function showIDVerification() {
    setProfilePage(4);
  }

  const sendOtp = async () => {
    try {
      setShowLoader(true);
      const { data } = await axios.get(`/users/${user.id}/send-otp`);
      if (data.success) {
        setShowOTP(true);
        setShowLoader(false);
      }
      console.log(data);
    } catch (e) {
      setShowLoader(false);
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  const checkOtp = async () => {
    setError('');
    try {
      setShowLoader(true);
      const { data } = await axios.post('check-otp', {
        otp: otp,
        email: user.email
      });
      if (data.success) {
        setError('');
        setProfilePage(3);
      }
      setShowLoader(false);
    } catch (e) {
      setError(e.response.data.message);
      setShowLoader(false);
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  useEffect(() => {
    if (user?.has_business) {
      setIsBusiness(true);
      getBusiness();
      getCompanyLogo();
    }
    setProfile(user?.profile);
    setTimeout(() => {
      setBusinessLoader(true);
    }, 2500);
  }, [user]);

  const uploadRef = useRef();
  const handleUpload = () => {
    uploadRef.current.click();
  };

  const saveProfilePhoto = async (file) => {
    let fileResult = '';
    getBase64(file, async (result) => {
      fileResult = result;
      setProfile(result);

      if (file) {
        let formData = new FormData();
        formData.append('type', 'profile');
        formData.append('document', file);
        formData.append('file', fileResult);

        const config = {
          headers: {
            'content-type': `multipart/form-data;`
          }
        };

        try {
          const { data } = await axios.post(
            `users/${user.id}/documents`,
            formData,
            {
              ...config
            }
          );
          if (data.success) {
            setProfile(data.profile);
          }
        } catch (e) {
          console.log(e);
          Sentry.captureException(e);
          if (e.response?.data?.message === 'Unauthenticated.') {
            sessionStorage.removeItem('token');
            await getToken();
            setContinuePage(0);
            navigate('/continue');
          }
        }
      }
    });
  };

  const getBusiness = async () => {
    try {
      const { data } = await axios.get(`users/${user?.id}/business`);
      setBusiness(data.business);
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  const getCompanyLogo = async () => {
    try {
      const { data } = await axios.get(
        `users/${user.id}/documents?type=company_logo`
      );
      setLogo(data.company_logo);
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  async function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const saveCompanyLogo = async (file) => {
    getBase64(file, async (result) => {
      setLogo(result);

      if (file) {
        let formData = new FormData();
        formData.append('type', 'company_logo');
        formData.append('document', file);

        const config = {
          headers: {
            'content-type': `multipart/form-data;`
          }
        };

        try {
          const { data } = await axios.post(
            `users/${user.id}/documents`,
            formData,
            {
              ...config
            }
          );
          if (data.success) {
            setProfile(data.profile);
          }
        } catch (e) {
          console.log(e);
          Sentry.captureException(e);
          if (e.response?.data?.message === 'Unauthenticated.') {
            sessionStorage.removeItem('token');
            await getToken();
            setContinuePage(0);
            navigate('/continue');
          }
        }
      }
    });
  };

  const switchToBusinessProfile = async () => {
    try {
      await axios.post('users/set-business-profile', {
        has_business: 1
      });
      setProfilePage(6);
      // navigate('/add-business-profile');
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        navigate('/continue');
      }
    }
  };

  function goToDetails() {
    if (isBusiness) {
      setProfilePage(6);
    } else {
      setProfilePage(1);
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {sendOTP || switchToBusiness ? (
        <div className={styles.overlay}></div>
      ) : null}
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          <GoBackHeader previousPage={previousPage} pageName={'My Profile'} />
          <div className='text-center pe-1 ps-1 p-b-120'>
            <div className='text-center p-t-100'>
              {!user || !businessLoader ? (
                <Row>
                  <Col lg={{ span: 4, offset: 4 }} md={{ span: 6, offset: 3 }}>
                    <div className={styles.fullscreenSpinner}>
                      <div className='center-content'>
                        <SpinnerAnimation />
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : !isBusiness ? (
                <span>
                  <img
                    src={profile ? profile : profilePhotoPlaceholder}
                    className={styles.photo}
                    width={200}
                    height={200}
                    alt='Profile'
                  />
                  <div>
                    <Button
                      variant='light'
                      className='btn-white btn-shadow btn-rounded btn-sm p-relative top-less-10 p0 h-30 w-30'
                      onClick={handleUpload}
                    >
                      <img
                        src={cameraIcon}
                        alt='edit'
                        className={styles.editProfilePhoto}
                      />
                      <input
                        type='file'
                        ref={uploadRef}
                        style={{ display: 'none' }}
                        accept='image/*'
                        onChange={(e) => saveProfilePhoto(e.target.files[0])}
                      />
                    </Button>
                  </div>
                  <p className='fs-18 fw-800 mt-3'>
                    {user?.first_name} {user?.last_name}
                  </p>
                </span>
              ) : (
                <span>
                  <img
                    src={logo ? logo : profilePhotoPlaceholder}
                    className={styles.photo}
                    width={200}
                    height={200}
                    alt='Profile'
                  />
                  <div>
                    <Button
                      variant='light'
                      className='btn-white btn-shadow btn-rounded btn-sm p-relative top-less-10 p0 h-30 w-30'
                      onClick={handleUpload}
                    >
                      <img
                        src={cameraIcon}
                        alt='edit'
                        className={styles.editProfilePhoto}
                      />
                      <input
                        type='file'
                        ref={uploadRef}
                        style={{ display: 'none' }}
                        accept='image/*'
                        onChange={(e) => saveCompanyLogo(e.target.files[0])}
                      />
                    </Button>
                  </div>
                  <p className='fs-18 fw-800 mt-3'>{business?.name}</p>
                </span>
              )}
            </div>
            {user?.verified ? (
              isBusiness ? (
                ''
              ) : (
                <div className={styles.verifyStatus}>
                  <span>Verified</span>
                </div>
              )
            ) : isBusiness ? (
              ''
            ) : (
              <div className={styles.verifyStatus}>
                <span>Pending Verification</span>
              </div>
            )}
            <div className='text-left'>
              <Card className='mb-2 cardWhite' onClick={() => goToDetails()}>
                <Card.Body className={styles.cardBody}>
                  <Card.Text className={styles.cardBodyText} as='h6'>
                    <div className='align-items-center d-flex'>
                      <img src={Identification} alt='id' className='h-24' />
                      <p className='mb-0 ms-2 fw-700 fs-14'>My details</p>
                    </div>
                  </Card.Text>
                  <div className={styles.viewFileBtn}>
                    <ChevronRightIcon />
                  </div>
                </Card.Body>
              </Card>

              {!isBusiness ? (
                <Card
                  className='mb-4 cardWhite'
                  onClick={() => setProfilePage(2)}
                >
                  <Card.Body className={styles.cardBody}>
                    <Card.Text className={styles.cardBodyText} as='h6'>
                      <div className='align-items-center d-flex'>
                        <img src={UserCircle} alt='id' className='h-24' />
                        <p className='mb-0 ms-2 fw-700 fs-14'>
                          My ID documents
                        </p>
                      </div>
                    </Card.Text>
                    <div className={styles.viewFileBtn}>
                      <ChevronRightIcon />
                    </div>
                  </Card.Body>
                </Card>
              ) : (
                ' '
              )}
            </div>
            {!isBusiness && !user?.has_transactions ? (
              <div className='text-center m-t-40 m-b-20 p-relative'>
                <p className='color-gray fs-12 fw-500'>PROFILE TYPE</p>
                <Switch
                  isOn={!isBusiness}
                  handleToggle={() => setSwitchToBusiness(!isBusiness)}
                  left={'Business'}
                  right={'Personal'}
                />
              </div>
            ) : (
              ''
            )}
          </div>
          { isBusiness ? (
          <div className={`pe-3 ps-3 ${styles.footer}`}>
            <Button
              variant='outline-dark'
              className='btn-block btn-rounded fw-700 fs-16'
              onClick={() => setSendOTP(true)}
            >
              Change password
            </Button>
          </div> ) : <></>} 

          {switchToBusiness && (
            <div className={styles.footerAnimate}>
              <Row className='m0'>
                <Col
                  lg={{ span: 4, offset: 4 }}
                  md={{ span: 6, offset: 3 }}
                  className={styles.passwordChangeFooter}
                >
                  <div className='ps-3 pe-3 pt-3'>
                    <p className='fw-700 fs-16'>
                      Are you sure you want to switch
                      <br />
                      to a Business profile?
                    </p>
                    <p className='fw-500 mb-4'>
                      Business profiles can only send
                      <br />
                      transactions. This change is permanent
                      <br />
                      and cannot be reversed.
                    </p>
                  </div>
                  {showLoader ? (
                    <SpinnerAnimation />
                  ) : (
                    <Button
                      variant='dark'
                      className='btn-block btn-rounded fw-700 fs-16'
                      onClick={() => switchToBusinessProfile()}
                    >
                      Continue
                    </Button>
                  )}
                  <p
                    className='mt-3'
                    onClick={() => setSwitchToBusiness(false)}
                  >
                    <span className='pointer fw-700'>Cancel</span>
                  </p>
                </Col>
              </Row>
            </div>
          )}

          {sendOTP && !showOTP ? (
            <div className={styles.footerAnimate}>
              <Row className='m0'>
                <Col
                  lg={{ span: 4, offset: 4 }}
                  md={{ span: 6, offset: 3 }}
                  className={styles.passwordChangeFooter}
                >
                  <div className='ps-3 pe-3 pt-3'>
                    <p className='fw-700 fs-16'>
                      Are you sure you want to
                      <br />
                      change password?
                    </p>
                    <p className='fw-500 mb-4'>
                      We will email you an OTP before your
                      <br />
                      password can be changed.
                    </p>
                  </div>
                  {showLoader ? (
                    <SpinnerAnimation />
                  ) : (
                    <Button
                      variant='dark'
                      className='btn-block btn-rounded fw-700 fs-16'
                      onClick={() => sendOtp()}
                    >
                      Continue
                    </Button>
                  )}
                  <p className='mt-3' onClick={() => setSendOTP(false)}>
                    <span className='pointer fw-700'>Cancel</span>
                  </p>
                </Col>
              </Row>
            </div>
          ) : null}

          {showOTP ? (
            <div className={styles.footerAnimateOTP}>
              <Row className='m0'>
                <Col
                  lg={{ span: 4, offset: 4 }}
                  md={{ span: 6, offset: 3 }}
                  className={styles.passwordChangeFooter}
                >
                  <Form.Group className='mb-4 p-relative'>
                    <div className='pb-2 pt-3 pb-3'>
                      <p className='fw-700 fs-16'>Enter OTP</p>
                    </div>
                    <Form.Control
                      type='number'
                      inputMode='numeric'
                      className={styles.input}
                      ref={OTPInput}
                      onChange={(e) => setOtp(e.currentTarget.value)}
                    />
                    {error ? (
                      <small className={styles.error}>{error}</small>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  {showLoader ? (
                    <SpinnerAnimation />
                  ) : (
                    <Button
                      variant='dark'
                      className='btn-block btn-rounded fw-700 fs-16'
                      onClick={() => checkOtp()}
                    >
                      Continue
                    </Button>
                  )}
                  <p className='mt-3' onClick={() => closeFooter()}>
                    <span className='pointer fw-700'>Cancel</span>
                  </p>
                </Col>
              </Row>
            </div>
          ) : null}
        </Col>
      </Row>
    </motion.div>
  );
}
