import React, { useContext, useState, useEffect } from 'react';
import styles from './styles.module.css';
import './SidebarMenu.css';
import { fallDown as Menu } from 'react-burger-menu';
import burgerIcon from '../../assets/images/burger.svg';
import crossIcon from '../../assets/images/cross.svg';
import { Button, Col, Row } from 'react-bootstrap';
import axios from 'axios';
import appContext from '../../context/app-context';
import DewlyLogo from '../../assets/images/dewly-logo.svg';
import * as Sentry from '@sentry/react';
import SpinnerAnimation from '../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import { useNavigate } from 'react-router-dom';

export default function SidebarMenu() {
  const { getToken, user, setContinuePage } = useContext(appContext);
  const [showLoader, setShowLoader] = useState(false);
  const [isBusiness, setIsBusiness] = useState(false);
  const navigate = useNavigate();

  const signout = async () => {
    setContinuePage(0);
    try {
      setShowLoader(true);
      const { data } = await axios.post('logout');
      setContinuePage(0);
      if (data.success) {
        sessionStorage.clear();
        // sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    } catch (e) {
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.clear();
        // sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
      console.log(e);
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    if (user?.has_business) {
      setIsBusiness(true);
    }
  }, [user]);

  return (
    <Menu
      pageWrapId={'page-wrap'}
      outerContainerId={'outer-container'}
      width='100%'
      noOverlay
      disableAutoFocus
      customBurgerIcon={<img src={burgerIcon} alt='menu' />}
      customCrossIcon={<img src={crossIcon} alt='close' />}
      itemListElement='div'
      className='p-relative'
    >
      <div className='pt-5' />
      <button
        onClick={() => navigate('/my-profile')}
        className={`${styles.link} menu-item`}
      >
        My Profile
      </button>
      <button
        onClick={() => navigate('/billing')}
        className={`${styles.link} menu-item`}
      >
        Billing
      </button>
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className=''
        >
          <div className='footer'>
            <div className={styles.grayBlock}>
              <p className='fw-500 fs-14 m0'>
                Signed into a {isBusiness ? 'business' : 'personal'} profile
              </p>
              <p className='fw-700 fs-14 color-lightgray'>
                {user && user.first_name ? user?.first_name : null}{' '}
                {user && user.last_name ? user?.last_name : null}
              </p>
              {/* <Link
                to='/business-profile'
                variant='outline-dark'
                className='btn btn-outline-dark btn-rounded btn-block fs-16 fw-700'
              >
                Switch account
              </Link> */}
              {showLoader ? (
                <div className='mt-4'>
                  <SpinnerAnimation />
                </div>
              ) : (
                <Button
                  onClick={(e) => signout(e)}
                  variant='outline-dark'
                  className='btn-block btn-rounded fs-16 fw-700 mt-4'
                >
                  Sign out
                </Button>
              )}
            </div>
            <img src={DewlyLogo} alt='dewly' className='mt-5' />
          </div>
        </Col>
      </Row>
    </Menu>
  );
}
