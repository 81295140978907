import * as Sentry from '@sentry/react';
import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

import SpinnerAnimation from '../../../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import SquareLogoAnimate from '../../../../components/SquareLogoAnimate/SquareLogoAnimate';
import appContext from '../../../../context/app-context';
import AccountConfirmation from './AccountConfirmation';
import TermsAndPrivacyConfirmation from './TermsAndPrivacyConfirmation';
import styles from './styles.module.css';
import { validateEmail } from '../../../../common/utils/utils';
import { useNavigate } from 'react-router-dom';

export default function Email(props) {
  const { setUserData, declineTerms } = props;

  const {
    setContinuePage,
    email,
    originalEmail,
    idNumber,
    setEmail,
    setShowPassword,
    setUser,
    setSigningType,
    existing,
    getSignatory,
    getToken,
  } = useContext(appContext);
  const navigate = useNavigate();
  const [mail, setMail] = useState('');
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [signInType, setSignInType] = useState('signone');
  const emailInput = useRef(null);
  const [continueFromEmail, setContinueFromEmail] = useState(false);
  const [continueFromConfirmation, setContinueFromConfirmation] =
    useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);

  useEffect(() => {
    setEmail('');
    const initialEmailIsValid = validateEmail(email);
    setIsEmailValid(initialEmailIsValid);
  }, []);

  useEffect(() => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let signType = params.get('sign_type');
    if (signType === 'new') {
      setSignInType('new');
    }
    if (signType === 'existing') {
      setSignInType('existing');
      setSigningType('signin');
      if (sessionStorage.getItem('token')) {
        setContinuePage(4);
      }
    }

    if (signType === 'signup') {
      setSignInType('signup');
    }

    if (!mail) {
      if (validateEmail(email)) {
        setMail(email);
      }
    }
  }, [email]);

  useEffect(() => {
    setMail(email);
    setEmail(email);
  }, [email]);

  const welcomeMessages = () => {
    if (signInType.includes('signin')) {
      return (
        <>
          <p className='pb-4 fw-400 fs-14'>
            Welcome Back
            <br />
            <br />
            <strong className='fw-700'>
              To access your signed copies,
              <br />
              please continue with
            </strong>
          </p>
        </>
      );
    } else if (signInType.includes('signone')) {
      return (
        <>
          <p style={{ paddingBottom: '40px' }} className='fw-400 fs-14'>
            To access your <strong className='fw-700'>dewly</strong> profile,
            <br />
            please enter your email address.
          </p>
        </>
      );
    } else if (signInType.includes('new')) {
      return (
        <>
          <p className='pb-4 fw-700 fs-18'>Enter your email address</p>
        </>
      );
    } else if (signInType.includes('existing')) {
      return (
        <>
          <p className='pb-4 fw-400 fs-14'>
            Sign with <strong className='fw-700'>dewly</strong>
            <br />
            <br />
            Please provide us with your email address
          </p>
        </>
      );
    } else if (signInType.includes('signup')) {
      return (
        <>
          <p className='pb-4 fw-400 fs-14'>
            Join <strong className='fw-700'>dewly</strong>
            <br />
            <br />
            Leave signature scribbles in the previous era,
            <br />& sign files with surety and security fit for this era.
          </p>
        </>
      );
    } else {
      return (
        <>
          <p style={{ paddingBottom: '40px' }} className='fw-400 fs-14'>
            To access your signed copies,
            <br />
            please continue with...
          </p>
        </>
      );
    }
  };

  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);
  let signatory = params.get('signatory') ?? '';
  let signType = params.get('sign_type');
  let transactionUuid = params.get('transaction');
  let self = params.get('self');

  const loginUser = async (e) => {
    getSignatory(transactionUuid, self, signatory);

    e.preventDefault();
    setError('');
    setErrorMessage('');
    if (!isEmailValid) {
      setError('Please enter a valid email address');
      return;
    }

    if (signatory && signType === 'new' && !existing) {
      try {
        setShowLoader(true);
        setUserData({
          email: mail,
          id_number: idNumber,
          sign_in_type: signInType,
          cell: null,
          signatory: signatory
        });
        const { data } = await axios.put(`register-signatory/${signatory}`, {
          email: mail,
          id_number: idNumber,
          sign_in_type: signInType,
          cell: null
        });
        setMail(email);
        setEmail(email);
        sessionStorage.removeItem('token');
        if (data.success) {
          setUser(data.user);
          setShowLoader(false);
          setShowPassword(data.show_password);

          if (data.show_otp) {
            setContinuePage(1.3);
          } else {
            setContinueFromEmail(true);
          }
        } else if (data.success === false) {
          setErrorMessage(data.message);
          setShowLoader(false);
        } else {
          setContinueFromEmail(true);
          setShowLoader(false);
        }
      } catch (e) {
        console.log(e);
        setShowLoader(false);
        Sentry.captureException(e);
      }
    } else if (signatory && originalEmail !== email) {
      try {
        setShowLoader(true);
        setUserData({
          email: mail,
          id_number: idNumber,
          sign_in_type: signInType,
          cell: null,
          signatory: signatory
        });
        const { data } = await axios.post(
          `link-signatory/${signatory}/${transactionUuid}`,
          {
            email: mail,
            id_number: idNumber,
            sign_in_type: signInType,
            cell: null
          }
        );
        getSignatory(transactionUuid, data.email, signatory);
        setMail(email);
        setEmail(email);
        sessionStorage.removeItem('token');
        if (data.success) {
          setUser(data.user);
          setShowLoader(false);
          setShowPassword(data.show_password);

          if (data.show_otp) {
            setContinuePage(1.3);
          } else {
            setContinueFromEmail(true);
          }
        } else if (data.success === false) {
          setErrorMessage(data.message);
          setShowLoader(false);
        } else {
          setContinueFromEmail(true);
          setShowLoader(false);
        }
      } catch (e) {
        console.log(e);
        setShowLoader(false);
        Sentry.captureException(e);
      }
    } else {
      try {
        setShowLoader(true);
        setUserData({
          email: mail,
          id_number: idNumber,
          sign_in_type: signInType,
          cell: null,
          signatory: signatory
        });
        const { data } = await axios.post('login', {
          email: mail,
          id_number: idNumber,
          sign_in_type: signInType,
          cell: null,
          signatory: signatory
        });
        setEmail(mail);
        sessionStorage.removeItem('token');
        if (data.success) {
          setShowLoader(false);
          if (!data.user?.has_business) {
            setError('This is not a business account');
            return;
          }
          setUser(data.user);
          setShowPassword(data.show_password);
          if (data.show_otp) {
            setContinuePage(1.3);
          } else {
            setContinuePage(2);
            setShowLoader(false);
          }
        } else {
          setContinueFromEmail(true);
          setShowLoader(false);
        }
      } catch (e) {
        console.log(e);
        setShowLoader(false);
        Sentry.captureException(e);
      }
    }
  };

  return (
    <>
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page p-relative'
        >
          {continueFromEmail ? (
            <div className={styles.overlay} />
          ) : (
            continueFromConfirmation && <div className={styles.overlay} />
          )}
          <div className={styles.centerContent}>
            <div className='mt-4 pt-4 pb-5'>
              <SquareLogoAnimate />
            </div>
            <div className='text-center'>
              {welcomeMessages()}
              <Form onSubmit={loginUser}>
                <Form.Group className='mb-4'>
                  <div className='text-center pb-3 fw-700'>
                    <label className={styles.label}>Email</label>
                  </div>
                  <Form.Control
                    type='email'
                    defaultValue={email.includes('@') ? email : ''}
                    autoComplete='email'
                    ref={emailInput}
                    onChange={(e) => {
                      const emailValue = e.currentTarget.value;
                      setEmail(emailValue);
                      setIsEmailValid(validateEmail(emailValue)); // Check email validity
                    }}
                    className={styles.input}
                  />
                  {error ? <small className={styles.error}>{error}</small> : ''}
                </Form.Group>
                {errorMessage ? (
                  <div className='mb-2 mt-2'>
                    <small className={styles.error}>{errorMessage}</small>
                  </div>
                ) : (
                  ''
                )}
                <div className='text-center'>
                  {showLoader ? (
                    <SpinnerAnimation />
                  ) : (
                    <Button
                      variant='dark'
                      className='btn-rounded btn-block blackButton'
                      onClick={(e) => loginUser(e)}
                    >
                      <span className='fs-14 fw-700'>Continue</span>
                    </Button>
                  )}
                  <Button
                    variant='outline'
                    className='btn-rounded btn-block mt-2'
                    onClick={(e) => setContinuePage(0)}
                  >
                    <span className='fs-14 fw-700'>Cancel</span>
                  </Button>
                </div>
              </Form>
            </div>
          </div>
          <div className={styles.footerLogo}>
            <img
              src='/static/media/dewly-logo.95b074ed119097ec1853a1fdc0320f6b.svg'
              alt='dewly'
            />
          </div>

          {continueFromEmail && (
            <AccountConfirmation
              onProceed={() => {
                setContinueFromEmail(false);
                setContinueFromConfirmation(true);
              }}
              onCancel={() => setContinueFromEmail(false)}
            />
          )}
          {continueFromConfirmation && (
            <TermsAndPrivacyConfirmation
              onConfirm={() => setContinuePage(2)}
              onDecline={declineTerms}
            />
          )}
        </Col>
      </Row>
    </>
  );
}
