import * as Sentry from '@sentry/react';
import axios from 'axios';
import SpinnerAnimation from '../../../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import SquareLogoAnimate from '../../../../components/SquareLogoAnimate/SquareLogoAnimate';
import appContext from '../../../../context/app-context';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

export default function AddPassword(props) {
  const { userData, onCancel } = props;
  const {
    setContinuePage,
    showPassword,
    setUser,
    setIsAuth,
    setToken,
    email,
    user,
    signingType,
    setEmail,
    getTransaction,
    getDocuments,
    getSignatory,
    existing,
    getToken,
  } = useContext(appContext);
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [isError, setIsError] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [sendOTP, setSendOTP] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [showOTPLoader, setShowOTPLoader] = useState(false);
  const [newPassword, setNewPassword] = useState(showPassword);
  const [error, setError] = useState('');
  const [otpError, setOtpError] = useState('');
  const passwordInput = useRef();
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [terms] = useState(true);

  useEffect(() => {
    if (passwordInput.current) passwordInput.current.focus();
  }, [passwordInput]);

  function closeFooter() {
    setSendOTP(false);
    setShowOTP(false);
  }

  useEffect(() => {
    if (userData.email) {
      setEmail(userData.email);
    }
  });

  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);
  let transactionUuid = params.get('transaction');
  let self = params.get('self');
  let signType = params.get('sign_type');
  let signatory = params.get('signatory') ?? '';

  const updatePassword = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    setError('');

    try {
      const { data } = await axios.post('update-password', {
        password: password,
        password_confirmation: confirm,
        email: email
      });

      if (data.success) {
        setUser(data.user);
        setEmail(data.user.email);
        setIsAuth(true);
        setToken(data.token);
        sessionStorage.setItem('token', data.token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
        setContinuePage(6);
        // getTransaction(transactionUuid);
        // getDocuments(transactionUuid, self, signatory);
        // getSignatory(transactionUuid, self, signatory);

        // if (otp) {
        //   setTimeout(() => {
        //     setShowLoader(false);
        //   }, 1000);
        // } else {
        //   setShowLoader(false);
        //   if (signType === 'new' && !existing) {
        //     if (data.token) {
        //       navigate({
        //         pathname: '/continue',
        //         search: `?${createSearchParams(
        //           'transaction=' +
        //             transactionUuid +
        //             '&sign_type=' +
        //             signType +
        //             '&signatory=' +
        //             signatory +
        //             '&self=' +
        //             self
        //         )}`
        //       });
        //       if (signatory === '') {
        //         setContinuePage(2.1);
        //       } else {
        //         setTimeout(() => {
        //           setContinuePage(5);
        //         }, 500);
        //       }
        //     }
        //   } else {
        //     setContinuePage(2.1);
        //   }
        // }
      }
      setError('');
    } catch (e) {
      setShowLoader(false);
      setError(e.response.data.errors.password[0]);
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  const completeLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      setShowLoader(true);
      const { data } = await axios.post('complete-login', {
        password: password,
        email: email,
        sign_in_type: signingType
      });
      sessionStorage.removeItem('token');
      if (data.success) {
        sessionStorage.setItem('token', data.token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
        setUser(data.user);
        setIsAuth(true);
        setToken(data.token);
        setShowLoader(false);

        console.log('user', user);
        console.log('data.user', data.user.cell);
        console.log('data.cell', data.cell);

        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        let signType = params.get('sign_type');

        if (!user?.cell && !data?.user?.cell) {
          setContinuePage(5);
        } else {
          if (signType === 'signup' || !data.user?.cell) {
            setContinuePage(3);
          } else {
            navigate('/');
          }
        }
      }
      setError('');
    } catch (e) {
      setError(e.response.data.message);
      setShowLoader(false);
      console.log(e);
      Sentry.captureException(e);
    }
  };

  const sendPasswordOtp = async () => {
    setError('');
    setOtpError('');
    setPassword('');
    try {
      setShowOTPLoader(true);
      axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
      const { data } = await axios.get(`/users/${user.uuid}/send-otp`);
      if (data.success) {
        setShowOTP(true);
      }
      setShowOTPLoader(false);
    } catch (e) {
      console.log(e);
      setShowOTPLoader(false);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  const checkOtp = async () => {
    setOtpError('');
    try {
      setShowOTPLoader(true);
      const { data } = await axios.post('check-otp', {
        otp: otp,
        email: user.email
      });
      if (data.success) {
        setNewPassword(false);
        closeFooter();
      }
      setShowOTPLoader(false);
      setOtpError('');
    } catch (e) {
      setOtpError(e.response.data.message);
      setShowOTPLoader(false);
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  const goBack = () => {
    onCancel();
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const checkPasswordMatch = (confirmPassword, password) => {
    const confirmPass = confirmPassword;

    setConfirm(confirmPass);

    if (confirmPass && password !== confirmPass) {
      setPasswordError(true);
      setIsError('Confirm password must match password');
    } else {
      setIsError('');
      setPasswordError(false);
    }
  };

  async function checkCharacters(password) {
    // let passwordValue = '';
    // if (isConfirm) {
    //   passwordValue = password;
    // } else {
    //   passwordValue = e.currentTarget.value;
    //   setPassword(passwordValue);
    // }

    let passwordValue = password;

    setPassword(passwordValue);

    try {
      setShowOTPLoader(true);
      const { data } = await axios.post('validate-password', {
        password: passwordValue
      });

      if (data.success) {
        setPasswordError(false);
      } else {
        setPasswordError(true);
      }
    } catch (e) {
      setPasswordError(true);
      console.log(e);
      Sentry.captureException(e);
      if (e.response?.data?.message === 'Unauthenticated.') {
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  }

  return (
    <Row className='m0'>
      {sendOTP ? <div className={styles.overlay}></div> : null}
      <Col
        lg={{ span: 4, offset: 4 }}
        md={{ span: 6, offset: 3 }}
        className='bg-white min-height-full-page px-4 p-relative'
      >
        <Row className='m0'></Row>
        <div
          className={
            newPassword ? styles.centerContentNewPassword : styles.centerContent
          }
        >
          <div className='text-center'>
            <div className='mb-4'>
              <SquareLogoAnimate />
            </div>
            {!newPassword || (signType === 'new' && !existing) ? (
              <p className='pb-4 fw-700 fs-16'>
                Please choose a password
                <br />
                for secure access to your files
              </p>
            ) : (
              <p className='pb-4 fw-500 fs-14'>
                {user && user.first_name ? (
                  <>
                    <strong className='fw-700'>
                      Hello, {user?.first_name} {user?.last_name}
                    </strong>
                    <br />
                    Please enter your password
                  </>
                ) : (
                  email
                )}
              </p>
            )}
            {!newPassword || (signType === 'new' && !existing) ? (
              <Form onSubmit={updatePassword}>
                <Form.Group className='mb-3'>
                  <div className='text-center pb-2 fw-700'>
                    <label className={styles.label}>PASSWORD</label>
                  </div>
                  <div className='p-relative'>
                    <Form.Control
                      type={passwordShown ? 'text' : 'password'}
                      ref={passwordInput}
                      value={password}
                      onChange={(e) => (
                        checkCharacters(e.target.value),
                        checkPasswordMatch(confirm, e.target.value)
                      )}
                      className={`${styles.passwordInput} ${
                        passwordError & !isError
                          ? 'bg-lightred'
                          : password
                          ? 'bg-lightgreen'
                          : 'bg-lightgray'
                      }`}
                    />
                    <div className='ps-3 pe-3 pt-1 pb-4 w-full align-items-center'>
                      <div className='pull-right'>
                        <p
                          className={styles.passwordToggle}
                          onClick={togglePassword}
                        >
                          {passwordShown ? 'hide' : 'show'}
                        </p>
                      </div>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className='mb-3'>
                  <div className='text-center pb-2 fw-700'>
                    <label className={styles.label}>CONFIRM PASSWORD</label>
                  </div>
                  <div className='p-relative'>
                    <Form.Control
                      type={confirmPasswordShown ? 'text' : 'password'}
                      value={confirm}
                      onChange={(e) => (
                        checkPasswordMatch(e.target.value, password),
                        checkCharacters(password)
                      )}
                      className={`${styles.passwordInput} ${
                        isError
                          ? 'bg-lightred'
                          : password & !isError
                          ? 'bg-lightgreen'
                          : 'bg-lightgray'
                      }`}
                    />
                    <div className='ps-3 pe-3 pt-1 pb-4 w-full align-items-center'>
                      <div className='pull-right'>
                        <p
                          className={styles.passwordToggle}
                          onClick={toggleConfirmPassword}
                        >
                          {confirmPasswordShown ? 'hide' : 'show'}
                        </p>
                      </div>
                    </div>
                  </div>
                </Form.Group>

                <div
                  className={`pt-1 fs-12 fw-500 text-center ${
                    passwordError && !isError
                      ? 'color-red'
                      : password
                      ? 'color-green'
                      : 'color-gray'
                  }`}
                >
                  Your password needs to be at least 8 characters
                  <br />& include UPPER CASE, lower case, numbers &<br />
                  special characters
                </div>

                {isError ? (
                  <>
                    <div className={styles.confirmPass}>{isError}</div>
                  </>
                ) : null}

                <div className={styles.proceed}>
                  {showLoader ? (
                    <SpinnerAnimation />
                  ) : (
                    <Button
                      variant='dark'
                      disabled={
                        password !== confirm ||
                        (!password && !confirm) ||
                        passwordError & !isError
                      }
                      className='btn-rounded btn-block blackButton'
                      onClick={(e) => updatePassword(e)}
                    >
                      <span className='fs-14 fw-700'>Continue</span>
                    </Button>
                  )}
                </div>
              </Form>
            ) : (
              <Form onSubmit={completeLogin}>
                <div className='text-center pb-2 fw-700'>
                  <label className={styles.label}>PASSWORD</label>
                </div>
                <Form.Group>
                  <div className='p-relative'>
                    <Form.Control
                      type={passwordShown ? 'text' : 'password'}
                      ref={passwordInput}
                      autoComplete='password'
                      onChange={(e) => setPassword(e.currentTarget.value)}
                      className={styles.passwordInput}
                    />
                    {error ? (
                      <div className='p-relative top-20'>
                        <small className={styles.error}>{error}</small>
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </Form.Group>
                <div className='ps-3 pe-3 pt-1 pb-4 w-full align-items-center'>
                  <div className='text-left pull-left'>
                    <p
                      className={styles.passwordReset}
                      onClick={(e) => setSendOTP(true)}
                    >
                      Reset password
                    </p>
                  </div>
                  <div className='pull-right'>
                    <p
                      className={styles.passwordToggle}
                      onClick={togglePassword}
                    >
                      {passwordShown ? 'hide' : 'show'}
                    </p>
                  </div>
                </div>
                <div className={styles.proceed}>
                  {showLoader ? (
                    <SpinnerAnimation />
                  ) : (
                    <Button
                      variant='dark'
                      disabled={!password}
                      className='btn-rounded btn-block blackButton'
                      onClick={(e) => completeLogin(e)}
                    >
                      <span className='fs-14 fw-700'>Continue</span>
                    </Button>
                  )}
                </div>
              </Form>
            )}
            <span className='m0 fw-700 fs-16 pointer' onClick={goBack}>
              Back
            </span>
          </div>
        </div>

        {sendOTP ? (
          <div className={styles.footerAnimate}>
            <Row className='m0'>
              <Col
                lg={{ span: 4, offset: 4 }}
                md={{ span: 6, offset: 3 }}
                className='p0'
              >
                <div className={styles.passwordChangeFooter}>
                  <div className='ps-3 pe-3 pt-4 pb-4'>
                    <p className='fw-700 fs-16 mt-2'>
                      Are you sure you want to
                      <br />
                      change your password?
                    </p>
                  </div>
                  {showOTPLoader ? (
                    <SpinnerAnimation />
                  ) : (
                    <Button
                      variant='dark'
                      className='btn-block btn-rounded fw-700 fs-16'
                      onClick={(e) => sendPasswordOtp()}
                    >
                      Continue
                    </Button>
                  )}

                  <p className='mt-3' onClick={(e) => setSendOTP(false)}>
                    <span className='pointer fs-16 fw-700'>Cancel</span>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        ) : null}

        {showOTP ? (
          <div className={styles.footerAnimateOTP}>
            <Row className='-m-l-15 -m-r-15'>
              <Col
                lg={{ span: 4, offset: 4 }}
                md={{ span: 6, offset: 3 }}
                className='p-l-0 p-r-0'
              >
                <div className={styles.passwordChangeFooter}>
                  <Form.Group className='mb-4 p-relative'>
                    <div className='pb-2 ps-4 pt-3'>
                      <p className='fw-700 fs-16'>
                        Enter the OTP that was sent to
                        <br /> {email}
                      </p>
                    </div>
                    <Form.Control
                      type='number'
                      inputMode='numeric'
                      className={styles.input}
                      placeholder=''
                      onChange={(e) => setOtp(e.currentTarget.value)}
                    />
                    {otpError ? (
                      <small className={styles.error}>{otpError}</small>
                    ) : (
                      ''
                    )}
                  </Form.Group>

                  {showOTPLoader ? (
                    <SpinnerAnimation />
                  ) : (
                    <Button
                      variant='dark'
                      className='btn-block btn-rounded fw-700 fs-16'
                      onClick={(e) => checkOtp()}
                    >
                      Continue
                    </Button>
                  )}

                  <p className='mt-3' onClick={(e) => closeFooter()}>
                    <span className='pointer fs-16 fw-700'>Cancel</span>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        ) : null}

        <div
          className={
            newPassword ? styles.footerLogoNewPassword : styles.footerLogo
          }
        >
          <img
            src='/static/media/dewly-logo.95b074ed119097ec1853a1fdc0320f6b.svg'
            alt='dewly'
          />
        </div>
      </Col>
    </Row>
  );
}
