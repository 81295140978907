import SpinnerAnimation from '../../../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import appContext from '../../../../context/app-context';
import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import styles from './styles.module.css';
import axios from 'axios';
import SquareLogoAnimate from '../../../SquareLogoAnimate/SquareLogoAnimate';
import { useNavigate } from 'react-router-dom';

export default function TermsAndPrivacyConfirmation({ onDecline, onConfirm }) {
  const { user, email } = useContext(appContext);
  const [showLoader, setShowLoader] = useState(false);

  const confirm = async () => {
    setShowLoader(false);
    let newUser = null;
    if (!user) {
      const {
        data: { user: u }
      } = await axios.post(`register`, {
        email
      });
      newUser = u;
    }

    console.log("USER", user)

    const userUuid = user ? user.id : newUser.uuid;
    const { data } = await axios.put(`add-terms/${userUuid}`, {
      terms: true
    });

    setShowLoader(false);
    if (data.success) {
      onConfirm();
    }
  };

  const welcomeMessages = () => {
    return (
      <>
        <div className='text-center'>
          <p className='mb-2 fw-700 fs-16'>
            By accessing or using Dewly's
            <br />
            services, I accept & agree to the
          </p>
          <p className='fs-16 color-lightgray fw-600 mt-3'>
            <a
              href='https://dewly.net/terms-and-conditions/'
              target='_blank'
              rel='noreferrer'
            >
              Terms of service
            </a>{' '}
            <span className='color-lightgray'> &</span>{' '}
            <a
              href='https://dewly.net/privacy-policy/'
              target='_blank'
              rel='noreferrer'
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </>
    );
  };

  return (
    <>
      <Row className='m0 pt-4'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 8, offset: 2 }}
          xs={{ span: 12, offset: 0 }}
          className={`${styles.footerInner}`}
        >
          <div className='text-center m-t-20'>{welcomeMessages()}</div>
          <div>
            <div className='text-center ps-3 pe-3'>
              {showLoader ? (
                <SpinnerAnimation />
              ) : (
                <>
                  <Button
                    variant='dark'
                    className='btn-rounded btn-block'
                    onClick={confirm}
                  >
                    <span className='fs-16 fw-700'>Accept</span>
                  </Button>
                </>
              )}
            </div>
            <div className='text-center ps-3 pe-3 mt-2'>
              <Button
                variant='outline-dark'
                className='btn-rounded btn-block mt-2 fade-in'
                onClick={onDecline}
              >
                <span className='pointer fs-16 fw-700'>Decline</span>
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
