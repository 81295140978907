import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';

import {
  getAccessToken,
  removeTokenAndStringifyParams,
  storeToken
} from '../../common/utils/utils';
import appContext from '../../context/app-context';
import SpinnerAnimation from '../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import styles from './styles.module.css';

export const Redirect = () => {
  const navigate = useNavigate();
  const { getUser, user } = useContext(appContext);

  useEffect(() => {
    const token = getAccessToken();
    storeToken(decodeURI(token));
  }, []);

  useEffect(() => {
    const loadUser = async () => {
      await getUser();
    };
    loadUser();
  }, []);

  useEffect(() => {
    if (user) {
      const redirect = removeTokenAndStringifyParams();
      const redirectParams = new URLSearchParams(redirect);
      const newUser = redirectParams.get('new');

      if (!!!newUser || !user.terms_and_conditions || !user.cell) {
        return navigate(`/continue?${redirect}`);
      }
      navigate(redirectParams.has('transaction') ? `/sign?${redirect}` : '/');
    }
  }, [user, navigate]);

  return (
    <div id='outer-container'>
      <div id='page-wrap'>
        <Row className='m0'>
          <Col
            lg={{ span: 4, offset: 4 }}
            md={{ span: 6, offset: 3 }}
            className='bg-white min-height-full-page'
          >
            <Row>
              <Col lg={{ span: 4, offset: 4 }} md={{ span: 6, offset: 3 }}>
                <div className={styles.fullscreenSpinner}>
                  <div className='center-content'>
                    <SpinnerAnimation />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};
