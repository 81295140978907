import styles from './styles.module.css';
import { motion } from 'framer-motion';
import { Row, Col } from 'react-bootstrap';
import SigningHeader from '../../../../components/Pages/Signing/Header/Header';
import { SignDocuments } from '../../../SignDocuments/SignDocuments';

export default function DocumentSigning({ onCancel }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <SigningHeader previousPage={onCancel} />
        <Col md={{ span: 6, offset: 3 }}>
          <div className={styles.main}>
            <div className='text-center'>
              <SignDocuments />
            </div>
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}
