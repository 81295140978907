import { useContext, useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import styles from './styles.module.css';
import GoBackHeader from '../../../../components/GoBackHeader/GoBackHeader';
import appContext from '../../../../context/app-context';
import { Button, Col, Form, Row } from 'react-bootstrap';
import CardIcon from '../../../../assets/images/credit-card.svg';
import LockIcon from '../../../../assets/images/lock.svg';
import Visa from '../../../../assets/images/visa.svg';
import Mastercard from '../../../../assets/images/mastercard.svg';
import Amex from '../../../../assets/images/amex.svg';
import axios from 'axios';
import SpinnerAnimation from '../../../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';

export default function CardDetailsPage(props) {
  const { setBillingPage, setCard, user, getCard, getToken, setContinuePage } = useContext(appContext);
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [cvv, setCvv] = useState('');
  const [errors, setErrors] = useState(null);
  const nameInput = useRef();
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (nameInput.current) nameInput.current.focus();
  }, [nameInput]);

  function previousPage() {
    setBillingPage(0);
  }

  useEffect(() => {
    getCard();
  }, [errors]);

  const saveCard = async () => {
    try {
      setShowLoader(true);
      const { data } = await axios.post(`users/${user.id}/cards`, {
        name: name,
        number: number,
        expiry_month: month,
        expiry_year: year,
        cvv: cvv
      });

      if (data.success) {
        setShowLoader(false);
        setCard(data.card);
        setBillingPage(0);
        setErrors(null);
      }
    } catch (e) {
      setShowLoader(false);
      if (e.response.status === 422) {
        let cardErrors = e.response.data.errors;
        setErrors(cardErrors);
      }
      console.log(e);
      Sentry.captureException(e);
      console.log(e.response?.data?.message)
      if (e.response?.data?.message === 'Unauthenticated.') {
        console.log('Unauthenticated.')
        sessionStorage.removeItem('token');
        await getToken();
        setContinuePage(0);
        navigate('/continue');
      }
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          <GoBackHeader previousPage={previousPage} pageName={'Card Details'} />
          <div className='p-t-100' />
          <Form className={styles.form}>
            <Form.Group className='mb-4 ps-3 pe-3'>
              <div className='pb-2 ps-4'>
                <label className={styles.label}>NAME ON CARD</label>
              </div>
              <Form.Control
                type='text'
                ref={nameInput}
                onChange={(e) => setName(e.currentTarget.value)}
                className={styles.input}
              />
              {errors && errors.name ? (
                <small className={styles.error}>Name is required</small>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group className='mb-4 ps-3 pe-3 p-relative'>
              <div className='pb-2 ps-4'>
                <label className={styles.label}>CARD NUMBER</label>
              </div>
              <Form.Control
                type='number'
                inputMode='numeric'
                className={styles.input}
                onChange={(e) => setNumber(e.currentTarget.value)}
                placeholder='0000 0000 0000 0000'
              />
              <img src={CardIcon} alt='ic' className={styles.iconInput} />
              {errors && errors.number ? (
                <small className={styles.error}>Card number is not valid</small>
              ) : (
                ''
              )}
            </Form.Group>
            <Row className='m0'>
              <Col xs={{ span: 6 }} className='p0'>
                <Form.Group className='mb-4 ps-3 pe-3'>
                  <div className='pb-2 ps-4'>
                    <label className={styles.label}>EXPIRY DATE</label>
                  </div>
                  <Form.Control
                    type='number'
                    inputMode='numeric'
                    onChange={(e) => setMonth(e.currentTarget.value)}
                    className={styles.input}
                    placeholder='MM'
                  />
                  {errors && errors.expiry_month ? (
                    <small className={styles.error}>Month is not valid</small>
                  ) : (
                    ''
                  )}
                </Form.Group>
              </Col>
              <Col xs={{ span: 6 }} className='p0'>
                <Form.Group className='mb-4 ps-3 pe-3'>
                  <div className='pb-2 ps-4'>
                    <label className={styles.label}></label>
                  </div>
                  <Form.Control
                    type='number'
                    inputMode='numeric'
                    onChange={(e) => setYear(e.currentTarget.value)}
                    className={styles.input}
                    placeholder='YYYY'
                  />
                  {errors && errors.expiry_year ? (
                    <small className={styles.error}>Year is not valid</small>
                  ) : (
                    ''
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className='m0'>
              <Col xs={{ span: 6 }} className='p0'>
                <Form.Group className='mb-4 ps-3 pe-3 p-relative'>
                  <div className='pb-2 ps-4'>
                    <label className={styles.label}>CVV</label>
                  </div>
                  <Form.Control
                    type='number'
                    inputMode='numeric'
                    onChange={(e) => setCvv(e.currentTarget.value)}
                    className={styles.input}
                    placeholder='000'
                    maxLength={3}
                  />
                  <img src={LockIcon} alt='ic' className={styles.iconInput} />
                  {errors && errors.cvv ? (
                    <small className={styles.error}>CVV is not valid</small>
                  ) : (
                    ''
                  )}
                </Form.Group>
              </Col>
            </Row>
            <div className={`text-center mt-4 ${styles.cardOptions}`}>
              <p className='fw-500'>We accept</p>
              <div className='pb-5'>
                <img src={Visa} alt='Visa' className='mx-2' />
                <img src={Mastercard} alt='Visa' className='mx-2' />
                <img src={Amex} alt='Visa' className='mx-2' />
              </div>
            </div>
          </Form>
        </Col>
      </Row>
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className={`bg-white ${styles.footerInner}`}
        >
          {showLoader ? (
            <div className={styles.footer}>
              <SpinnerAnimation />
            </div>
          ) : (
            <Button
              variant='dark'
              className='btn-block btn-rounded fw-700 fs-16'
              onClick={(e) => saveCard()}
            >
              Save card details
            </Button>
          )}
        </Col>
      </Row>
    </motion.div>
  );
}
