import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { motion } from 'framer-motion';
import { Button, Col, Row } from 'react-bootstrap';

import styles from './styles.module.css';
import faceOutlineImg from '../../assets/images/face-outline.png';
import Fingerscan from '../../assets/images/finger-scan.svg';
import CameraIcon from '../../assets/images/camera-white.svg';
import SigningHeader from '../Pages/Signing/Header/Header';
import SpinnerAnimation from '../Spinners/SpinnerAnimation/SpinnerAnimation';
import { ALLOWED_SELFIE_ATTEMPTS } from '../../common/contants';
import { dataURLtoFile } from '../../common/utils/utils';
import { uploadFile } from '../../common/uploadFile/uploadFile';
import appContext from '../../context/app-context';

export const FaceCapture = ({
  onSubmit,
  previousPage,
  isLoading,
  attempts
}) => {
  const webcamRef = useRef(null);
  const [preview, setPreview] = useState(null);
  const [retake, setRetake] = useState(false);
  const [confirmSubmission, setConfirmSubmission] = useState(false);
  const [progress, setProgress] = useState(0);
  const { user, country } = useContext(appContext)

  const capturePhoto = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPreview(imageSrc);
  }, [webcamRef]);

  const submitFace = async () => {
    if (preview) {
      const face = dataURLtoFile(preview, 'face.jpeg');
      const fileName = await uploadFile(face, setProgress);
      await onSubmit({ faceString: preview, fileName });
      setConfirmSubmission(false);
    }
  };

  const retakeSelfie = () => {
    setConfirmSubmission(false);
    setRetake(false);
    setPreview(null);
  };

  const confirmSelfieSubmission = async () => {
    console.log('SELFIE NOT VERIFIED', user, country);
    if (user?.verified && preview) {
      await submitFace();
      return
    }
    setConfirmSubmission(true);
  }

  useEffect(() => {
    if (attempts >= ALLOWED_SELFIE_ATTEMPTS) {
      return setRetake(true);
    }
    return setRetake(false);
  }, [attempts, setRetake]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <SigningHeader previousPage={previousPage} />
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          {!preview ? (
            <div className={styles.main}>
              <div className='text-center p-t-100'>
                <p className='pt-2 fw-500 m0'>
                  Position your face in the viewfinder and
                  <br />
                  take a selfie. Ensure good lighting.
                </p>
                <div className='m-auto mt-3'>
                  <div className={styles.webcamContainer}>
                    <img
                      src={faceOutlineImg}
                      className={styles.faceOutlineImg}
                      alt='face-outline'
                    />
                    <div className={styles.webcamFlip}>
                      <Webcam
                        ref={webcamRef}
                        audio={false}
                        height={480}
                        width={640}
                        screenshotFormat='image/jpeg'
                        className={styles.webcam}
                        screenshotQuality={0.7}
                        forceScreenshotSourceSize={true}
                        //height='100%'
                      />
                    </div>

                    <div className={styles.cameraBtns}>
                      <Button
                        variant='dark'
                        className='rounded-circle btn-lg-rounded p0 pulse-animate'
                        onClick={capturePhoto}
                      >
                        <img
                          src={CameraIcon}
                          className={styles.CameraIcon}
                          alt='selfie'
                        />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className={styles.main}>
              <div className='text-center p-t-100'>
                <div className={styles.capturedPhotoContainer}>
                  <img src={preview} className={styles.photo} alt='Selfie' />
                  <p className='m-t-15 fw-700 fs-16'>
                    Your selfie remains private & is only used
                    <br />
                    for the purpose of identifying yourself.
                  </p>
                  <div>
                    <Row>
                      <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 8, offset: 2 }}
                        xs={{ span: 12, offset: 0 }}
                        className={`${styles.continueOrRetake}`}
                      >
                        <Col>
                          {isLoading ? (
                            <SpinnerAnimation />
                          ) : (
                            <Button
                              variant='dark'
                              className='btn-block btn-rounded fw-700'
                              onClick={confirmSelfieSubmission}
                            >
                              Continue
                            </Button>
                          )}
                        </Col>
                        <Col>
                          <Button
                            variant='outline-dark'
                            className='btn-block btn-rounded fw-700 m-t-15 btn-no-hover-change'
                            onClick={retakeSelfie}
                            disabled={isLoading}
                          >
                            Retake Selfie
                          </Button>
                        </Col>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          )}
          {confirmSubmission && !user?.verified && (
            <>
            <div className={styles.overlay} />
              <Row className='m0 pt-4 pb-4 zindex-10'>
                <Col md={{ span: 6, offset: 3 }} xs={{ span: 12, offset: 0 }}>
                  <div className={styles.footer}>
                    <div className={styles.fingerscan}>
                      <img
                        src={Fingerscan}
                        alt='scan'
                        className={styles.fingerscanIcon}
                      />
                    </div>
                    <div>
                        <>
                          {country !== 'South Africa' ? (
                            <>
                              <p className='fw-500 fs-16 pb-3'>
                                To verify and protect your identity,
                                <br />
                                you consent that <strong>dewly</strong> can process
                                <br />
                                your Identity credentials against
                                <br />
                                the photo of your identity documents
                              </p>
                            </>
                          ) : (
                            <>
                              <p className='fw-500 fs-16 pb-3'>
                                To verify and protect your identity, I consent that
                                <br />
                                <strong>Dewly</strong> can process my ID/passport number 
                                <br/>
                                and selfie photo against the Department of Home Affairs
                                <br/>
                                & Southern Africa Fraud Prevention Services.
                              </p>
                            </>
                          )}
                        </>
                        <br />
                        <div>
                          <Row className='m0 pt-4 pb-4'>
                            <Col xs={{ span: 12, offset: 0 }}>
                              {isLoading ? (
                                <div className='p-b-24'>
                                  <SpinnerAnimation />
                                </div>
                              ) : (
                                <Button
                                  variant='dark'
                                  className='btn-rounded btn-block mb-4 fw-700'
                                  onClick={submitFace}
                                >
                                  Verify me
                                </Button>
                              )}
                            </Col>
                            <Col xs={{ span: 12 }}>
                              <div className='text-center'>
                                <span
                                  className='fs-16 fw-700 pointer'
                                  onClick={retakeSelfie}
                                >
                                  Cancel
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
          {retake && (
            <>
              <div className={styles.overlay} />
              <Row className='m0 pt-4 pb-4 zindex-10'>
                <Col md={{ span: 6, offset: 3 }} xs={{ span: 12, offset: 0 }}>
                  <div className={styles.footerRetake}>
                    <div>
                      <Row className='m0 pt-5 pb-4'>
                        <img
                          src={preview}
                          className={styles.retakePreview}
                          alt='selfie'
                        />
                        <p className='fw-500 fs-14 pb-4'>
                          We could not verify your identity with this
                          <br />
                          selfie photo, please retake
                        </p>
                        <Col
                          md={{ span: 6, offset: 3 }}
                          xs={{ span: 12, offset: 0 }}
                        >
                          {isLoading ? (
                            <div className='p-b-50'>
                              <SpinnerAnimation />
                            </div>
                          ) : (
                            <Button
                              variant='dark'
                              className='btn-rounded btn-block mb-4 fw-700'
                              onClick={retakeSelfie}
                            >
                              Retake
                            </Button>
                          )}
                        </Col>
                        <Col xs={{ span: 12 }}>
                          <div className='text-center'>
                            <span
                              className='fs-16 fw-700 pointer fw-700'
                              onClick={previousPage}
                            >
                              Cancel
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </Row>
    </motion.div>
  );
};
