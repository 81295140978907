import axios from 'axios';

export const uploadFile = async (file, onProgress) => {
  return axios
    .post('/documents/upload-url', {
      fileExtension: file.name.split('.').pop(),
      contentType: file.type
    })
    .then(async (res) => {
      const uploadable = file.file ? file.file : file
      await axios.put(res.data.url, uploadable, {
        headers: {
          'Content-Type': file.type
        },
        withCredentials: false,
        onUploadProgress: (progressEvent) => {
          onProgress(progressEvent.loaded / progressEvent.total);
        },
        transformRequest: (data, headers) => {
          delete headers.common['Authorization'];
          return data;
        }
      });
      console.log(res.data)
      return res.data.fileName;
    })
    .catch((err) => {
      console.log(err.message);
    });
};
