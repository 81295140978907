import { useContext, useEffect, useRef, useState } from 'react';
import styles from './styles.module.css';
import { motion } from 'framer-motion';
import GoBackHeader from '../../../../components/GoBackHeader/GoBackHeader';
import appContext from '../../../../context/app-context';
import { useFileUpload } from 'use-file-upload';
import { Button, Col, Row } from 'react-bootstrap';
import cameraIcon from '../../../../assets/images/camera.svg';
import axios from 'axios';
import SpinnerAnimation from '../../../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import * as Sentry from '@sentry/react';

export default function MyIDDocuments() {
  const { setProfilePage, user } = useContext(appContext);
  const [documents, setDocuments] = useState([]);
  const [id, setId] = useState(null);
  const [address, setAddress] = useState(null);
  const [bankAccount, setBankAccount] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderID, setShowLoaderID] = useState(false);
  const [showLoaderAddress, setShowLoaderAddress] = useState(false);
  const [showLoaderBankAccount, setShowLoaderBankAccount] = useState(false);

  function previousPage() {
    setProfilePage(0);
  }

  useEffect(() => {
    getDocuments();
  }, [user]);

  const uploadIdRef = useRef();
  const uploadAddressRef = useRef();
  const uploadStatementRef = useRef();
  const handleUploadId = () => {
    uploadIdRef.current.click();
  };
  const handleUploadAddress = () => {
    uploadAddressRef.current.click();
  };
  const handleUploadStatement = () => {
    uploadStatementRef.current.click();
  };

  async function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  const saveDocument = async (file, type) => {
    getBase64(file, async (result) => {
      if (type === 'id') {
        setId(result);
      }
      if (type === 'proof_of_address') {
        setAddress(result);
      }
      if (type === 'proof_of_bank_account') {
        setBankAccount(result);
      }

      if (file) {
        let formData = new FormData();
        formData.append('type', type);
        formData.append('document', file);
        formData.append('file', result);

        const config = {
          headers: {
            'content-type': `multipart/form-data;`
          }
        };

        try {
          if (type === 'id') {
            setShowLoaderID(true);
          }
          if (type === 'addrproof_of_addressess') {
            setShowLoaderAddress(true);
          }
          if (type === 'proof_of_bank_account') {
            setShowLoaderBankAccount(true);
          }

          await axios.post(`users/${user.id}/documents`, formData, {
            ...config
          });
          if (type === 'id') {
            setShowLoaderID(false);
          }
          if (type === 'proof_of_address') {
            setShowLoaderAddress(false);
          }
          if (type === 'proof_of_bank_account') {
            setShowLoaderBankAccount(false);
          }
        } catch (e) {
          console.log(e);
          if (type === 'id') {
            setShowLoaderID(false);
          }
          if (type === 'proof_of_address') {
            setShowLoaderAddress(false);
          }
          if (type === 'proof_of_bank_account') {
            setShowLoaderBankAccount(false);
          }
          Sentry.captureException(e);
        }
      }
    });
  };

  const getDocuments = async () => {
    try {
      setShowLoader(true);
      const { data } = await axios.get(`users/${user.id}/documents`);
      setDocuments(data.documents);
      setShowLoader(false);
      for (let i = 0; i < data.documents.length; i++) {
        if (data.documents[i].type === 'id') {
          setId(data.documents[i].path);
        }

        if (data.documents[i].type === 'proof_of_address') {
          setAddress(data.documents[i].path);
        }

        if (data.documents[i].type === 'proof_of_bank_account') {
          setBankAccount(data.documents[i].path);
        }
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          <GoBackHeader
            previousPage={previousPage}
            pageName={'My ID Documents'}
          />

          <div className='m-t-100 pb-4'>
            <div className='text-center ps-3 pe-3'>
              <p className={styles.label}>PHOTO OF ID DOCUMENT</p>
              {showLoader ? (
                <>
                  <div className='mb-3'>
                    <SpinnerAnimation />
                  </div>
                </>
              ) : (
                <>
                  {id ? (
                    <>
                      <img src={id} alt='id' className={styles.idPhoto} />
                    </>
                  ) : (
                    <>
                      <div className={styles.IDPlaceholder}></div>
                    </>
                  )}
                  <div>
                    {showLoaderID ? (
                      <div className='mb-3'>
                        <SpinnerAnimation />
                      </div>
                    ) : (
                      <Button
                        variant='dark'
                        className='btn-rounded btn-50 p-relative top-less-20 p0'
                        onClick={handleUploadId}
                      >
                        <img
                          src={cameraIcon}
                          alt='edit'
                          className={styles.edit}
                        />
                        <input
                          type='file'
                          ref={uploadIdRef}
                          style={{ display: 'none' }}
                          accept='image/*'
                          onChange={(e) =>
                            saveDocument(e.target.files[0], 'id')
                          }
                        />
                      </Button>
                    )}
                  </div>
                </>
              )}
            </div>

            <div className='text-center ps-3 pe-3'>
              <p className={styles.label}>
                PHOTO OF PROOF OF RESIDENTIAL ADDRESS
              </p>
              {showLoader ? (
                <>
                  <div className='mb-3'>
                    <SpinnerAnimation />
                  </div>
                </>
              ) : (
                <>
                  {address ? (
                    <>
                      <img
                        src={address}
                        alt='license'
                        className={styles.idPhoto}
                      />
                    </>
                  ) : (
                    <>
                      <div className={styles.placeholder}>
                        <p className='center-content fw-400 fs-12'>None</p>
                      </div>
                    </>
                  )}
                  <div>
                    {showLoaderAddress ? (
                      <div className='mb-3'>
                        <SpinnerAnimation />
                      </div>
                    ) : (
                      <Button
                        variant='dark'
                        className='btn-rounded btn-50 p-relative top-less-20 p0'
                        onClick={handleUploadAddress}
                      >
                        <img
                          src={cameraIcon}
                          alt='edit'
                          className={styles.edit}
                        />
                        <input
                          type='file'
                          ref={uploadAddressRef}
                          style={{ display: 'none' }}
                          accept='image/*'
                          onChange={(e) =>
                            saveDocument(e.target.files[0], 'proof_of_address')
                          }
                        />
                      </Button>
                    )}
                  </div>
                </>
              )}
            </div>

            <div className='text-center ps-3 pe-3'>
              <p className={styles.label}>PHOTO OF PROOF OF BANK ACCOUNT</p>
              {showLoader ? (
                <>
                  <div className='mb-3'>
                    <SpinnerAnimation />
                  </div>
                </>
              ) : (
                <>
                  {bankAccount ? (
                    <>
                      <img
                        src={bankAccount}
                        alt='license'
                        className={styles.idPhoto}
                      />
                    </>
                  ) : (
                    <>
                      <div className={styles.placeholder}>
                        <p className='center-content fw-400 fs-12'>None</p>
                      </div>
                    </>
                  )}
                  <div>
                    {showLoaderBankAccount ? (
                      <div className='mb-3'>
                        <SpinnerAnimation />
                      </div>
                    ) : (
                      <Button
                        variant='dark'
                        className='btn-rounded btn-50 p-relative top-less-20 p0'
                        onClick={handleUploadStatement}
                      >
                        <img
                          src={cameraIcon}
                          alt='edit'
                          className={styles.edit}
                        />
                        <input
                          type='file'
                          ref={uploadStatementRef}
                          style={{ display: 'none' }}
                          accept='image/*'
                          onChange={(e) =>
                            saveDocument(
                              e.target.files[0],
                              'proof_of_bank_account'
                            )
                          }
                        />
                      </Button>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}
