import * as Sentry from '@sentry/react';
import Identification from '../../assets/images/identification.svg';
import WhiteChevronRightIcon from '../../assets/images/white-chevron-right.svg';
import axios from 'axios';
import ViewAcceptAcknowledgements from '../../components/Acknowledgements/ViewAcceptAcknowledgements';
import SpinnerAnimation from '../../components/Spinners/SpinnerAnimation/SpinnerAnimation';
import AdditionalFields from '../../components/shared/SidebarMenu/AdditionalFields';
import appContext from '../../context/app-context';
import { motion } from 'framer-motion';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';

export const SignDocuments = (props) => {
  const {
    transaction,
    selfieVerified,
    setSingleSigning,
    setCurrentPage,
    documents,
    signatory,
    unSignedDocuments,
    currentDocument,
    email,
    selfie,
    getToken,
    setContinuePage,
  } = useContext(appContext);
  const fieldRef = useRef();
  const navigate = useNavigate();
  const [validatePassed, setValidatePassed] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState('');
  const [signFiles, setSignFiles] = useState(false);
  const [addUserToSign, setAddUserToSign] = useState(false);

  // ACKNOWLEDGEMENTS
  const [userAcknowledgements, setuserAcknowledgements] = React.useState([]);
  const [showAcknowledgement, setShowAcknowledgement] = useState(false);
  const [showSavetime, setShowSavetime] = useState(false);
  const [signSingleFile, setSignSingleFile] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState(documents);
  const [showAcknowledgementPopup, setShowAcknowledgementPopup] =
    useState(true);
  const [showAcceptSign, setShowAcceptSign] = useState(true);
  const [showSignLoader, setShowSignLoader] = useState(false);

  const [verifiedFirstName, setVerifiedFirstName] = useState('');
  const [verifiedLastName, setVerifiedLastName] = useState('');

  const addingUser = () => {
    setAddUserToSign(true);
  };

  const signDocument = useCallback(
    async (isSingle) => {
      fieldRef.current.save();
      console.log('is single ' + isSingle);
      setSignSingleFile(isSingle);
      setSignFiles(true);

      if (userAcknowledgements?.length > 0) {
        setShowAcknowledgement(true);
        setShowSavetime(false);
        return;
      }

      setError('');
      let documentId = '';
      setShowLoader(true);
      if (isSingle) {
        documentId = `/${currentDocument.uuid}`;
      }
      console.log('Complete Sign Document', currentDocument.uuid, email, selectedDocs);

      try {
        // const { data } = await axios.post(
        //   `transactions/${transaction.id}/complete-signatory${documentId}`,
        //   {
        //     email: email,
        //     documents: selectedDocs
        //   }
        // );

        // setShowLoader(false);
        // if (data.success) {
        //   setCurrentPage(5);
        //   setSingleSigning(isSingle);
        // }
      } catch (e) {
        setTimeout(() => {
          setShowLoader(false);
          setError('Something went wrong please try again later!');
        }, 2000);
        Sentry.captureException(e);
        console.log(e);
        if (e.response?.data?.message === 'Unauthenticated.') {
          sessionStorage.removeItem('token');
          await getToken();
          setContinuePage(0);
          navigate('/continue');
        }
      }
    },
    [
      currentDocument.uuid,
      email,
      selectedDocs,
      setCurrentPage,
      setSingleSigning,
      transaction.id,
      userAcknowledgements
    ]
  );

  useEffect(() => {
    console.log(signFiles, userAcknowledgements, selectedDocs, signSingleFile);
    if (signFiles && userAcknowledgements?.length === 0) {
      signDocument(signSingleFile);
    }
  }, [
    userAcknowledgements,
    selectedDocs,
    signFiles,
    signSingleFile,
    signDocument
  ]);

  function sign(isSingle) {
    setSignSingleFile(isSingle);

    // let documentIndex = signatoryDocuments.findIndex(
    //   (document) => document.uuid === currentDocument.uuid
    // );
    // let document =
    //   documentIndex !== -1 ? signatoryDocuments[documentIndex] : null;
    if (userAcknowledgements?.length > 0) {
      setShowAcknowledgement(true);
      setShowSavetime(false);
      return;
    }

    signDocument(isSingle);
  }

  function disregard(DocumentUuid) {
    // Remove the document with disregarded acknowledgements from the list to sign
    let documentsToSign = selectedDocs;
    const newDocumentsArr = documentsToSign.filter((object) => {
      return object.id !== DocumentUuid;
    });

    if (signSingleFile) {
      setSignFiles(false);
      setSelectedDocs([]);
      // navigate('/');
      setCurrentPage(1);
      return;
    } else {
      setSelectedDocs(newDocumentsArr);
    }

    // Check if there are any any documents left to sign still
    let count = 0;
    newDocumentsArr.forEach((doc) => {
      if (doc.signed === 0) {
        count++;
      }
    });

    // If all are disregarded, go to home page
    if (newDocumentsArr.length === 0 || count === 0) {
      setSignFiles(false);
      setCurrentPage(1);
      return;
    }
  }

  // const handleAdditionalFieldValidation = (validationState) => {
  //   setValidatePassed(validationState);
  // };
  console.log(selfieVerified, selfie);
  console.log(unSignedDocuments);
  console.log(selectedDocs);
  console.log(addUserToSign);
  return (
    <>
      {selfieVerified && selfie && (
        <div className={styles.capturedPhotoContainer}>
          {unSignedDocuments > 1 ? (
            <>
              <motion.div
                initial={{ x: 0, y: 0, opacity: 0 }}
                animate={{ x: 0, y: 0, opacity: 1 }}
                transition={{ type: 'tween', delay: 0.3 }}
              >
                {signFiles && !showLoader && (
                  <div className={styles.overlay}></div>
                )}
                <div className={styles.flipImage}>
                  {selfie ? (
                    <img
                      src={selfie}
                      className={styles.photoPreview}
                      alt='Screenshot'
                    />
                  ) : (
                    ''
                  )}
                </div>
                <p className='fw-800 fs-18'>
                  Hi{' '}
                  {signatory ? (
                    <>
                      {signatory?.first_name} {signatory?.last_name}
                    </>
                  ) : (
                    <span className='lowercase'>{signatory?.email}</span>
                  )}
                </p>
                <AdditionalFields
                  validate={setValidatePassed}
                  ref={fieldRef}
                  documents={documents}
                  transaction={transaction}
                  user={signatory}
                />
                <p className='mt-5 fw-500 fs-14'>
                  By signing,
                  <br />
                  you agree to, & accept
                  <br />
                  the terms & conditions of
                </p>
                <div className='p-b-150'>
                  {selectedDocs.map((document, i) => {
                    return (
                      <p key={i} className='mb-2 fs-14 fw-700'>
                        {document.title}
                        <br />
                      </p>
                    );
                  })}
                </div>
                {showSignLoader && (
                  <div className='p-b-50'>
                    <SpinnerAnimation />
                  </div>
                )}
                {showAcceptSign && (
                  <div className={`${styles.footerWhite}`}>
                    <Row className='m0 pt-4'>
                      <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 8, offset: 2 }}
                        xs={{ span: 12, offset: 0 }}
                        className={`${styles.footerInner}`}
                      >
                        {showLoader ? (
                          <div className='p-b-50'>
                            <SpinnerAnimation />
                          </div>
                        ) : (
                          <Button
                            variant='dark'
                            className='btn-rounded btn-block'
                            disabled={!validatePassed}
                            onClick={() => (
                              setSignFiles(true),
                              setShowSavetime(true),
                              fieldRef.current.save()
                            )}
                          >
                            <span className='fs-16 fw-700'>Accept & Sign</span>
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                )}

                {signFiles && showSavetime && (
                  <Row>
                    <Col
                      lg={{ span: 6, offset: 3 }}
                      md={{ span: 8, offset: 2 }}
                      xs={{ span: 12, offset: 0 }}
                    >
                      <div className={styles.footer}>
                        <p className='fw-800 fs-18 m0 pt-5'>
                          save time by signing
                          <br />
                          all files in one go?
                        </p>

                        {showLoader ? (
                          <div className='p-b-50'>
                            <SpinnerAnimation />
                          </div>
                        ) : (
                          <div className={styles.photoBtns}>
                            {error ? (
                              <p className={styles.error}>{error}</p>
                            ) : (
                              ''
                            )}
                            <Row className='m0 pt-4'>
                              <Col
                                md={{ span: 6 }}
                                xs={{ span: 12, offset: 0 }}
                                className='mobile-order-1'
                              >
                                <Button
                                  variant='outline-dark'
                                  className='btn-rounded btn-block mb-3'
                                  onClick={() => {
                                    setShowAcknowledgementPopup(false);
                                    setTimeout(() => {
                                      setShowAcknowledgementPopup(true);
                                    }, 500);
                                    sign(true);
                                    setShowAcceptSign(false);
                                    setShowSignLoader(true);
                                  }}
                                >
                                  <span className='fs-16 fw-700'>
                                    Sign individually
                                  </span>
                                </Button>
                              </Col>
                              <Col
                                md={{ span: 6 }}
                                xs={{ span: 12, offset: 0 }}
                              >
                                <Button
                                  variant='dark'
                                  className='btn-rounded btn-block mb-3'
                                  onClick={() => sign(false)}
                                >
                                  <span className='fs-16 fw-700'>Sign all</span>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              </motion.div>
            </>
          ) : !selfieVerified ? (
            <>
              {!addUserToSign ? (
                <>
                  <Row className='m0'>
                    <Col
                      xs={{ span: 12, offset: 0 }}
                      className='bg-white min-height-full-page -m-t-56'
                    >
                      <div className='text-center mt-3 pt-5'>
                        <div className='pb-2 pt-4'>
                          <img
                            src={Identification}
                            alt='ID'
                            className={styles.icon}
                          />
                        </div>
                        <p className='fw-500 fs-14'>
                          Verification is temporarily unavailable
                        </p>
                        <p className='fw-700 fs-16'>*ID NUMBER GOES HERE*</p>
                        <Form.Group className='mb-4 ps-3 pe-3'>
                          <div className='pb-2 text-center'>
                            <label className={styles.label}>FIRST NAMES</label>
                          </div>
                          <Form.Control type='text' className={styles.input} />
                          {error ? (
                            <small className={styles.error}>{error}</small>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                        <Form.Group className='mb-4 ps-3 pe-3'>
                          <div className='text-center pb-2'>
                            <label className={styles.label}>LAST NAME</label>
                          </div>
                          <Form.Control type='text' className={styles.input} />
                        </Form.Group>

                        <div className='text-center'>
                          <Button
                            variant='dark'
                            className='btn btn-dark rounded-circle btn-md-rounded p0 pulse-animate'
                            onClick={addingUser}
                          >
                            <img src={WhiteChevronRightIcon} alt='proceed' />
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row className='m0'>
                    <Col
                      xs={{ span: 12, offset: 0 }}
                      className='bg-white min-height-full-page -m-t-70'
                    >
                      {signFiles && !showLoader && (
                        <div className={styles.overlay}></div>
                      )}
                      <div className='p-t-80 text-center'>
                        <img
                          src={selfie}
                          className={styles.photoPreview}
                          alt='Screenshot'
                        />
                        <p className='fw-800 fs-18 pb-4'>
                          <strong>Hello </strong>,
                        </p>
                        <p className='fw-700 fs-16'>Additional information</p>

                        <p className='mt-5 fw-500 fs-14'>
                          By signing this, you agree to, & accept
                          <br />
                          the terms & conditions of
                        </p>
                        <div className='p-b-100'>
                          {selectedDocs.map((document, i) => {
                            return (
                              <p key={i} className='mb-2 fs-14 fw-700'>
                                {document.title}
                                <br />
                              </p>
                            );
                          })}
                        </div>
                        <div className={styles.acceptSignFooter}>
                          <Row className='m0 pt-4 ps-3 pe-3'>
                            <Col
                              lg={{ span: 6, offset: 3 }}
                              md={{ span: 6, offset: 3 }}
                              xs={{ span: 12, offset: 0 }}
                            >
                              {showLoader ? (
                                <div className='p-b-50'>
                                  <SpinnerAnimation />
                                </div>
                              ) : (
                                <Button
                                  variant='dark'
                                  className='btn-rounded btn-block mb-3'
                                  onClick={() => (
                                    setSignFiles(true), setShowSavetime(true)
                                  )}
                                >
                                  <span className='fs-16 fw-700'>
                                    Accept & Sign
                                  </span>
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>

                      {signFiles && showSavetime && (
                        <>
                          <Row>
                            <Col
                              lg={{ span: 6, offset: 3 }}
                              md={{ span: 8, offset: 2 }}
                              xs={{ span: 12, offset: 0 }}
                            >
                              <div className={styles.footer}>
                                <p className='fw-800 fs-18 m0 pt-5'>
                                  save time by signing
                                  <br />
                                  all files in one go?
                                </p>

                                {showLoader ? (
                                  <div className='p-b-50'>
                                    <SpinnerAnimation />
                                  </div>
                                ) : (
                                  <div className={styles.photoBtns}>
                                    {error ? (
                                      <p className={styles.error}>{error}</p>
                                    ) : (
                                      ''
                                    )}
                                    <Row className='m0 pt-4'>
                                      <Col
                                        md={{ span: 6 }}
                                        xs={{ span: 12, offset: 0 }}
                                        className='mobile-order-1'
                                      >
                                        <Button
                                          variant='outline-dark'
                                          className='btn-rounded btn-block mb-3'
                                          onClick={() => sign(true)}
                                        >
                                          <span className='fs-16 fw-700'>
                                            Sign individually
                                          </span>
                                        </Button>
                                      </Col>
                                      <Col
                                        md={{ span: 6 }}
                                        xs={{ span: 12, offset: 0 }}
                                      >
                                        <Button
                                          variant='dark'
                                          className='btn-rounded btn-block mb-3'
                                          onClick={() => sign(false)}
                                        >
                                          <span className='fs-16 fw-700'>
                                            Sign all
                                          </span>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </>
          ) : (
            <>
              <div className='p-t-200'>
                <motion.div
                  initial={{ x: 0, y: 0, opacity: 0 }}
                  animate={{ x: 0, y: -170, opacity: 1 }}
                  transition={{ type: 'tween', delay: 0.3 }}
                >
                  <div className={styles.flipImage}>
                    {selfie ? (
                      <img
                        src={selfie}
                        className={styles.photoPreview}
                        alt='Screenshot'
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <p className='fw-800 fs-18'>
                    Hello,{' '}
                    {signatory && signatory.first_name ? (
                      <>
                        {signatory?.first_name}{' '}
                        {signatory.last_name && <>{signatory?.last_name}</>}
                      </>
                    ) : verifiedFirstName && verifiedLastName ? (
                      verifiedFirstName + ' ' + verifiedLastName
                    ) : (
                      <span className='lowercase'>{signatory?.email}</span>
                    )}
                  </p>

                  <AdditionalFields
                    validate={setValidatePassed}
                    ref={fieldRef}
                    documents={documents}
                    transaction={transaction}
                    user={signatory}
                  />

                  <p className='mt-5 fw-500 fs-14'>
                    By signing this, you agree to, & accept
                    <br />
                    the terms & conditions of
                    {selectedDocs.map((document, i) => {
                      return (
                        <p key={i} className='mb-1 fs-14 fw-700'>
                          <br />
                          {document.title}
                          <br />
                        </p>
                      );
                    })}
                  </p>
                </motion.div>

                {error ? <p className={styles.error}>{error}</p> : ''}
                <div className={`${styles.footerWhite}`}>
                  <Row className='m0 pt-4'>
                    <Col
                      lg={{ span: 4, offset: 4 }}
                      md={{ span: 8, offset: 2 }}
                      xs={{ span: 12, offset: 0 }}
                      className={`${styles.footerInner}`}
                    >
                      {showLoader ? (
                        <div className='p-b-50'>
                          <SpinnerAnimation />
                        </div>
                      ) : (
                        <Button
                          variant='dark'
                          className='btn-rounded btn-block'
                          onClick={() => signDocument(false)}
                          disabled={!validatePassed}
                          // onClick={() => sign(false)}
                        >
                          <span className='fs-16 fw-700'>Accept & Sign</span>
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      asdfadsfadsf
      {showAcknowledgementPopup}
      {showAcknowledgementPopup && (
        <ViewAcceptAcknowledgements
          currentDocument={currentDocument}
          document={document}
          transaction={transaction}
          signatory={signatory}
          setuserAcknowledgements={setuserAcknowledgements}
          showAcknowledgement={showAcknowledgement}
          generatePDFafterAccept={false}
          signSingleFile={signSingleFile}
          disregard={disregard}
        />
      )}
    </>
  );
};
