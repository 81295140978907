import styles from './styles.module.css';
import { Row, Col } from 'react-bootstrap';
import ChevronLeftIcon from '../../assets/images/chevron-left.svg';
import home from '../../assets/images/home.svg';

export default function GoBackHeader(props) {

  return (
    <div className={styles.header}>
      <Row className='m0 w-100'>
        <Col className='p0' md={{ span: 6, offset: 3 }}>
          <div className='d-flex align-items-center justify-content-center pe-3 ps-3'>
            <div
              className={styles.chevronLeftIcon}
              onClick={props.previousPage}
            >
              <img src={ChevronLeftIcon} alt='go back' />
            </div>
            <p className='fs-16 fw-700 m0'>{props.pageName}</p>
            <img
              src={home}
              alt='home'
              className={styles.homeBtn}
              onClick={() => window.location.href = '/'}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}
